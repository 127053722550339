<template>
  <div class="hello">

    <div class="row">

      <div class="col-xs-9 col-sm-10">
        
        <div v-if="configStyle" :style="configStyle.principal">

<!--           <img src="@/assets/pedigree_base_3.png" width="1024"
              style="position:fixed;left:0px;top:0px;"> -->
 
          <div :style="configStyle.nome">
            <b :style="configStyle.label">Nome</b><br>
            <span>NOME DO ANIMAL</span>
          </div>

          <div :style="configStyle.proprietario">
              <b :style="configStyle.label">Proprietário</b><br>
              <span>NOME DO PROPRIETARIO</span>
          </div>

          <div :style="configStyle.qrCode">
            <b 
            style="position: absolute;top:-20px;left:0px;color: #039cae;">QR code de identificação</b>
            <img :src="qrCode">
          </div>

          <div :style="configStyle.foto" style="background-color: grey;z-index: 999;height:150px;"></div>

          <div :style="configStyle.raca">
            <b :style="configStyle.label">Raça</b><br>
            <span>PIT BULL</span>
          </div>

          <div :style="configStyle.rg">
            <b :style="configStyle.label">Nº de registro</b><br>
            <span>RG/CH/MATRIZ/XX/XXXXX</span>
          </div>

          <div :style="configStyle.cor">
            <b :style="configStyle.label">Cor</b><br>
            <span>BRANCO</span>
          </div>

          <div :style="configStyle.sexo">
            <b :style="configStyle.label">Sexo</b><br>
            <span>Macho</span>
          </div>

          <div :style="configStyle.microchip">
            <b :style="configStyle.label">Microchip</b><br>
            <span>CODIGO_MICROCHIP&nbsp;</span>
          </div>

          <div :style="configStyle.nascimento">
            <b :style="configStyle.label">Nascimento</b><br>
            <span>01/01/2000</span>
          </div>

          <div :style="configStyle.empresa">
            <b :style="configStyle.label">Criador</b><br>
              <span>CRIADOR</span>
          </div>

          <div :style="configStyle.emissao">
            <b :style="configStyle.label">Emissão</b><br>
            <span>01/01/2022</span>
          </div>

          <div :style="configStyle.pais">
            <b :style="configStyle.label">Origem</b>
            <div style="margin-top:5px;">
              <i class="flag-icon flag-icon-br" style="margin-right: 5px; font-size: 24px !important; float: left;"></i>
              <span>BRASIL</span>
            </div>
          </div>

          <div :style="configStyle.irmao">
            <b :style="configStyle.label">Irmãos de ninhada</b><br>
            <small>irmão, irmã, irmão, irmã, irmão, irmã, irmão, irmã, irmão, irmã, irmão, irmã, ...</small>
          </div>

          <div :style="configStyle.arvore">
            <Arvore :compacto="true" :cabecalho="false" :edicao="false" rg="-1"
                  :styleItem1="configStyle.itemArvore1"
                  :styleItem2="configStyle.itemArvore2"
                  :styleItem3="configStyle.itemArvore3"
                  :styleItem4="configStyle.itemArvore4"
                    :styleMae="configStyle.itemMae"
                    :stylePai="configStyle.itemPai"/>
          </div>

        </div>

      </div>

      <div v-if="configStyle" style="position: absolute;top: 800px;left: 0px;width: 1024px;text-align: left;">

        <span style="background-color: black;color:white;margin-left: 500px;">GRANDE</span><br><br>

        <div v-for="pos in 20" :style="configStyle.qrCodeGrandeItem">

          <img :src="qrCodeGrande" style="width: 100px;height: 100px;">
          <span :style="configStyle.qrCodeGrandeTexto">{{configStyle.qrCodeGrande?configStyle.qrCodeGrande.texto:"..."}}</span>
          
        </div>

      </div>

      <div v-if="configStyle" style="position: absolute;top: 1100px;left: 0px;width: 1024px;text-align: left;background-color: white;">

        <span style="background-color: black;color:white;margin-left: 500px;">pequeno</span><br><br>

        <div  v-for="pos in 20" :style="configStyle.qrCodePequenoItem">
          <img :src="qrCodePequeno" style="width: 80px;height: 80px;">
          <span :style="configStyle.qrCodePequenoTexto">{{configStyle.qrCodePequeno?configStyle.qrCodePequeno.texto:"..."}}</span>
        </div>

      </div>

      <div class="hidden-print well" style="width: 300px;position: absolute;right: 0px;z-index: 999;">

        <h1>Personalizar estilo do Pedigree</h1>

        <div v-if="configStyle" style="text-align: left;padding: 10px;">
          <textarea cols="30" rows="50" v-model="configStyleStr">
          </textarea>
        </div>

        <BotaoLoader style="height: 30px;" class="btn btn-success " v-on:acao="salvar" :pai="this" role="button"><span class="glyphicon glyphicon-ok" aria-hidden="true">
        </span>Salvar</BotaoLoader>
        
      </div>

  </div>

  </div>
</template>

<script>
import BotaoLoader from '../components/BotaoLoader.vue'
import Menu from '../components/Menu.vue'
import Arvore from '../components/Arvore.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,easyGet } from '../utils'

const toast = useToast();

export default {

  components: {
    BotaoLoader,
    Arvore,
    Menu
  },

  mounted: function () {

    this.carregarConfiguracaoPedigree()

  },

  computed: {

    qrCode(){
      const urlConsulta = "bychos_teste";
      return "https://api.katon.tech/api/qr-code?type=ONLY_BASE64_IMAGE&chs=100x100&data="+encodeURI(urlConsulta)
    },

    qrCodeGrande(){
      const urlConsulta = "bychos_teste";
      var ref = this;
      var tamanho
      if(ref.configStyle&&ref.configStyle.qrCodeGrande&&ref.configStyle.qrCodeGrande.tamanho){
        tamanho = ref.configStyle.qrCodeGrande.tamanho;
      }else{
        tamanho = "100x100";
      }

      return "https://api.katon.tech/api/qr-code?type=ONLY_BASE64_IMAGE&chs="+tamanho+"&cht=qr&chl="+encodeURI(urlConsulta)
    },

    qrCodePequeno(){
      const urlConsulta = "bychos_teste";

      var ref = this;
      var tamanho
      if(ref.configStyle&&ref.configStyle.qrCodePequeno&&ref.configStyle.qrCodePequeno.tamanho){
        tamanho = ref.configStyle.qrCodePequeno.tamanho;
      }else{
        tamanho = "100x100";
      }

      return "https://api.katon.tech/api/qr-code?type=ONLY_BASE64_IMAGE&chs="+tamanho+"&cht=qr&chl="+encodeURI(urlConsulta)

    },

    configStyle(){

      var ref = this;
      return JSON.parse( ref.configStyleStr );

    }

  },

  data: function () {
                      return {
                                configStyleStr:null
                            }
                  },

    methods: {

      secureUpper,

      salvar:function(ajaxPost,cancel){

        var ref = this
        var parameters = "styleStr="+ref.configStyleStr

        ajaxPost(apiHost+"api/ajaxSalvarConfiguracaoPedigree",
                  parameters,
              function (result) {

                if(!result.status){
                  toast.error(result.message)
                  return
                }

                toast.success("Salvo com sucesso!")

            }
        )

      },

      carregarConfiguracaoPedigree:function(){

        var ref = this
        var parametros
        easyGet("api/ajaxCarregarConfiguracaoPedigree",parametros,null,function(result){

              //ref.configStyle    = result.pedigreeStyle;
              ref.configStyleStr = JSON.stringify(result.pedigreeStyle,null,4)

          })


      }

    }

}
</script>

<style scoped type="text/css" media="print">

  @media print {  
    .hidden-print{
      display: none;
    }
  }

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




input{
  text-transform:none;
}

</style>