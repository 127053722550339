<template>
<nav>

  <VueSidebarMenuAkahon 
      v-if="logado"
      menuTitle="Bychos"
      :isSearch="false"
      bgColor="#0ca0a4"
      :menuItems="opcoesMenu"/>

</nav>
</template>

<script>

import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";

export default {

    components: {VueSidebarMenuAkahon},
    name: 'Menu',
    methods: {

          logout: function () {

              localStorage.removeItem('token');
              window.location.href = "..#menu-login";

          }

    },

    mounted: function () {

      var ref = this;

      if(ref.perfil=="PROPRIETARIO"){
  
        ref.opcoesMenu.push({link: '#menu-animais',name: 'Animais', tooltip: 'Animais', 
                          icon: 'bxs-baby-carriage' })

        ref.opcoesMenu.push({link: '#menu-cadastroPet',name: 'Animais cadastro', tooltip: 'Cadastro de animais', 
                          icon: 'bx-plus' })

        ref.opcoesMenu.push({link: '#menu-sair',name: 'Sair', tooltip: 'Sair', 
                          icon: 'bx-exit' })

        return
      }

      if(ref.matriz){

        ref.opcoesMenu.push({link: '#menu-especies',name: 'Especies', tooltip: 'Especies', 
                            icon: 'bx bxs-heart' })

        ref.opcoesMenu.push({link: '#menu-racas',name: 'Raças', tooltip: 'Raças', 
                            icon: 'bxs-vial' })

        ref.opcoesMenu.push({link: '#menu-franquias',name: 'Franquias', tooltip: 'Franquias', 
                            icon: 'bx-home-alt' })

        ref.opcoesMenu.push({link: '#menu-usuarios',name: 'Usuarios', tooltip: 'Usuarios', 
                            icon: 'bx-user' })

        ref.opcoesMenu.push({link: '#menu-cores',name: 'Cores', tooltip: 'Cores', 
                            icon: 'bx-color-fill' })


      }

      ref.opcoesMenu.push({link: '#menu-criadores',name: 'Criadores', tooltip: 'Criadores', 
                          icon: 'bx-user' })

      ref.opcoesMenu.push({link: '#menu-animais',name: 'Animais', tooltip: 'Animais', 
                          icon: 'bxs-baby-carriage' })

      ref.opcoesMenu.push({link: '#menu-consulta',name: 'Busca', tooltip: 'Busca', 
                            icon: 'bx-search-alt' })

      if(ref.matriz){

        ref.opcoesMenu.push({link: '#menu-gerarQrCode',name: 'Medals', tooltip: 'Gerar Qr Code', 
                            icon: 'bx-barcode' })

        ref.opcoesMenu.push({link: '#menu-microchips',name: 'Microchips', tooltip: 'Microchips', 
                            icon: 'bx-barcode' })

        ref.opcoesMenu.push({link: '#menu-whatsapp',name: 'Whatsapp', tooltip: 'Whatsapp',
                            icon: 'bx-chat' })

        ref.opcoesMenu.push({link: '#menu-configuracaoPedigree',name: 'Personalizar', tooltip: 'Personalizar pedigree', 
                            icon: 'bx-code-alt' })

        ref.opcoesMenu.push({link: '#menu-configuracaoOutros',name: 'Termos', tooltip: 'Ajustar termos', 
                            icon: 'bx-barcode' })

      }

      ref.opcoesMenu.push({link: '#menu-sair',name: 'Sair', tooltip: 'Sair', 
                          icon: 'bx-exit' })

    },

    data: function () {
                      return {
                          logo:'@assets/loader-wheel.svg',
                          opcoesMenu:[]
                      }
    },

    computed: {

      logado() {
        return localStorage.getItem('token')!=null
      },

      perfil() {
        return localStorage.getItem('perfil')
      },

      matriz() {
        return localStorage.getItem('matriz')=="true"
      }

    },

}
</script>

<style>

.sidebar .logo-details .icon {
  display: none!important;
}

.profile{
  display: none!important;
}

</style>
