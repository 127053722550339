<template>
  <div class="hello">

    <Menu/>

    <div class="row">

      <div class="col-xs-3 col-sm-3">
      </div>

      <div class="hidden-print col-xs-5 col-sm-5">

        <h1>Termos de uso</h1>

        <div style="text-align: left;padding: 10px;">
          <textarea cols="100" rows="50" v-model="termosUso">
          </textarea>
        </div>

        <BotaoLoader style="height: 30px;" class="btn btn-success " v-on:acao="salvar" :pai="this" role="button"><span class="glyphicon glyphicon-ok" aria-hidden="true">
        </span>Salvar</BotaoLoader>
        
      </div>

      <div class="col-xs-3 col-sm-3">
      </div>

  </div>

  </div>
</template>

<script>
import BotaoLoader from '../components/BotaoLoader.vue'
import Menu from '../components/Menu.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,easyGet } from '../utils'

const toast = useToast();

export default {

  components: {
    BotaoLoader,
    Menu
  },

  mounted: function () {

    this.carregarConfiguracao()

  },

  data: function () {
                      return {
                                termosUso:null
                            }
                  },

    methods: {

      secureUpper,

      salvar:function(ajaxPost){

        var ref = this
        var parameters = "termosUso="+ref.termosUso

        ajaxPost(apiHost+"api/ajaxSalvarConfiguracao",
                  parameters,
              function (result) {

                if(!result.status){
                  toast.error(result.message)
                  return
                }

                toast.success("Salvo com sucesso!")

            }
        )

      },

      carregarConfiguracao:function(){

        var ref = this
        var parametros
        easyGet("api/ajaxCarregarConfiguracao",parametros,null,function(result){

              ref.termosUso    = result.termosUso;

          })

      }

    }

}
</script>

<style scoped type="text/css" media="print">

  @media print {  
    .hidden-print{
      display: none;
    }
  }

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




input{
  text-transform:none;
}

</style>