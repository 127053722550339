<template>
  <div class="hello">
    <Menu/>

    <PopupConfirmacao ref='mdPopConfirmacaoExclusao' v-on:sucesso="onExcluido"/>

    <FormExpansivel :pai="this" ref="mdPopup" :large="false" id="modalPopup" alinhamento="left" title="Salvar" v-bind:options="optionsPopup">

        <form id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">
            <fieldset>
                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Nome</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="nome" @keyup="nome=secureUpper(nome)">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Criador</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="nomeEmpresa" @keyup="nomeEmpresa=secureUpper(nomeEmpresa)">
                    </div>
                </div>

                <div v-if="matriz" class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Franquia</label>
                    <div class="col-sm-7">
                      <ComboboxBase 
                          v-model="franquiaId" :url='apiHost+"api/ajaxComboboxFranquias"'/>
                    </div>
                </div>

                <div v-if="matriz&&franquiaId==1" class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Espécie</label>
                    <div class="col-sm-7">
                      <ComboboxBase 
                          v-model="especieId" :url='apiHost+"api/ajaxComboboxEspecies"'/>
                    </div>
                </div>


                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Telefone</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="telefone" @keyup="telefone=secureUpper(telefone)">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Prefixo criador</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="prefixo" @keyup="prefixo=secureUpper(prefixo)">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Sufixo criador</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="sufixo" @keyup="sufixo=secureUpper(sufixo)">
                    </div>
                </div>

                <div v-if="matriz" class="form-group">

                    <label for="nome" class="col-sm-4 control-label">Vencimento</label>
                    <div class="col-sm-2">
                      <InputDataHora v-model="vencimento"/>
                    </div>

                </div> 

            </fieldset>
        </form>

    </FormExpansivel>

    <div style="margin:10px;padding: 5px;">
      <TabelaAjax dominio="Usuarios" ref="tbDados" :root="this" :paginado="true" :colunas="configTbDados.columns" :url="configTbDados.url" :filtros="configTbDados.filtros" ></TabelaAjax>
    </div>

  </div>
</template>

<script>
import TabelaAjax from '../components/TabelaAjax.vue'
import FormExpansivel from '../components/FormExpansivel.vue'
import PopupConfirmacao from '../components/PopupConfirmacao.vue'
import InputDataHora from '../components/InputDataHora.vue'
import Menu from '../components/Menu.vue'
import ComboboxBase from '../components/ComboboxBase.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification"
import { secureUpper } from '../utils'

const toast = useToast();
var moment = require('moment');

export default {

  props: {
    msg: String
  },

  components: {
    TabelaAjax,
    FormExpansivel,
    InputDataHora,
    PopupConfirmacao,
    Menu,
    ComboboxBase
  },

  mounted: function () {

    var ref = this;
    ref.vencimento = moment().add(1, 'years').format('DD/MM/YYYY');

    console.log("====mounted ref.vencimento:"+ref.vencimento);

  },

  computed: {

    apiHost(){
      return apiHost
    },

    matriz(){
      return localStorage.getItem('matriz')=="true"
    }

  },

  data: function () {

                      var colunas = []

                      if( localStorage.getItem('matriz')=="true" ){

                        colunas.push({name:"nome",title:"Nome",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"})

                        colunas.push({name:"nomeEmpresa",title:"Criador",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"})


                      }else{
                        colunas.push({name:"nome",title:"Nome"})
                        colunas.push({name:"nomeEmpresa",title:"Criador"})
                      }

                      colunas = colunas.concat([ 
                                          {name:"email",title:"Email",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"telefone",title:"Telefone",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"prefixo",title:"Prefixo criador",type:"EDITAVEL_TEXTO",
                                                                                          urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"sufixo",title:"Sufixo criador",type:"EDITAVEL_TEXTO",
                                                                                          urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"ativo",title:"Ativo",type:"EDITAVEL_BOOLEAN",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"}
                                                
                                   ])

                      if( localStorage.getItem('matriz')=="true" ){

                        colunas.push({name:"vencimento",title:"Vencimento",type:"EDITAVEL_DARAHORA",
                                 urlEdicao:apiHost+"api/ajaxEditarInformacao"})

                        colunas.push({name:"Franquia.nome",title:"Franquia",
                                            type:"EDITAVEL_COMBOBOX",
                                            urlCombobox: apiHost+"api/ajaxComboboxFranquias",
                                            urlEdicao:   apiHost+"api/ajaxEditarInformacao"
                                      })

                      }else{
                        colunas.push({name:"vencimento",title:"Vencimento"})
                      }

                      colunas = colunas.concat([ 

                                          {name:"id",title:"Certificado",type:"VIEW", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                const url = "/app/certificadoCriador/"+encodeURI(dadosColuna.inscricao.replaceAll("/","-") )

                                                window.open(url, '_blank').focus()
                                              }
                                          },

                                          {name:"id",title:"",type:"DELETE", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                var opcoes = [ 
                                                                {codigo:"EXCLUIR",descricao:"Confirmar exclusão"}
                                                              ];

                                                var parametrosAdicionais = "id="+item+"&informacao=Usuarios";

                                                refRoot.$refs.mdPopConfirmacaoExclusao.abrirPopupConfirmacaoAjax(
                                                      "Confirmar exclusão?",
                                                      "",
                                                      "Ao confirmar #"+dadosColuna.nome+" será excluido permanentemente",
                                                      opcoes,
                                                      "api/ajaxExcluirInformacao",
                                                      parametrosAdicionais);

                                                }


                                              }                                          

                                        ]


                            )


                      return {

                            nome:"",
                            email:"",
                            telefone:"",
                            senha:"",
                            sufixo:"",
                            prefixo:"",
                            franquiaId:"",
                            nomeEmpresa:"",
                            vencimento:null,
                            especieId:null,

                            optionsPopup:{
                              confirm:{
                                show:true,
                                action:function(ajaxPost,cancel,item,refPai){

                                    var parameters = "nome="+encodeURIComponent(refPai.nome)+"&franquiaId="+refPai.franquiaId+
                                            "&email="+refPai.email+
                                            "&telefone="+refPai.telefone+
                                            "&sufixo="+refPai.sufixo+
                                            "&prefixo="+refPai.prefixo+
                                            "&perfil=CRIADORES"+
                                            "&especieId="+refPai.especieId+
                                            "&nomeEmpresa="+encodeURIComponent(refPai.nomeEmpresa)+
                                            "&vencimento="+refPai.vencimento+
                                            "&senha="+encodeURIComponent(refPai.senha);

                                    ajaxPost(apiHost+"api/ajaxAdicionarUsuario",parameters,
                                        function (result) {

                                            if(!result.status){
                                              toast.error(result.message)
                                              return
                                            }

                                            refPai.$refs.tbDados.forceRefreshCurrentData();
                                            refPai.$refs.mdPopup.limparRecolherDados();

                                        }
                                    );
                                }
                              }

                            },

                            configTbDados:{
                              filtros:[
                                {
                                  title:"perfil",
                                  type:"HIDDEN",
                                  name:"perfil",
                                  selected:"CRIADORES"
                                },
                                {
                                  name:"filtroNome",
                                  title:"Nome",
                                  selected:"",
                                  type:"SEARCH",
                                },
                                {
                                  name:"filtroEmpresa",
                                  title:"Criador",
                                  selected:"",
                                  type:"SEARCH",
                                }
                              ],
                              columns : colunas,
                              url: apiHost+"api/ajaxListarUsuarios",
                            },

                      }
                    },

    methods: {
      secureUpper,
      
      onExcluido: function () {
        this.atualizarDados();
      },

      atualizarDados:function(){
        var ref = this;
        ref.$refs.tbDados.forceRefreshCurrentData();
      }

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
