<script>
  /* eslint-disable */

var moment = require('moment');
import { apiHost } from '../config'

import { useToast } from "vue-toastification";
import { secureUpper,easyGet } from '../utils'

const toast = useToast();

export default {

  props: {
    msg: String
  },

  components: {
  },

  mounted: function () {

    var ref = this;

  },

  computed:{

      logado() {
        return localStorage.getItem('token')!=null
      }

  },

  data: function () {
                      return {
                            codigo:"",
                        }
                    },

  methods: {
          
          secureUpper,

          consultarDados: function () {

            var ref = this;

            if(!ref.codigo){
              toast.error("Nada informado")
              return
            }

            if(ref.codigo.length<15){
              toast.error("Código inválido")
              return
            }

            window.location.href = '/app/detalhes/'+ref.codigo;

          }

    }

}
</script>

<template>
<div>

	<div class="panel panel-default" style="padding: :10px;width: 100%;border-width: 0px;box-shadow: none;">
	  <div class="panel-body" style="border-width: 0px;">

      <img src="@/assets/logo-geolocalizacao-bychos.png" style="width: 100%;max-width: 400px;">

	    <form id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">
	        <fieldset>

            <div class="row divTextoPesquisa">
             <div class="col-md-4"></div>
             <div class="col-md-4">
               <b><p style="font-size: 18px;">Pesquisar pet pelo código do microchip ou QR Code</p></b>
             </div>
             <div class="col-md-4"></div>
            </div>

            <div class="row">
              <div class="col-md-4"></div>

              <div class="col-md-4">
                  <input style="text-align: center; border-radius: 10px;font-size: 30px;padding: 30px;max-width:1000px;" name="nome" type="text" id="nome" class="form-control" placeholder="Digite aqui" v-model="codigo" keyup="codigo=secureUpper(codigo)">
              </div>

              <div class="col-md-4"></div>

              <div class="col-md-12">
                <button type="button" class="btn btn-info" v-on:click="consultarDados" style="margin-top: 10px;">Consultar</button>
              </div>

            </div>
	                
	        </fieldset>

	    </form>

	  </div>
	  <div class="panel-footer" style="position: relative;border-width: 0px;background-color: white;">

      <div class="row">

        <div class="col-md-4" style="margin-top: 10px;">
          <u><a style="color: black;" href="/app/cadastroProprietario">Login ou cadastro de novo usuário</a></u>
          <br><br>
          <u><a style="color: black;margin-top: 10px;" href="mailto:contato@bychos.com.br">Contato Bychos</a></u>

        </div>
        <div class="col-md-4" style="margin-top: 10px;">
        </div>


        <div class="col-md-4" style="margin-top: 10px;">
          <span>Siga-nos no instagram:</span>
          <br>
          <img src="@/assets/instagram.png" width="20"><b><a style="color: black;" href="https://instagram.com/bychosoficial"> @bychosoficial</a></b>
        </div>
      </div>
   
	  </div>
    <div class="textoDireitos">
      <center><p>©2022 www.Bychos.com.br - Todos os direitos reservados</p></center>
    </div>

	</div>

</div>
</template>


<style scoped>

.divTextoPesquisa{
  margin-top: 100px;
}

.textoDireitos{
  position: absolute;
  bottom: 10px;
  width: 100%;
}

@media only screen and (max-height: 700px) {

  .divTextoPesquisa{
    margin-top: 10px!important;
  }

  .textoDireitos{
    margin-top: 10px;
    position: relative;
  }

}

</style>










