<template>
  <div class="hello">
    <Menu/>

    <PopupConfirmacao ref='mdPopConfirmacaoExclusao' v-on:sucesso="onExcluido"/>

    <FormExpansivel :pai="this" ref="mdForm" :large="false" id="modalPopup" alinhamento="left" title="Salvar" v-bind:options="optionsPopup">

        <form id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">
            <fieldset>
                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Espécie</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="nome" @keyup="nome=secureUpper(nome)">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Associação</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="descritivo" @keyup="descritivo=secureUpper(descritivo)">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Sigla</label>
                    <div class="col-sm-3">
                        <input style="text-transform:uppercase;" maxlength="2" name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="sigla" @keyup="sigla=secureUpper(sigla)">
                    </div>
                </div>

            </fieldset>
        </form>

    </FormExpansivel>

    <div style="margin:10px;padding: 5px;">
      <TabelaAjax dominio="Especies" ref="tbDados" :root="this" :paginado="true" :colunas="configTbDados.columns" :url="configTbDados.url" :filtros="configTbDados.filtros" ></TabelaAjax>
    </div>

  </div>
</template>

<script>
import TabelaAjax from '../components/TabelaAjax.vue'
import Menu from '../components/Menu.vue'
import FormExpansivel from '../components/FormExpansivel.vue'
import PopupConfirmacao from '../components/PopupConfirmacao.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper } from '../utils'

const toast = useToast();

export default {

  name: 'Especies',
  props: {
    msg: String
  },

  components: {
    TabelaAjax,
    PopupConfirmacao,
    Menu,
    FormExpansivel
  },

  data: function () {
                      return {

                            nome:"",
                            descritivo:"",
                            sigla:"",

                            optionsPopup:{
                              confirm:{
                                show:true,
                                action:function(ajaxPost,cancel,item,refPai){

                                    var parameters = "nome="+encodeURIComponent(refPai.nome)+
                                                    "&descritivo="+encodeURIComponent(refPai.descritivo)+
                                                    "&sigla="+encodeURIComponent(refPai.sigla)

                                    ajaxPost(apiHost+"api/ajaxAdicionarEspecie",parameters,
                                        function (result) {

                                            if(!result.status){
                                              toast.error(result.message)
                                              return
                                            }

                                            refPai.$refs.tbDados.forceRefreshCurrentData();

                                            refPai.$refs.mdForm.limparRecolherDados();

                                        }
                                    );
                                }
                              }




                            },

                            configTbDados:{
                              filtros:[
                                {
                                  name:"filtroNome",
                                  title:"Espécie",
                                  selected:"",
                                  type:"SEARCH",
                                }
                              ],
                              columns : [ 

                                          {name:"nome",title:"Espécie",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"descritivo",title:"Associação",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"sigla",title:"Sigla",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"},

                                          {name:"id",title:"",type:"DELETE", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                var opcoes = [ 
                                                                {codigo:"EXCLUIR",descricao:"Confirmar exclusão"}
                                                              ];

                                                var parametrosAdicionais = "id="+item+"&informacao=Especies";

                                                refRoot.$refs.mdPopConfirmacaoExclusao.abrirPopupConfirmacaoAjax(
                                                      "Confirmar exclusão?",
                                                      "",
                                                      "Ao confirmar #"+dadosColuna.nome+" será excluido permanentemente",
                                                      opcoes,
                                                      "api/ajaxExcluirInformacao",
                                                      parametrosAdicionais);

                                                }

                                              }


                                        ],
                              url: apiHost+"api/ajaxListarEspecies",
                            },

                      }
                    },

    methods: {

      secureUpper,

      onExcluido: function () {
        this.atualizarDados();
      },

      atualizarDados:function(){
        var ref = this;
        ref.$refs.tbDados.forceRefreshCurrentData();
      }

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
