<script>
/* eslint-disable */

import InputNumerico from '../components/InputNumerico'
import InputDataHora from './InputDataHora.vue'

export default {

  components: {
    InputNumerico,
    InputDataHora
  },
  name:"Filtro",
  data: function () {
            return {
              focado:false
            }
          },

  props: {
    filtro: null
  },

  methods: {

      filtroModificado: function(){

        var ref = this;
        ref.$emit('modificado');

      }

  },

  computed: {

    larguraDinamica: function () {

      var larguraCalculada = 200;
      var ref = this;

      if(ref.filtro.selected){
        larguraCalculada = (ref.filtro.selected.length*7)+200;
      }

      var larguraMinima = ref.filtro.title.length*7;
      if(larguraCalculada<larguraMinima){
        larguraCalculada = larguraMinima;
      }
      
      return {width:larguraCalculada+"px"};
    }

  }

}
</script>

<template>

  <div v-show="filtro.type!='HIDDEN'" v-bind:style="larguraDinamica" style="min-width:200px;float:left;position:relative;margin-right:10px;padding-top:10px;">
        <span v-if="filtro.type=='SEARCH'||filtro.type==null||filtro.type=='DATE'" style="position:absolute;left:5px;top:-7px;z-index:100;font-size:small;">{{filtro.title}}</span>
        <div v-if="filtro.type=='SEARCH'" class="input-group">

          <input v-if="filtro.dataType==null||filtro.dataType=='TEXT'" v-on:focus="focado=true" v-on:blur="focado=false" v-on:keyup.enter="filtroModificado" v-model="filtro.selected" class="form-control" type="text" autocomplete="off">

          <input-numerico :width="larguraDinamica" v-if="filtro.dataType&&filtro.dataType=='NUMERIC_INTEGER'" :decimal="false" v-on:focus="focado=true" v-on:blur="focado=false" v-on:confirmado="filtroModificado" v-model="filtro.selected"></input-numerico>

          <div class="input-group-btn"> <span v-show="focado" class="label label-default" style="position:absolute;top:-18px;right:-1px;z-index:100;">enter</span> <button tabindex="-1" v-on:click="filtroModificado" type="button" class="btn btn-primary"> <span class="glyphicon glyphicon-search"></span></button></div></div>
        <div v-on:focusout="filtroModificado">
            <input-data-hora :width="larguraDinamica" v-if="filtro.type=='DATE'" v-model="filtro.selected"></input-data-hora>
        </div>
        <select v-if="filtro.type==null" class="form-control" v-model="filtro.selected" v-on:change="filtroModificado">
          <option v-for="op in filtro.options" v-bind:key="op.code" v-bind:value="op.code">{{op.title}}</option>
        </select>
    </div>


</template>

<style scoped>
.btn-primary{
  background-color: black;
}
</style>