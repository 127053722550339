<template>
  <div class="hello">
    <Menu/>

    <PopupConfirmacao ref='mdPopConfirmacaoExclusao' v-on:sucesso="onExcluido"/>

    <FormExpansivel :pai="this" ref="mdPopup" :large="false" id="modalPopup" alinhamento="left" title="Salvar" description="" v-bind:options="optionsPopup">

        <form id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">
            <fieldset>
                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Raça</label>
                    <div class="col-sm-7">
                        <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="nome" @keyup="nome=secureUpper(nome)">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Espécie</label>
                    <div class="col-sm-7">
                      <ComboboxBase v-model="especieId" :url='apiHost+"api/ajaxComboboxEspecies"'/>
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Origem</label>
                    <div class="col-sm-7">
                      <ComboboxBase style="width: 200px;float:left;"
                          placeholder="Brasil"
                          v-model="codigoPais" :url='apiHost+"api/ajaxComboboxPaises"'/>
                      <span style="margin-left:5px;font-size: 24px;float:left;" :class="iconeBandeira"></span>
                    </div>
                </div>

            </fieldset>
        </form>

    </FormExpansivel>

    <div style="margin:10px;padding: 5px;">
      <TabelaAjax dominio="Racas" ref="tbItens" :root="this" :paginado="true" :colunas="configTbItens.columns" :url="configTbItens.url" :filtros="configTbItens.filtros" ></TabelaAjax>
    </div>

  </div>
</template>

<script>

import TabelaAjax from '../components/TabelaAjax.vue'
import FormExpansivel from '../components/FormExpansivel.vue'
import ComboboxBase from '../components/ComboboxBase.vue'
import PopupConfirmacao from '../components/PopupConfirmacao.vue'
import Menu from '../components/Menu.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper } from '../utils'

const toast = useToast();

export default {

  name: 'Racas',
  props: {
    msg: String
  },

  components: {
    TabelaAjax,
    FormExpansivel,
    ComboboxBase,
    PopupConfirmacao,
    Menu
  },

  computed: {

    apiHost(){
      return apiHost
    }

  },

  data: function () {
                      return {
                            nome:"",
                            especieId:"",
                            codigoPais:"BR",
                            optionsPopup:{
                              confirm:{
                                show:true,
                                action:function(ajaxPost,cancel,item,refPai){

                                    console.log("===item:"+item);

                                    var parameters = "nome="+encodeURIComponent(refPai.nome)+"&especieId="+refPai.especieId+
                                                    "&pais="+refPai.codigoPais;

                                    ajaxPost(apiHost+"api/ajaxAdicionarRaca",parameters,
                                        function (result) {

                                            if(!result.status){
                                              toast.error(result.message)
                                              return
                                            }

                                            refPai.$refs.tbItens.forceRefreshCurrentData();
                                            refPai.$refs.mdPopup.limparRecolherDados();

                                        }
                                    );
                                }
                              }


                            },

                            configTbItens:{
                              filtros:[
                                {
                                  name:"filtroNome",
                                  title:"Raça",
                                  selected:"",
                                  type:"SEARCH",
                                },
                                {
                                  title:"Espécie",
                                  name:"filtroEspecie",
                                  selected:"",
                                  options:[],
                                  url:apiHost+"api/ajaxComboboxEspecies",
                                },
                              ],
                              columns : [ 
                                          {name:"nome",title:"Raça",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                          {name:"Especie.nome",title:"Espécie",
                                                type:"EDITAVEL_COMBOBOX",
                                                urlCombobox: apiHost+"api/ajaxComboboxEspecies",
                                                urlEdicao:   apiHost+"api/ajaxEditarInformacao"},
                                          {name:"pais",title:"País",
                                                type:"EDITAVEL_COMBOBOX",
                                                urlCombobox: apiHost+"api/ajaxComboboxPaises",
                                                urlEdicao:   apiHost+"api/ajaxEditarInformacao"},

                                          {name:"id",title:"",type:"DELETE", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                var opcoes = [ 
                                                                {codigo:"EXCLUIR",descricao:"Confirmar exclusão"}
                                                              ];

                                                var parametrosAdicionais = "id="+item+"&informacao=Racas";

                                                refRoot.$refs.mdPopConfirmacaoExclusao.abrirPopupConfirmacaoAjax(
                                                      "Confirmar exclusão?",
                                                      "",
                                                      "Ao confirmar #"+dadosColuna.nome+" será excluido permanentemente",
                                                      opcoes,
                                                      "api/ajaxExcluirInformacao",
                                                      parametrosAdicionais);

                                                }

                                              }

                                        ],
                              url: apiHost+"api/ajaxListarRacas",
                            },

                      }
                    },

    methods: {

          secureUpper,

          onExcluido: function () {
            this.atualizarDados();
          },

          atualizarDados:function(){
            var ref = this;
            ref.$refs.tbItens.forceRefreshCurrentData();
          }

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
