<script>
/* eslint-disable */

import { apiHost } from '../config'
import LoginCachorro from './LoginCachorro.vue'
import { useToast } from "vue-toastification";
const toast = useToast();

export default {

  name: 'Login',
  props: {
    msg: String
  },

  components: {
    LoginCachorro
  },

  data: function () {
                      return {
                        email:"",
                        senha:""
                      }},

  methods: {

    login:function(){

      var ref = this;
      var parametros = "email="+ref.email+"&password="+encodeURIComponent(ref.senha)

      $.ajax({
              url: apiHost+"api/token" ,
              data: parametros,
              dataType: "JSON",
                  type: "POST"
             }).success(function(result){
                
              console.log("=====login matriz:"+result.matriz );

              localStorage.setItem('token', result.token )
              localStorage.setItem('especieId', result.especieId )
              localStorage.setItem('matriz', result.matriz===true?"true":"false")
              localStorage.setItem('perfil', result.perfil )

              window.location.href = "/app/animais";

          }).complete(function(result){
            
        
            }).error(function(respErro){

                  try{

                    if(respErro.status=="401"){ 

                      toast.error("Email ou senha invalidos, verifique os dados digitados. Caso tenha solicitado a redefinição de senha a nova senha será enviada no seu email.");

                    }else if(respErro.status=="403"){ 
                      toast.error("Acesso não permitido, entre em contato com o administrador para verificar se você possuí as permissões de acesso adequadas.");
                    }else{
                    
                      var result;
                      if(respErro.responseText){
                        result = JSON.parse(respErro.responseText);
                      }

                      if(result&&result.message){

                        toast.error(result.message);
                        
                      }else{
                        toast.error("Houve um problema, tente novamente mais tarde.");
                      }

                    }

                  }catch(e){

                      toast.error("Houve um problema, tente novamente mais tarde.");
                  }

          });

    }

  }

}

</script>
<template>

  <div class="tela-login">

    <div class="loginmodal-container">
      <LoginCachorro style="margin-top:300px;" v-model:email="email" v-model:senha="senha" v-on:entrar="login"/>
    </div>

  </div>

</template>

<style scoped>

@import url(http://fonts.googleapis.com/css?family=Roboto);

.tela-login{
  background-color: #e7f0fd;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
}

/****** LOGIN MODAL ******/
.loginmodal-container {
  padding: 30px;
  max-width: 350px;
  width: 100% !important;
  margin: 0 auto;
  border-radius: 2px;
  overflow: hidden;
  font-family: roboto;
}

.loginmodal-container h1 {
  text-align: center;
  font-size: 1.8em;
  font-family: roboto;
}

.loginmodal-container input[type=submit] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.loginmodal-container input[type=text], input[type=password] {
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.loginmodal-container input[type=text]:hover, input[type=password]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.loginmodal {
  text-align: center;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  height: 36px;
  padding: 0 8px;
/* border-radius: 3px; */
/* -webkit-user-select: none;
  user-select: none; */
}

.loginmodal-submit {
  /* border: 1px solid #3079ed; */
  border: 0px;
  color: #fff;
  text-shadow: 0 1px rgba(0,0,0,0.1); 
  background-color: #4d90fe;
  padding: 17px 0px;
  font-family: roboto;
  font-size: 14px;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}

.loginmodal-submit:hover {
  /* border: 1px solid #2f5bb7; */
  border: 0px;
  text-shadow: 0 1px rgba(0,0,0,0.3);
  background-color: #357ae8;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}

.loginmodal-container a {
  text-decoration: none;
  color: #666;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  opacity: 0.6;
  transition: opacity ease 0.5s;
} 

.login-help{
  font-size: 12px;
}

</style>
