<script>
  /* eslint-disable */

var moment = require('moment');
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,easyPost,easyPostJson,resizeImage,nullCheck,extrairId } from '../utils'
import ComboboxBase from '../components/ComboboxBase.vue'
import InputDataHora from '../components/InputDataHora.vue'
import BotaoLoader from '../components/BotaoLoader.vue'

const toast = useToast();

export default {

  components: {
    BotaoLoader,
    InputDataHora,
    ComboboxBase
  },

  mounted: function () {

    var ref = this;

  },

  computed:{

      logado() {
        return localStorage.getItem('token')!=null
      },

      apiHost() {
        return apiHost
      }

  },

  data: function () {
                      return {
                            localizacao:null,
                            exibirLogin:false,
                            carregando:false,
                            aceitoTermos:false,
                            dadosProprietario:{
                              nome:null,
                              senha:null,
                              telefone:null,
                              telefoneComMascara:null,
                              email:null
                            }
                        }
                    },

  methods: {
          
          secureUpper,

          avancar: function (ajaxPost,cancel) {

            var ref = this

            if(ref.exibirLogin){
              ref.login(cancel)
              return
            }

            if(!ref.aceitoTermos){
                toast.error("Para prosseguir é necessário o aceite dos termos de uso.");
                cancel();
                return
            }

            var parameters = "nome="+encodeURIComponent(ref.dadosProprietario.nome)+
                              "&senha="+encodeURIComponent(ref.dadosProprietario.senha)+
                              "&telefone="+encodeURIComponent(ref.dadosProprietario.telefone)+
                              "&email="+encodeURIComponent(ref.dadosProprietario.email)

                  ajaxPost(apiHost+"api/ajaxCadastroPublicoProprietario",parameters,
                      function (result){

                          if(!result.status){
                            toast.error(result.message)
                            return
                          }

                          ref.login()

                      });


          },

          redefinirSenha: function (){

            var ref = this
            if(ref.carregando){
              toast.info("redefinição em andamento")
              return
            }

            if(!ref.dadosProprietario.email){
              toast.error("Informe o seu email")
              return
            }

            ref.carregando = true

            var parametros = "email="+encodeURIComponent(ref.dadosProprietario.email)


            easyPost("api/redefinicao-senha",parametros,null,function(result){

              var mensagem = "Sua nova senha será enviada no email "+result.emailMascara
              if(result.telefoneMascara){
                mensagem+= "e também via whatsapp no telefone "+result.telefoneMascara 
              }

              alert(mensagem)

            },
            function(result){

              ref.carregando = false

            }) 

          },

          login:function(callback){

                var ref = this;
                var parametros = "email="+ref.dadosProprietario.email+"&password="+encodeURIComponent(ref.dadosProprietario.senha)

                $.ajax({
                        url: apiHost+"api/token" ,
                        data: parametros,
                        dataType: "JSON",
                            type: "POST"
                       }).success(function(result){
                          
                        localStorage.setItem('token', result.token )
                        localStorage.setItem('especieId', null )
                        localStorage.setItem('matriz', "false")
                        localStorage.setItem('perfil', result.perfil )

                        window.location.href = "/app/animais"

                    }).complete(function(result){
                          
                      if(callback){
                        callback()
                      }

                    }).error(function(respErro){

                            try{

                              if(respErro.status=="401"){

                                toast.error("Email ou senha invalidos, verifique os dados digitados. Caso tenha solicitado a redefinição da senha verifique a nova senha em seu email.");

                              }else if(respErro.status=="403"){ 
                                toast.error("Acesso não permitido, entre em contato com o administrador para verificar se você possuí as permissões de acesso adequadas.");
                              }else{
                              
                                var result;
                                if(respErro.responseText){
                                  result = JSON.parse(respErro.responseText);
                                }

                                if(result&&result.message){

                                  toast.error(result.message);
                                  
                                }else{
                                  toast.error("Houve um problema, tente novamente mais tarde.");
                                }

                              }

                            }catch(e){

                                toast.error("Houve um problema, tente novamente mais tarde.");
                            }

                    });

              }

    }

}
</script>

<template>
<div>


<div class="row">

  <div class="col-xs-12 col-sm-4"></div>

  <div class="col-xs-12 col-sm-4">
    
    <div style="position: relative; border: #4dc7b1;border-style: solid;padding: 20px;margin-top: 100px;">

      <a style="color: #fff;background-color: #9e9e9e;border-color: #9e9e9e;margin-bottom: 10px;" href="https://bychos.com.br/" class="btn btn-info pull-left"><span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span>  voltar</a>

      <a href="https://bychos.com.br/">
        <img src="@/assets/logo-geolocalizacao-bychos.png" style="width: 100%;max-width: 400px;">
      </a>
      <br><br>

      <div v-show="!exibirLogin">
        <b>Proprietário: </b><input type="text" class="form-control" v-model="dadosProprietario.nome" />
        <br>
        <b>celular: </b><input v-maska="['(##) ####-####', '(##) #####-####']" class="form-control" type="tel" autocomplete="tel" v-model="dadosProprietario.telefoneComMascara" @maska="dadosProprietario.telefone = $event.target.dataset.maskRawValue">

        <br>
        <b>Email: </b><input type="text" class="form-control" v-model="dadosProprietario.email" />
        <br>
        <b>Senha: </b><input type="password" class="form-control" v-model="dadosProprietario.senha" />

        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="aceitoTermos"> Aceito os <a href="https://bychos.com.br/app/termos" target="_blank">termos de uso</a>
          </label>
        </div>

        <br><br>
        <a href="#" @click="exibirLogin=true">já tenho conta</a>
      </div>

      <div v-show="exibirLogin">
        <b>Email: </b><input type="text" class="form-control" v-model="dadosProprietario.email" />
        <br>
        <b>Senha: </b><input type="password" class="form-control" v-model="dadosProprietario.senha" />

        <br><br>
        <a href="#" @click="redefinirSenha">esqueci minha senha</a>
        <br><br>
        <a href="#" @click="exibirLogin=false">me cadastrar</a>
      </div>

      <BotaoLoader style="margin-top: 20px;" class="btn btn-success btn-block" v-on:acao="avancar" :pai="this" role="button"><span class="glyphicon glyphicon-ok" aria-hidden="true"> 
      </span> Avançar</BotaoLoader>

    </div>

  </div>
  <div class="col-xs-12 col-sm-4"></div>
</div>

</div>
</template>