
<script>
/* eslint-disable */

var _ = require("lodash")

export default {

  name:"LoginCachorro",
  emits: ['update:email','update:senha'],
  props: {
    email: String,
    senha: String
  },

  data: function () {
                    return {
                          exibirSenha:false,
                        }
                      },

  mounted: function () {

    var ref = this; 

    let usernameInput = document.querySelector('.username');
    let passwordInput = document.querySelector('.password');

    let showPasswordButton = document.querySelector('.password-button');

    let face = document.querySelector('.face');

    passwordInput.addEventListener('focus', event => {
      document.querySelectorAll('.hand').forEach(hand => {
        hand.classList.add('hide');
      });
      document.querySelector('.tongue').classList.remove('breath');
    });

    passwordInput.addEventListener('blur', event => {
      document.querySelectorAll('.hand').forEach(hand => {
        hand.classList.remove('hide');
        hand.classList.remove('peek');
      });
      document.querySelector('.tongue').classList.add('breath');
    });

    usernameInput.addEventListener('focus', event => {
      let length = Math.min(usernameInput.value.length - 16, 19);
      document.querySelectorAll('.hand').forEach(hand => {
        hand.classList.remove('hide');
        hand.classList.remove('peek');
      });

      face.style.setProperty('--rotate-head', `${-length}deg`);
    });

    usernameInput.addEventListener('blur', event => {
      face.style.setProperty('--rotate-head', '0deg');
    });

    usernameInput.addEventListener('input', _.throttle(event => {
      let length = Math.min(event.target.value.length - 16, 19);

      face.style.setProperty('--rotate-head', `${-length}deg`);
    }, 100));

    showPasswordButton.addEventListener('click', event => {

      if (passwordInput.type === 'text') {

        ref.exibirSenha = false;

        passwordInput.type = 'password';
        document.querySelectorAll('.hand').forEach(hand => {
          hand.classList.remove('peek');
          hand.classList.add('hide');
        });
      } else {

        ref.exibirSenha = true;

        passwordInput.type = 'text';
        document.querySelectorAll('.hand').forEach(hand => {
          hand.classList.remove('hide');
          hand.classList.add('peek');
        });
      }

    });

  },


}
</script>

<template>

<!-- partial:index.partial.html -->
<div class="center">

  <div class="login">

    <img src="@/assets/logo-bychos.png">

    <h3>Sistema Genealógico de Pedigrees</h3>

    <label>
      <input class="username" type="text" autocomplete="on" placeholder="Email"
            :value="email"
            @input="$emit('update:email', $event.target.value)"
          />
    </label>
    <label>
      <input class="password" type="password" autocomplete="off" placeholder="Senha"
            :value="senha"
            @input="$emit('update:senha', $event.target.value)"
          />
      <button class="password-button"><div v-show="!exibirSenha">Mostrar</div><div v-show="exibirSenha">ocultar</div>  </button>
    </label>
    <button v-on:click="$emit('entrar')" class="login-button">Entrar</button>
  </div>


</div><!-- partial -->

</template>

<style scoped>

h3{
  color: white;
  margin-bottom: 40px;
}

input{
  text-transform:none;
}

* {
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  background-color: #e0e0e0;
  overflow: hidden;
  font-size: 12px;
}
.inspiration {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-family: 'Gill Sans', sans-serif;
  font-size: 12px;
  color: #969696;
}
.inspiration img {
  width: 60px;
}
.center {
  position: relative;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 350px;
  height: 500px;
  border-radius: 3px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: #0ca0a4;
}
@media screen and (max-height: 500px) {
  .center {
    transition: transform 0.5s;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
.center .ear {
  position: absolute;
  top: -110px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #243946;
}
.center .ear.ear--left {
  left: -135px;
}
.center .ear.ear--right {
  right: -135px;
}
.center .face {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 150px;
  margin: 80px auto 10px;
  --rotate-head: 0deg;
  transform: rotate(var(--rotate-head));
  transition: transform 0.2s;
  transform-origin: center 20px;
}
.center .eye {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #243946;
}
.center .eye.eye--left {
  margin-right: 40px;
}
.center .eye.eye--right {
  margin-left: 40px;
}
.center .eye .glow {
  position: relative;
  top: 3px;
  right: -12px;
  width: 12px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  transform: rotate(38deg);
}
.center .nose {
  position: relative;
  top: 30px;
  transform: scale(1.1);
}
.center .nose .glow {
  position: absolute;
  top: 3px;
  left: 32%;
  width: 15px;
  height: 8px;
  border-radius: 50%;
  background-color: #476375;
}
.center .mouth {
  position: relative;
  margin-top: 45px;
}
.center svg.smile {
  position: absolute;
  left: -28px;
  top: -19px;
  transform: scaleX(1.1);
  stroke: #243946;
}
.center .mouth-hole {
  position: absolute;
  top: 0;
  left: -50%;
  width: 60px;
  height: 15px;
  border-radius: 50%/100% 100% 0% 0;
  transform: rotate(180deg);
  background-color: #243946;
  z-index: -1;
}
.center .tongue {
  position: relative;
  top: 5px;
  width: 30px;
  height: 20px;
  background-color: #ffd7dd;
  transform-origin: top;
  transform: rotateX(60deg);
}
.center .tongue.breath {
  -webkit-animation: breath 0.3s infinite linear;
          animation: breath 0.3s infinite linear;
}
.center .tongue-top {
  position: absolute;
  bottom: -15px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #ffd7dd;
}
.center .line {
  position: absolute;
  top: 0;
  width: 30px;
  height: 5px;
  background-color: #fcb7bf;
}
.center .median {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 25px;
  border-radius: 5px;
  background-color: #fcb7bf;
}
.center .hands {
  position: relative;
}
.center .hands .hand {
  position: absolute;
  top: -6px;
  display: flex;
  transition: transform 0.5s ease-in-out;
  z-index: 1;
}
.center .hands .hand--left {
  left: 100px;
}
.center .hands .hand--left.hide {
  transform: translate(2px, -155px) rotate(-160deg);
}
.center .hands .hand--left.peek {
  transform: translate(0px, -150px) rotate(-160deg);
}
.center .hands .hand--right {
  left: 200px;
}
.center .hands .hand--right.hide {
  transform: translate(-6px, -155px) rotate(160deg);
}
.center .hands .hand--right.peek {
  transform: translate(-4px, -150px) rotate(160deg);
}
.center .hands .finger {
  position: relative;
  z-index: 0;
}
.center .hands .finger .bone {
  width: 20px;
  height: 20px;
  border: 2px solid #9e9e9e;
  border-bottom: none;
  border-top: none;
  background-color: #727272;
}
.center .hands .finger .nail {
  position: absolute;
  left: 0;
  top: 10px;
  width: 20px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #9e9e9e;
  background-color: #727272;
  z-index: -1;
}
.center .hands .finger:nth-child(1),
.center .hands .finger:nth-child(3) {
  left: 4px;
  z-index: 1;
}
.center .hands .finger:nth-child(1) .bone,
.center .hands .finger:nth-child(3) .bone {
  height: 10px;
}
.center .hands .finger:nth-child(3) {
  left: -4px;
}
.center .hands .finger:nth-child(2) {
  top: -5px;
  z-index: 2;
}
.center .hands .finger:nth-child(1) .nail,
.center .hands .finger:nth-child(3) .nail {
  top: 0px;
}
.center .login {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.center .login label {
  position: relative;
  padding: 0 20px;
}
.center .login label .fa {
  position: absolute;
  top: 40%;
  left: 35px;
  color: #bbb;
}
.center .login label .fa:before {
  position: relative;
  left: 1px;
}
.center .login input,
.center .login .login-button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 0px;
}
.center .login input {
  padding: 0 20px 0 40px;
  margin: 5px 0;
  box-shadow: none;
  outline: none;
}
.center .login input::-moz-placeholder {
  color: #ccc;
}
.center .login input:-ms-input-placeholder {
  color: #ccc;
}
.center .login input::placeholder {
  color: #ccc;
}
.center .login input.password {
  padding: 0 90px 0 40px;
}
.center .login .password-button {
  position: absolute;
  top: 15px;
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 27px;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #243946;
  color: #fff;
}
.center .login .password-button:active {
  transform: scale(0.95);
}
.center .login .login-button {
  width: calc(100% - 40px);
  margin: 20px 20px 0;
  outline: none;
  background-color: #243946;
  color: #fff;
  transition: transform 0.1s;
}
.center .login .login-button:active {
  transform: scale(0.95);
}
.center .footer {
  text-align: center;
  margin-top: 15px;
}
@-webkit-keyframes breath {
  0%, 100% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(60deg);
  }
}
@keyframes breath {
  0%, 100% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(60deg);
  }
}

</style>