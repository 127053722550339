<script>
/* eslint-disable */

import BotaoLoader from './BotaoLoader.vue'
import ComboboxBase from './ComboboxBase.vue'
import InputDataHora from '../components/InputDataHora.vue'
import { secureUpper } from '../utils'

import { useToast } from "vue-toastification"
const toast = useToast();

export default {

  components: {
    BotaoLoader,
    InputDataHora,
    ComboboxBase
  },
  data: function () {
            return {
              editando:false,
              textoEditado:null
            }
          },

  props: {
    dominio:null,
    type:null,
    id:null,
    coluna:null,
    texto: null,
    urlEdicao:null,
    urlCombobox:null,
    filtrosCombobox:Object,
  },

  methods: {
      secureUpper,
      editarItem:function(){

        var ref = this;
        ref.editando=true

        if(ref.type=='EDITAVEL_TEXTO'||ref.type=='EDITAVEL_TEXTAREA'){
          ref.textoEditado = ref.texto        
        }

      },

      salvar: function(ajaxPost,cancel){

        var ref = this;

        console.log("campo_editavel #A")

        if(!ref.dominio){
          alert("Parametro dominio tabelaAjax não informado")
          cancel()
          return
        }

        if(!ref.urlEdicao){
          alert("URL de edição não definida")
          cancel()
          return
        }

        if(ref.type=='EDITAVEL_COMBOBOX'){

        }

        var valor
        if(ref.type=='EDITAVEL_SENHA'){
          valor = encodeURIComponent(ref.textoEditado)
        }else{
          valor = secureUpper(encodeURIComponent(ref.textoEditado))
        }

        var parameters = "id="+ref.id+"&valor="+valor+"&coluna="+ref.coluna
                        +"&dominio="+ref.dominio+"&type="+ref.type

        ajaxPost(ref.urlEdicao,
                  parameters,
              function (result) {

                if(!result.status){
                  toast.error(result.message)
                  return
                }

                ref.textoEditado=null
                ref.editando=false
                ref.$emit('modificado')

                toast.success("Editado com sucesso")

            }
        );

      }

  },

}
</script>

<template>
  <div>

    <a v-if="type!='EDITAVEL_BOOLEAN'" v-show="!editando" style="color:black;" @click="editarItem">{{texto&&texto!='undefined'?texto:"..."}}</a>

    <a v-if="type=='EDITAVEL_BOOLEAN'" v-show="!editando" style="color:black;" @click="editarItem">{{texto?"Sim":"Não"}}</a>
  
    <div v-show="!editando" style="font-size: 8px;" @click="editarItem">
      <a>editar</a>
    </div>

    <div class="input-group-sm" v-show="editando" style="min-width: 200px;">

      <input v-if="type=='EDITAVEL_TEXTO'" class="form-control" type="text" v-model="textoEditado" style="width: calc(100% - 66px);float: left;">

      <textarea v-if="type=='EDITAVEL_TEXTAREA'" class="form-control" type="text" v-model="textoEditado" 
              style="width: calc(100% - 66px);height: 100px;float: left;z-index: 999;position: relative;" rows="5" cols="50"/>

      <input v-if="type=='EDITAVEL_SENHA'" class="form-control" type="text" v-model="textoEditado" style="width: calc(100% - 66px);float: left;text-transform:none;">

      <select v-if="type=='EDITAVEL_BOOLEAN'" class="form-control" v-model="textoEditado">
          <option value="1">Sim</option>
          <option value="0">Não</option>
      </select>

      <InputDataHora v-if="type=='EDITAVEL_DARAHORA'" v-model="textoEditado" style="width: calc(100% - 66px);float: left;"/>

      <ComboboxBase v-if="type=='EDITAVEL_COMBOBOX'" 
          v-model="textoEditado" 
          :filtros="filtrosCombobox"
          :url='urlCombobox'/>

      <span class="input-group-btn">
        <button @click="editando=false" class="btn btn-warning" type="button"><span class="glyphicon glyphicon-remove" aria-hidden="true">
        </span></button>

        <botaoLoader style="height: 30px;" class="btn btn-success " v-on:acao="salvar" :pai="this" role="button"><span class="glyphicon glyphicon-ok" aria-hidden="true">
        </span></botaoLoader>

      </span>

    </div>

  </div>
</template>

<style scoped>



</style>