<template>
  <div class="hello">

    <Menu class="ocultar-impressao"/>

    <FormExpansivel :pai="this" ref="mdPopup" :large="true" id="modalPopup" alinhamento="left" title="Salvar" v-bind:options="optionsPopup" class="ocultar-impressao">

      <form class="form-horizontal ocultar-impressao" role="form" >

          <fieldset>
            <div class="well">
                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Quantidade</label>
                    <div class="col-sm-7">
                      <input class="form-control" type="text" v-model="qtdQrCodes">
                    </div>
                </div>
                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Tamanho</label>
                    <div class="col-sm-6">

                        <div @click="tamanho='pequeno'" style="cursor: pointer;float: left;margin: 50px;"
                            :class="{'item-selecionado':tamanho=='pequeno' }">
                          <img :src="gerarQrCode('bychos_pequeno','pequeno')" style="width: 80px;height: 80px;">
                          <br>
                          <span>Pequeno</span>                     
                        </div>

                        <div @click="tamanho='grande'" style="cursor: pointer;float: left;"
                              :class="{'item-selecionado':tamanho=='grande' }">

                          <img :src="gerarQrCode('bychos_grande','grande')" style="width: 100px;height: 100px;">
                          <br>
                          <span>Grande</span>
                        </div>

                    </div>
                </div>

         </div>
        </fieldset>
      </form>

    </FormExpansivel>

    <div class="ocultar-impressao" style="margin:10px;padding: 5px;clear: both;">
      <TabelaAjax dominio="Medals" ref="tbDados" :root="this" :paginado="true" :colunas="configTbDados.columns" :url="configTbDados.url" :filtros="configTbDados.filtros" ></TabelaAjax>
    </div>

    <div v-if="configStyle" class="painelQrCodes">
      
      <div v-if="tamanho=='grande'">

          <div v-for="qrCode in listaQrCodes" :style="configStyle.qrCodeGrandeItem">
            <img :src="gerarQrCode(qrCode.codigo)" style="width: 100px;height: 100px;">
            <span :style="configStyle.qrCodeGrandeTexto">{{configStyle.qrCodeGrande?configStyle.qrCodeGrande.texto:"..."}}</span>
          </div>

      </div>

      <div v-if="tamanho=='pequeno'">
          <div  v-for="qrCode in listaQrCodes" :style="configStyle.qrCodePequenoItem">
            <img :src="gerarQrCode(qrCode.codigo)" style="width: 80px;height: 80px;">
            <span :style="configStyle.qrCodePequenoTexto">{{configStyle.qrCodePequeno?configStyle.qrCodePequeno.texto:"..."}}</span>
          </div>
      </div>

    </div>

  </div>
</template>

<script>
import TabelaAjax from '../components/TabelaAjax.vue'
import Menu from '../components/Menu.vue'
import FormExpansivel from '../components/FormExpansivel.vue'
import PopupConfirmacao from '../components/PopupConfirmacao.vue'
import ComboboxBase from '../components/ComboboxBase.vue'
import BotaoLoader from '../components/BotaoLoader.vue'
import { apiHost,frontHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,easyPostJson,easyGet } from '../utils'

const toast = useToast();

export default {

  props: {
    msg: String
  },

  components: {
    TabelaAjax,
    ComboboxBase,
    PopupConfirmacao,
    BotaoLoader,
    Menu,
    FormExpansivel
  },

    computed: {

      apiHost(){
        return apiHost
      }

    },

  data: function () {
                      return {  
                                listaQrCodes:[],
                                qtdQrCodes:10,
                                configStyle:null,
                                tamanho:"pequeno",

                                 optionsPopup:{
                                   confirm:{
                                     show:true,
                                     action:function(ajaxPost,cancel,item,refPai){

                                        refPai.listaQrCodes = refPai.gerarListaQrCodes();

                                        setTimeout(function() {

                                            console.log("====refPai.listaQrCodes:"+refPai.listaQrCodes.length);
                                            console.log("====refPai.listaQrCodes[0].codigo:"+refPai.listaQrCodes[0].codigo);

                                            var parametros = {codigos: refPai.listaQrCodes }

                                            refPai.$nextTick(function () {

                                                    easyPostJson("api/ajaxRegistrarQrCodes",parametros,null,function(result){

                                                          cancel()
                                                          refPai.$refs.mdPopup.limparRecolherDados()

                                                          refPai.$nextTick(function () {

                                                                print()
                                                                refPai.listaQrCodes = [];

                                                            })

                                                        },function(result){

                                                          cancel()

                                                        })



                                                    })


                                        },3000);

                                      } 
                                    }
                                  },

                                configTbDados:{
                                    filtros:[

                                      {
                                        name:"codigo",
                                        title:"Código",
                                        selected:"",
                                        type:"SEARCH",
                                      }

                                    ],
                                    columns : [ 
                                                {name:"codigo",title:"Codigo"},
                                                {name:"Animal.nome",title:"Pet"}
                                              ],
                                    url: apiHost+"api/ajaxListarMedals",
                                  },


                              }
                    },

    mounted: function () {

      this.carregarConfiguracaoEstilo()

    },

    methods: {

      secureUpper,

      gerarListaQrCodes(){

        var ref = this

        var itens = []

        var limite = ref.qtdQrCodes<100?ref.qtdQrCodes:100

        const urlConsulta = frontHost+"app/detalhes/"

        console.log("====urlConsulta:"+urlConsulta)

        for (var i = 0; i < limite; i++){

          var identificadorUnico = ref.uuidv4()
          var codigoQrCode = urlConsulta+identificadorUnico

          itens.push({texto:"item "+i,codigo: codigoQrCode })

        }

        return itens

      },

      uuidv4:function () {

        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );

      },

      carregarConfiguracaoEstilo:function(){

        var ref = this
        var parametros
        easyGet("api/ajaxCarregarConfiguracaoPedigree",parametros,null,function(result){

              ref.configStyle = result.pedigreeStyle;

          })


      },

      gerarQrCode:function(dadosQrCode,tamanho){

        var ref = this
        var chs

        if(!tamanho){
          tamanho = ref.tamanho
        }

        if(tamanho=="grande"){

          if(ref.configStyle&&ref.configStyle.qrCodeGrande&&ref.configStyle.qrCodeGrande.tamanho){
            chs = ref.configStyle.qrCodeGrande.tamanho;

            console.log("=====funcionou...qr grande...")

          }else{
            chs = "100x100";
          }

        }else {

          if(ref.configStyle&&ref.configStyle.qrCodePequeno&&ref.configStyle.qrCodePequeno.tamanho){
            chs = ref.configStyle.qrCodePequeno.tamanho;

            console.log("=====funcionou...qr pequeno...")

          }else{
            chs = "100x100";
          }

        }

        return "https://api.katon.tech/api/qr-code?type=ONLY_BASE64_IMAGE&chs="+chs+"&cht=qr&chl="+encodeURI(dadosQrCode)

      }

    }

}
</script>

<style scoped>

.item-selecionado{
  background-color: blue;
  color:white;
  padding: 10px;
}

.painelQrCodes{
  text-align: left;
  visibility:  hidden;
}

@media print {
  .painelQrCodes{
    text-align: left;
    position: absolute;
    left: 0px;
    top: 0px;
    visibility:visible;
  }

  @page { 
          size: A4 portrait;
          margin: 0;
        }

  .ocultar-impressao {display: none !important}
}

</style>
