<template>
  <div>	
  	<h1>Termos de uso</h1>
    <p v-html="termosUso"></p>
  </div>
</template>

<script>

//import { apiHost } from '../config'
import { easyGet } from '../utils'

export default {

  mounted(){

    var ref = this
    var parametros
    easyGet("api/ajaxCarregarTermosUso",parametros,null,function(result){

          ref.termosUso    = result.termosUso;

      })

  },

  data: function () {
                      return {
                            termosUso:"",
                      }
                    },

    methods: {
      

    }

}
</script>