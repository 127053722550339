

<script>
/* eslint-disable */

window.$ = window.jQuery = require('jquery');
require("../vendor/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js");

export default {
  name:"InputDataHora",
  props: {
    id:null,
    name:null,
    modelValue:null,
    exibirHora:false,
    disabled: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    width: {
      type:Number,
      required: false,
      default:100
    }
  },

  emits:["update:modelValue"],

  watch: {
    modelValue :{
      handler: function (val, oldVal) {

        var ref = this;
        var refField = ref.$refs.txtDataHora;
  
        var refFieldValor = ref.$refs.txtDataHorai;

        $(refFieldValor).val(ref.modelValue);

      }
    }
  },

  methods: {

    focus: function () {
      var ref      = this;
      var refField = ref.$refs.txtDataHora;
      refField.focus();
    }

  },

  mounted: function () {

    var ref = this;

    var refField      = ref.$refs.txtDataHora;
    var refFieldValor = ref.$refs.txtDataHorai;

    if(this.modelValue){

      $(refFieldValor).val(this.modelValue);
      $(refField).datetimepicker({
          format: ( this.exibirHora ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY' )
      });

    }else{

      $(refField).datetimepicker({
          defaultDate: new Date(),
          format: ( this.exibirHora ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY' )
      });

      console.log("========InputDataHora model:"+$(refFieldValor).val() )

      ref.$emit('update:modelValue', $(refFieldValor).val() );

    }

    $(refField).on('dp.change', function(e){
      ref.$emit('update:modelValue', $(refFieldValor).val() );
    });

  }

}
</script>

<template>
    <div ref="txtDataHora" class="input-group">
        <input ref="txtDataHorai" type="text" :class="{'form-control': true }" :disabled="disabled" v-bind:style="{ width: width +'px'}" />
        <span class="input-group-addon">
            <span class="glyphicon glyphicon-calendar"></span>
        </span>
    </div>
</template>

<style scoped>
</style>