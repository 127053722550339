
var urlApi;
var urlFront;

console.log("=====config NODE_ENV:"+process.env.NODE_ENV)

if(window.location.href.indexOf("herokuapp") !== -1){
	urlApi   = "https://lit-waters-38338.herokuapp.com/"
	urlFront = "https://lit-waters-38338.herokuapp.com/"
}else if(window.location.href.indexOf("https://bychos.com.br") !== -1){
	urlApi 		= "https://api.bychos.com.br/"
	urlFront    = "https://bychos.com.br/"
}else if(window.location.href.indexOf("bychos.com.br") !== -1){
	urlApi   = "http://bychos.com.br/"
	urlFront = "http://bychos.com.br/"

}else if(window.location.href.indexOf("191.252.202.235") !== -1){
	urlApi   = "http://191.252.202.235/"
	urlFront = "http://191.252.202.235/"

}else if(process.env.NODE_ENV=="development"){
	urlApi   = "http://localhost:3060/"
	urlFront = "http://localhost:8060/"

}else{
	urlApi 	 = "http://bychosv4.us-east-1.elasticbeanstalk.com/"
	urlFront = "http://bychos-site.s3-website-us-east-1.amazonaws.com/"
}

export const apiHost   = urlApi
export const frontHost = urlFront
