<script>
/* eslint-disable */

import BotaoLoader from './BotaoLoader.vue'
import ComboboxBase from './ComboboxBase.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
const toast = useToast();
import { secureUpper } from '../utils'

export default {

  components: {
    BotaoLoader,
    ComboboxBase
  },
  data: function () {
            return {
              editando:false,
              textoEditado:"",
              rgDigitado:"",
              busca:false,
              animalBusca:null
            }
          },

  props: {
    animal:null,
    coluna:null,
    compacto: {
      type: Boolean,
      default: false
    },
    edicao:  {
      type: Boolean,
      default: true
    },

    styleItem: null

  },

  mounted:function(){

    console.log("====itemarvore styleItem:"+this.styleItem);
    
  },

  computed: {

    apiHost(){
      return apiHost
    },

    sexo(){
      return this.coluna=="pai"?"M":"F"
    }

  },

  methods: {
      secureUpper,
      onIncluidoSucesso:function(){

        var ref = this
        ref.textoEditado=""
        ref.editando=false
        ref.$emit('modificado')

      },

      carregarNovoAncestral:function(ajaxPost,cancel){

        var ref = this;

        if(!ref.animal){
          toast.error("animal não informado")
          return 
        }

        if( (ref.animal.AnimalPai&&ref.animal.AnimalPai.id)||(ref.animal.AnimalMae&&ref.animal.AnimalMae.id ) ){

          if(!confirm("Ao trocar os ancestrais deste serão removidos da arvore, tem certeza?")){
            cancel()
            return
          }
        }

        var parameters = "id="+ref.animal.idFilho+
                          "&idPaiMae="+ref.animalBusca+
                          "&coluna="+ref.coluna

        ajaxPost(apiHost+"api/ajaxAtualizarPaiMae",
                  parameters,
              function (result) {

                if(!result.status){
                  toast.error(result.message)
                  return
                }

                ref.onIncluidoSucesso()

            }
        );

      },

      cancelarEdicao:function(){

        var ref = this;
        ref.editando=false

        ref.textoEditado  = ""
        ref.rgDigitado    = ""
        ref.busca         = false
        ref.animalBusca   = null
      },

      habilitarEdicao:function(){

        var ref = this;
        ref.textoEditado = ref.animal.nome=="XXXXXXXXXXXXXXXXXXXXXX"?"":ref.animal.nome 
        ref.rgDigitado   = ref.animal.rg
        ref.editando=true

      },

      salvar: function(ajaxPost,cancel){

        var ref = this;

        if(ref.busca){
          ref.carregarNovoAncestral(ajaxPost,cancel)
          return
        }

        //secureUpper

        var parameters = "idFilho="+ref.animal.idFilho+
                          "&id="+ref.animal.id+
                          "&nome="+ref.textoEditado+
                          "&rg="+ref.rgDigitado+
                          "&coluna="+ref.coluna

        ajaxPost(apiHost+"api/ajaxAtualizarArvore",
                  parameters,
              function (result) {

                if(!result.status){
                  toast.error(result.message)
                  return
                }

                ref.onIncluidoSucesso()

            }
        );

      }

  },

}
</script>

<template>
  <div style="position: relative;">

    <div v-if="!compacto">
      <a v-if="edicao" v-show="!editando" style="color:black;" @click="habilitarEdicao">{{animal.nome?animal.nome:'...'}}</a>
      <p v-if="!edicao" style="color:black;">{{animal.nome}}</p>
      <br><small v-if="!editando" class="label-rg">{{animal.rg}}</small>
    </div>

    <div v-if="compacto" :style="styleItem" class="alinhamento-esquerda">

      <a v-if="edicao" v-show="!editando" style="color:black;" @click="habilitarEdicao">{{animal.nome}}</a>
      <small v-if="!edicao" style="color:black;">{{animal.nome}}<small v-if="!editando&&animal.rg"> | {{animal.rg}}</small></small>
    </div>
 
    <div class="input-group-sm" v-show="editando" style="height: 90px;">

      <button v-show="!busca" 
            style="position: absolute;right: 0px;top: -20px;" 
            @click="busca=true" type="button" class="btn btn-info btn-sm">
        <i class="glyphicon glyphicon-search"/>
      </button>

      <div v-show="busca" style="min-width: 300px;">

          <b class="titulo">Busca de animal</b>
          <div style="width: 300px;">
            <ComboboxBase 
                :url="apiHost+'api/ajaxComboboxAnimais'"
                :filtros="{sexo:sexo}"
                v-model="animalBusca"/>
          </div>

      </div>

      <div v-show="!busca" style="min-width: 300px;">
        <b class="titulo" v-if="!animal.id">Inclusão de animal ({{animal.idFilho}})</b>
        <b class="titulo" v-if="animal.id">Editando animal ({{animal.idFilho}})</b>

        <input class="form-control" placeholder="Nome" @keyup="textoEditado=secureUpper(textoEditado)" type="text" v-model="textoEditado" style="width: calc(100% - 66px);float: left;">

        <input class="form-control" type="text" placeholder="RG" v-model="rgDigitado" style="width: calc(100% - 66px);float: left;" @keyup="rgDigitado=secureUpper(rgDigitado)">

       </div>

        <span class="input-group-btn">
          <button @click="cancelarEdicao" class="btn btn-warning" type="button"><span class="glyphicon glyphicon-remove" aria-hidden="true">
          </span></button>

          <botaoLoader style="height: 30px;" class="btn btn-success " v-on:acao="salvar" :pai="this" role="button"><span class="glyphicon glyphicon-ok" aria-hidden="true">
          </span></botaoLoader>
        </span>

    </div>
  </div>
</template>

<style scoped>

input{
  text-transform:uppercase;
}

.alinhamento-esquerda{
 text-align: left;
}

.titulo{
  color: white;
}

.label-rg{
  color: white;
}



</style>