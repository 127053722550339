<script>
/* eslint-disable */

import BotaoLoader from './BotaoLoader.vue'

export default {

  name:"FormExpansivel",
  data: function () {
                    return {
                              formOpen: false,
                              dados:null,
                              msgAlerta:null,
                              visivel:false
                        }
                      },

  mounted: function () {

  },

  methods: {
    resetForm: function () {


    },
    
    cancel: function(){
      this.limparRecolherDados();
    },

    limparRecolherDados: function(){
      this.formOpen = false;
      this.resetForm();
    },

  },

  components: {
    BotaoLoader
  },

  props: {
    id:null,
    title:null,
    description:null,
    options:null,
    large:null,
    small:null,
    pai:null,
    alinhamento: {
      type: String,
      default: function () {
        return 'center'
      }
    }

  },

}
</script>

<template>
<div class="container" id="app">
  <div class="add-product" :class="{'open': formOpen, 'form-large': large , 'form-small': small}" 
    style="margin:10px;">
    <div class="button-copy" v-show="!formOpen" @click="formOpen = true"><span style="font-size: 20px;color:black">+</span></div>
    <form v-show="formOpen" @submit="cancel()">

        <div v-if="options">
            <div class="modal-header">
                <h4 class="modal-title text-align-center fw-bold mt">{{title}}</h4>
                <p v-show="description" class="text-align-center fs-mini text-muted mt-sm">{{description}}</p>
                <slot name="cabecalho"></slot>
            </div>
            <div class="modal-body" :align="alinhamento">
               <slot></slot>
            </div>
            <div class="modal-footer">

               <div v-show="msgAlerta" class="alert alert-warning alert-sm" style="float:left;max-width:350px;"><span>{{msgAlerta}}</span></div>
            </div>
        </div>

       <botao-loader ref="btnConfirm" :pai="pai" v-if="options.confirm.show" class="submit-button" v-on:acao="options.confirm.action" role="button"><span v-if="options.confirm.enterHotkey==true" class="label label-default" style="background-color: rgb(85, 85, 85); border-color: rgb(60, 60, 60);">enter</span> {{options.confirm.text?options.confirm.text:'Confirmar'}}</botao-loader>

      <slot name="botoes"></slot>

      <div style="height: 30px;" class="cancel"><span @click="cancel()">Cancelar</span></div>
    </form>
  </div>
</div>
</template>

<style scoped>

@charset "UTF-8";

.container {
  background-image:  linear-gradient(39deg, rgb(0 0 0 / 93%) 3%, #ffffff 88%, rgb(0 0 0 / 93%) 100%);

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.add-product {
  transition: all 0.3s ease;
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 72px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

.add-product.open {
  background-color: #FAFAFA;
  padding: 18px 32px;
  border-radius: 5px;
  width: 420px;
  height: auto;
  cursor: default;
}

.form-large.open{
  width: 900px!important;
}

.form-small.open{
  width: 200px!important;
}

.add-product.open form {
  opacity: 1;
  transition: opacity 0.1s ease;
  transition-delay: 0.3s;
  height: auto;
}
.add-product .button-copy {
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  color: #f7f7f7;
}
.add-product form {
  transition: none;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.add-product .cancel {
  font-size: 12px;
  text-align: center;
  margin-top: 1em;
}
.add-product .cancel span {
  cursor: pointer;
}
.add-product .cancel span:hover {
  text-decoration: underline;
}

.submit-button {
  display: block;
  background-color: #3498DB;
  height: 40px;
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875em;
  border: none;
  font-weight: 700;
  padding: 0 34px;
  margin: 0 auto;
}
.submit-button img {
  position: relative;
  top: 3px;
  right: 6px;
}
.submit-button:hover {
  background-color: #217dbb;
  cursor: pointer;
}

.featured-note {
  color: #949494;
  font-size: 12px;
  margin: 4px 0px;
  line-height: 18px;
  font-style: italic;
}

form * {
  outline: none;
}

label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
}
label.emoji input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 21px;
  margin: 0;
  display: none;
}
label.emoji input + span:after {
  content: "😶";
  font-size: 18px;
  top: 2px;
  left: 2px;
  position: relative;
  height: 18px;
  display: inline-block;
}
label.emoji input:checked + span:after {
  content: "😀";
}

.form--field {
  width: 420px;
  margin: 10px 0;
}
.form--field.-short {
  width: 120px;
}

.form--price {
  position: absolute;
  line-height: 38px;
  width: 16px;
  color: #C7C7C7;
  text-align: center;
}
.form--price + input {
  padding-left: 14px;
}

.form--container {
  width: 420px;
}
.form--container.-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -12px;
}

.form--element {
  background-color: #fff;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  font-size: 14px;
  line-height: 28px;
  padding: 0 4px;
  color: #3D3D3D;
  width: 100%;
  margin: 4px 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
.form--element:focus {
  border: 1px solid white;
  border-radius: 2px;
}
.form--element:not(.texteare) {
  height: 30px;
}
.form--element.textarea {
  height: 80px;
  resize: none;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background-color: #F3F4F5;
  cursor: default;
}

a {
  text-decoration: none;
  cursor: pointer;
}

::selection {
  background-color: #F5617A;
  color: #fff;
}

::-moz-selection {
  background-color: #F5617A;
  color: #fff;
}

</style>