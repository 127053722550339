import { apiHost } from './config'
import { useToast } from "vue-toastification";
import axios from 'axios'

var paises = require("./js/paises.json")

/* eslint-disable */

$.ajaxSetup({
    cache: false
});

export function easyPost(url,parametros,mensagemSucesso,callbackSucesso,callbackComplete){

   	  const toast = useToast();

      $.ajax({
          url: apiHost+url ,
          data: parametros,
          dataType: "JSON",
              type: "POST",
          headers: {"Authorization": 'Bearer '+localStorage.getItem('token')},
         }).success(function(result){

        if(result.status){

	    	if(mensagemSucesso){
	        	toast.success(mensagemSucesso)
	    	}

	    	if(callbackSucesso){
	        	callbackSucesso(result)
	    	}

        }else{
        	toast.error(result.message)
        }

      }).complete(function(result){
    
        if(callbackComplete){
            callbackComplete(result)
        }

      }).error(function(respErro){

          var result;
 

          if(respErro.responseText){
            result = JSON.parse(respErro.responseText);
          }

          try{

            if(result&&result.message){

              toast.error(result.message);
              
            }else if(respErro.status=="403"){ 
              toast.error("Acesso não permitido, entre em contato com o administrador para verificar se você possuí as permissões de acesso adequadas.");
            //}else if(respErro.status=="401"){ //Fez a requisição de conteudo logado via ajax mas já perdeu login...
            //  location.reload();
            }else{
              toast.error("Houve um problema, tente novamente mais tarde.");

            }

          }catch(e){

              toast.error("Houve um problema, tente novamente mais tarde.");
          }

      });


}

export function easyPostJson(url,dados,mensagemSucesso,callbackSucesso,callbackComplete){

      const toast = useToast();

      console.log("===easyPost #A")

      axios({

          method: 'POST',
          url: apiHost+url,
          data: dados,
          headers: {
            "Authorization": 'Bearer '+localStorage.getItem('token')
          }

        }).then(function (response) {
          
         var result = response.data //status,etc...

         //response.status //200
        //alert("resposta:"+JSON.stringify(response) )

        if(result.status){

            console.log("===easyPost #C")

        if(mensagemSucesso){
            toast.success(mensagemSucesso)
        }

        if(callbackSucesso){
            callbackSucesso(result)
        }

        }else{
          toast.error(result.message+" (#10)")
        }

        if(callbackComplete){
          callbackComplete(result)
        }

          //response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))

        }).catch((error) => {
          console.error("====easyPostJson Error:"+error)

          if(callbackComplete){
            callbackComplete()
          }

        })

}


export function easyGet(url,parametros,mensagemSucesso,callbackSucesso,callbackComplete){

      const toast = useToast();

      $.ajax({
          url: apiHost+url ,
          data: parametros,
          dataType: "JSON",
              type: "GET",
          headers: {"Authorization": 'Bearer '+localStorage.getItem('token')},
         }).success(function(result){

        if(result.status){

        if(mensagemSucesso){
            toast.success(mensagemSucesso)
        }

        if(callbackSucesso){
            callbackSucesso(result)
        }

        }else{
          toast.error(result.message)
        }

      }).complete(function(result){
    
        if(callbackComplete){
          callbackComplete(result)
        }

      }).error(function(respErro){

          var result;
          if(respErro.responseText){
            result = JSON.parse(respErro.responseText);
          }

          try{

            if(result&&result.message){

              toast.error(result.message);
              
            }else if(respErro.status=="403"){ 
              toast.error("Acesso não permitido, entre em contato com o administrador para verificar se você possuí as permissões de acesso adequadas.");
            //}else if(respErro.status=="401"){ //Fez a requisição de conteudo logado via ajax mas já perdeu login...
            //  location.reload();
            }else{
              toast.error("Houve um problema, tente novamente mais tarde.");

            }

          }catch(e){

              toast.error("Houve um problema, tente novamente mais tarde.");
          }

      });


}

export function extrairId(valor){

  if(!valor)
    return

  if(valor&&valor.includes("|")){

    console.log("====initSelection ---- encontrou barra...valor:"+valor)

    var valorSplit = valor.split("|")

    console.log("====initSelection ---- utilizado:"+valorSplit[0])

   return valorSplit[0]
  }

  return valor
}

export function nullCheck(param){

  if( (!param)||param=="null"||param=="undefined"||param==undefined)
    return null

  return param
}

export function buscarPais(codigoPais){

  if(!codigoPais){
    return ""
  }

  const paisesFiltro = paises.lista.filter(pais => pais.id.toLowerCase().includes(codigoPais.toLowerCase() ));

  console.log("====buscarPais:"+codigoPais)

  if(!paisesFiltro||paisesFiltro.length==0){
    return ""
  }

  console.log("====paisesFiltro[0].nome:"+paisesFiltro[0].nome)

  return paisesFiltro[0].nome;
}

export function secureUpper(texto){

  if(!texto){
    return
  }

  return texto.toUpperCase()
}

export function resizeImage(settings){
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');
    var dataURItoBlob = function (dataURI) {
        var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    };
    var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}