<template>
  <div class="hello">


    <div v-if="!codigoQr">
      <p>qr code não carregado</p>
    </div>

    <form v-if="codigoQr" class="form-horizontal ocultar-impressao" role="form" style="position: absolute;left: 0px;top: 20px;width: 400px;">
        <fieldset>

          <div class="form-group">
              <label for="nome" class="col-sm-4 control-label">Tamanho</label>
              <div class="col-sm-8">
                <select v-model="tamanho" class="form-control">
                  <option value="grande">grande</option>
                  <option value="pequeno">pequeno</option>
                </select>
              </div>
          </div>
        </fieldset>
    </form>

    <div v-if="codigoQr" class="painelQrCodes">
      
      <div v-show="tamanho=='grande'">
          <div v-for="qrCode in listaQrCodes" style="position: relative;float:left;height: 270px;">

            <img :src="gerarQrCode(qrCode.codigo)" style="width: 100px;height: 100px;" >
            <span style="position:absolute;bottom:10px;left:0px;width:100%;font-size: 18px;font-weight: 500;">Saiba meu nome</span>
            
          </div>
      </div>

      <div v-show="tamanho=='pequeno'">
          <div  v-for="qrCode in listaQrCodes" style="position: relative;float:left;height: 130px;">
            <img :src="gerarQrCode(qrCode.codigo)" style="width: 80px;height: 80px;">
            <span style="position:absolute;bottom:10px;left:0px;width:100%;font-size: 10px;font-weight: 500;">Saiba meu nome</span>
          </div>
      </div>

    </div>

  </div>
</template>

<script>
import { apiHost,frontHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,easyPostJson,easyGet } from '../utils'

const toast = useToast();

export default {

    props: {
      msg: String
    },

    components: {
    },

    computed: {

      listaQrCodes(){

        var ref = this

        var itens = []

        const urlConsulta = frontHost+"app/detalhes/"

        console.log("====urlConsulta:"+urlConsulta)

        var codigoQrCode = ref.codigoQr

        console.log("====codigoQrCode:"+codigoQrCode)

        itens.push({texto:"item",codigo: codigoQrCode })

        return itens

      },

      apiHost(){
        return apiHost
      }

    },

  data: function () {
                      return {
                                qtdQrCodes:10,
                                tamanho:"pequeno",
                                rgAnimal:null,
                                codigoQr:null
                             }
                    },

    mounted: function () {

      var ref = this;

      if(ref.$route.params.identificador){

        ref.rgAnimal =  ref.$route.params.identificador.replaceAll("-","/")

        var parametros = "rg="+ref.rgAnimal
        easyGet("api/ajaxCarregarMedal",parametros,null,function(result){

           ref.codigoQr = result.codigo

        })

      }

    },

    methods: {

      secureUpper,

      gerarQrCode:function(dadosQrCode,tamanho){

        var ref = this
        var chs

        if(!tamanho){
          tamanho = ref.tamanho
        }

        if(tamanho=="grande"){
          chs = "250x250"
        }else {
          chs = "120x120"
        }

        return "https://api.katon.tech/api/qr-code?type=ONLY_BASE64_IMAGE&chs="+chs+"&cht=qr&chl="+encodeURI(dadosQrCode)

      }

    }

}
</script>

<style scoped>

.item-selecionado{

  background-color: blue;
  color:white;
  padding: 10px;

}

.painelQrCodes{
  position: absolute;
  left: 140px;
  top: 70px;
  visibility:visible;
}

@media print {

  .ocultar-impressao {display: none !important}
}

</style>
