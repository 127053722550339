import { createWebHistory, createRouter } from "vue-router";

import Especies from '@/views/Especies.vue'
import Racas from '@/views/Racas.vue'
import Franquias from '@/views/Franquias.vue'
import Usuarios from '@/views/Usuarios.vue'
import Criadores from '@/views/Criadores.vue'
import Animais from '@/views/Animais.vue'
import Pedigree from '@/views/Pedigree.vue'
import CertificadoCriador from '@/views/CertificadoCriador.vue'
import Login from '@/components/Login.vue'
import App from '@/App.vue'
import Busca from '@/views/Busca.vue'
import Page404 from '@/views/404.vue'
import Cores from '@/views/Cores.vue'
import GerarQrCode from '@/views/GerarQrCode.vue'
import DetalhesPet from '@/views/DetalhesPet.vue'
import Microchips from '@/views/Microchips.vue'
import CadastroPet from '@/views/CadastroPet.vue'
import Whatsapp from '@/views/Whatsapp.vue'
import ConfiguracaoPedigree from '@/views/ConfiguracaoPedigree.vue'
import ConfiguracaoOutros from '@/views/ConfiguracaoOutros.vue'
import CadastroPublicoProprietario from '@/views/CadastroPublicoProprietario.vue'
import BuscaV2 from '@/views/BuscaV2.vue'
import Termos from '@/views/Termos.vue'
import ExibirQrCode from '@/views/ExibirQrCode.vue'

const routes = [

  {
    path: "/",
    name: "Home",
    component: BuscaV2,
  },

  {
    path: "/app",
    name: "App",
    component: App,
  },
  {
    path: "/app/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/app/404",
    name: "Page404",
    component: Page404,
  },
  {
    path: "/app/consulta/",
    name: "Busca.",
    component: Busca,
  },
  {
    path: "/app/consulta/:identificador",
    name: "Busca",
    component: Busca,
  },
  {
    path: "/app/detalhes/:identificador",
    name: "Detalhes do pet",
    component: DetalhesPet,
  },

  {
    path: "/app/cadastroPet",
    name: "Cadastro do pet",
    component: CadastroPet,
  },

  {
    path: "/app/buscaV2",
    name: "BuscaV2",
    component: BuscaV2,
  },
  {
    path: "/app/termos",
    name: "Termos",
    component: Termos,
  },
  {
    path: "/app/exibirQrCode/:identificador",
    name: "ExibirQrCode",
    component: ExibirQrCode,
  },
  {
    path: "/app/cadastroProprietario",
    name: "Cadastro do proprietário",
    component: CadastroPublicoProprietario,
  },

  {
    meta: { requiresAuth:true} ,
    path: "/app/especies",
    name: "Especies",
    component: Especies
  },

  {
    meta: { requiresAuth:true} ,
    path: "/app/whatsapp",
    name: "Whatsapp",
    component: Whatsapp
  },
  {
    meta: { requiresAuth:true} ,
    path: "/app/configuracaoPedigree",
    name: "Configuracao Pedigree",
    component: ConfiguracaoPedigree
  },


  {
    meta: { requiresAuth:true} ,
    path: "/app/configuracaoOutros",
    name: "Configuracao Outros",
    component: ConfiguracaoOutros
  },

  {
    meta: { requiresAuth:true} ,
    path: "/app/cores",
    name: "Cores",
    component: Cores
  },
  {
    meta: { requiresAuth:true} ,
    path:'/app/racas', 
    name: 'Racas', 
    component: Racas,
  },
  {
    meta: { requiresAuth:true} ,
    path:'/app/franquias', 
    name: 'Franquias', 
    component: Franquias,
  },
  {
    meta: { requiresAuth:true} ,
    path:'/app/usuarios', 
    name: 'Usuarios', 
    component: Usuarios,
  },

  {
    meta: { requiresAuth:true} ,
    path:'/app/criadores', 
    name: 'Criadores', 
    component: Criadores,
  },

  {
    meta: { requiresAuth:true} ,
    path:'/app/animais', 
    name: 'Animais', 
    component: Animais,
  },
  {
    meta: { requiresAuth:true} ,
    path:'/app/pedigree/:rg', 
    name: 'Pedigree',
    component: Pedigree,
  },

  {
    meta: { requiresAuth:true} ,
    path:'/app/gerarQrCode/', 
    name: 'GerarQrCode',
    component: GerarQrCode,
  },
  {
    meta: { requiresAuth:true} ,
    path:'/app/microchips/', 
    name: 'Microchips',
    component: Microchips,
  },
  {
    meta: { requiresAuth:true} ,
    path:'/app/certificadoCriador/:inscricao', 
    name: 'CertificadoCriador',
    component: CertificadoCriador
  }
];

const router = createRouter({
  base: '/app/',
  history: createWebHistory(),
  routes,
});

// Meta Handling
router.beforeEach((to, from, next) => {

  console.log("======to.fullPath:"+to.fullPath)

  if(to.fullPath.includes("#menu-") ){

      var posIni = to.fullPath.indexOf("#menu-")+6
      var posFim = to.fullPath.length

      var opcao = to.fullPath.substring(posIni,posFim)

      console.log("======posIni:"+posIni)
      console.log("======posFim:"+posFim)
      console.log("======opcao:"+opcao)

      if(opcao=="sair"){
        localStorage.removeItem('token');
        window.location.href = "/app/";
        return;
      }

      next({
        path: '/app/'+opcao
        //,params: { nextUrl: to.fullPath }
      })

      return 
  }

  if(to.fullPath=="/app/"||to.fullPath=="/app"){

    if (localStorage.getItem('token') == null){
        
      next({
        path: '/app/login',
        params: { nextUrl: to.fullPath }
      })

    }else{

      next({
        path: '/app/animais',
        params: { nextUrl: to.fullPath }
      })

    }

  }else if (to.matched.some(record => record.meta.requiresAuth)) {

    if (localStorage.getItem('token') == null) {

      next({
        path: '/app/login',
        params: { nextUrl: to.fullPath }
      })

    } else {

      /*
      let user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.is_admin)) {

        if (user.is_admin == 1) {
          next()
        } else {
          next({ name: 'userboard' })
        }

      } else {
        next()
      }*/

      next()

    }

  } else {

    next()

  }
})


export default router;