<script>
  /* eslint-disable */

var moment = require('moment');
import { apiHost } from '../config'

import { useToast } from "vue-toastification";
import { secureUpper,easyGet } from '../utils'

const toast = useToast();

export default {

  props: {
    msg: String
  },

  components: {
  },

  mounted: function () {

    var ref = this;

    var filtro = ref.$route.query.filtro

    if(filtro){

      var parametros = "sigla="+filtro
      easyGet("api/ajaxTermoSigla",parametros,null,function(result){

        ref.termo = result.termo

      })

    }

    if(ref.$route.params.identificador){

      ref.rgAnimal =  ref.$route.params.identificador.replaceAll("-","/")
      ref.consultarAnimal()

    }

  },

  computed:{

      logado() {
        return localStorage.getItem('token')!=null
      },

      dataValidadeCriador() {
        if(!this.criador.vencimento)
          return ""

        return   moment(this.criador.vencimento).format('DD/MM/YYYY')
      },

      dataAtual() {
        return moment().format('DD/MM/YYYY HH:mm')
      },

      dataNascimento() {
        return moment(this.animal.dataNascimento).format('DD/MM/YYYY')
      }

  },

  data: function () {
                      return {
                            rgAnimal:"",
                            termo:"",
                            animal:null,
                            criador:null,
                            inscricaoCriador:"",
                        }
                    },

  methods: {
          
          secureUpper,

          consultarCriador: function () {

            var ref = this;

            if(ref.rgAnimal){

              ref.consultarAnimal()

            }else{

              if(!ref.inscricaoCriador){
                toast.error("Nada informado")
                return
              }

              if(ref.inscricaoCriador.length<4){
                toast.error("Incrição inválida")
                return
              }

              var filtro = ref.$route.query.filtro

              var parametros = "filtro="+filtro+"&inscricao="+ref.inscricaoCriador
              easyGet("api/ajaxConsultaCriador",parametros,null,function(result){

                 ref.criador = result.criador

              })

            }

          },

          consultarAnimal: function (rg) {

            var ref = this;

            if(!ref.rgAnimal){
              toast.error("rg não informada")
              return
            }

            var parameters = "rg="+ref.rgAnimal
            if(ref.$route.query.filtro){
              parameters+= "&filtro="+ref.$route.query.filtro
            }

            $.ajax({
                url: apiHost+"api/ajaxConsultaAnimal",
                data: parameters,
                dataType: "JSON",
                type: "POST",
               }).success(function(result){
                
                if(result.status==true){

                  console.log("===result.animal:"+result.animal)

                  if(result.animal==null){
                    window.location.href = "/404";
                    return;
                  }

                  ref.animal = result.animal;

                }else{
                    toast.error(result.message);
                }

            }).complete(function(result){
              

            });


          }

    }

}
</script>

<template>
<div>

	<div class="panel panel-default" style="padding: :10px;width: 100%;">
	  <div class="panel-heading"><b style="font-size: 18px;">Busca</b></div>
	  <div class="panel-body" style="">

	    <form id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">
	        <fieldset>

              <div class="form-group">
                  
                  <div class="col-sm-4">
                    <p class="control-label"><b>Consultar Pedigree</b>
                        <br><small>pesquisar por nº de registro</small>
                    </p>
                    
                  </div>

                  <div class="col-sm-7">
                      <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="rgAnimal" keyup="rgAnimal=secureUpper(rgAnimal)">
                  </div>
              </div>

	            <div class="form-group">
	                <b><p class="col-sm-4 control-label">Consultar {{termo?termo:"criador"}}</p></b>
	                <div class="col-sm-7">
	                    <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="inscricaoCriador" keyup="inscricaoCriador=secureUpper(inscricaoCriador)">
	                </div>
	            </div>
	        </fieldset>
	    </form>

	  </div>
	  <div class="panel-footer">

	     <div class="form-actions">
			<button type="button" class="btn btn-info" v-on:click="consultarCriador">Consultar</button>
	     </div>

	  </div>

	</div>

  <div v-if="criador" class="well" style="text-align: left;width: 400px;margin: 10px;height: 300px;">
      <div>
        <p>{{termo?termo:'Criador'}}:</p>
        <b>{{criador.nomeEmpresa}}</b>
      </div>
      <br>
      <div>
        <p>Proprietário:</p>
        <b>{{criador.nome}}</b>
      </div>
      <br>
      <div>
        <p>Inscrição:</p>
        <b>{{criador.inscricao}}</b>
      </div>
      <br>
      <div>
        <p>Vencimento:</p>
        <b>{{dataValidadeCriador}}</b>
      </div>
  </div>

    <div v-if="animal" class="well" style="text-align: left;width: 400px;margin: 10px;">
      <div >
        <div style="float:left;margin-right: 50px;">
          <p>Nome:</p>
          <b>{{animal.nome}}</b>
        </div>
        <div>
          <p>Proprietário:</p>
          <b>{{animal.Proprietario?animal.Proprietario.nome:"indefinido"}}</b>
        </div>
        <br><br>
        <div style="float: left;margin-right: 50px;">
          <p>Raça:</p>
          <b>{{(animal.Raca&&animal.Raca.nome)?animal.Raca.nome:"indefinida"}}</b>
        </div>
        <div>
          <p>RG:</p>
          <b>{{animal.rg}}</b>
        </div>

        <br><br>
        <div>
          <b>{{dataAtual}}</b>
        </div>
      </div>
      <div style="margin-top: 20px;">
      </div>
    </div>

</div>
</template>