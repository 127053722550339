<script>
/* eslint-disable */

import BotaoLoader from './BotaoLoader.vue'

export default {
  name:"PopupSimples",
  
  components: {
    BotaoLoader
  },

  data: function () {
          return {
            dados:null,
            msgAlerta:null,
            visivel:false
          }
        },

  props: {
    id:null,
    title:null,
    description:null,
    options:null,
    large:null,
    small:null,
    pai:null,
    alinhamento: {
      type: String,
      default: function () {
        return 'center'
      }
    }

  },
  
  methods: {

      mostrarAlerta: function (texto) {

        var ref = this;
        ref.msgAlerta = texto;

        setTimeout(function() {

          ref.msgAlerta = null;

        }, 5000);

      },

      simularClickBotaoConfirmar: function () {

        var ref = this;
        $(ref.$refs.btnConfirm.$el).trigger("click");

      },

      abrirPopup: function () {

        var ref = this;
        ref.visivel = true;

      },

      isVisible: function () {
        var ref = this;
        return ref.visivel;
      },

      ocultarPopup: function () {
        var ref = this;
        ref.visivel = false;
      }

  }

}
</script>

<template>

  <!--
    //Foi necessário remover o tabindex="-1" pois com ele não estava sendo possível focar prog no select2.
  -->
  <div v-show="visivel" style="position: fixed;left:0;top:0;z-index: 1044;width: 100%;height: 100%;background-color: #00000069;">
  
  </div>

  <div v-show="visivel"  v-bind:id="id" style="position: fixed;left:30%;top:100px;z-index: 1044;">

        <div class="modal-dialog" v-bind:class="{ 'modal-lg': large , 'modal-sm': small }">
            <div class="modal-content">
                <div class="modal-header">
                    <button v-show="options.canClose==null||options.canClose==true" type="button" class="close" v-on:click="ocultarPopup">&times;</button>
                    <h4 class="modal-title text-align-center fw-bold mt">{{title}}</h4>
                    <p v-show="description" class="text-align-center fs-mini text-muted mt-sm">{{description}}</p>
                    <slot name="cabecalho"></slot>
                </div>
                <div class="modal-body" :align="alinhamento" style="overflow-y:scroll;max-height:800px;">
                   <slot></slot>
                </div>
                <div class="modal-footer">

                   <div v-show="msgAlerta" class="alert alert-warning alert-sm" style="float:left;max-width:350px;"><span>{{msgAlerta}}</span></div>


                   <slot name="botoes"></slot>
                   <botao-loader ref="btnConfirm" :pai="pai" v-if="options.confirm.show" class="submit-button" v-on:acao="options.confirm.action" role="button"><span v-if="options.confirm.enterHotkey==true" class="label label-default" style="background-color: rgb(85, 85, 85); border-color: rgb(60, 60, 60);">enter</span> {{options.confirm.text?options.confirm.text:'Confirmar'}}</botao-loader>

                   <div class="cancel" v-show="(options.canClose==null||options.canClose==true)&&(options.showCloseButton==null||options.showCloseButton==true)" style="height:30px;"><span @click="ocultarPopup">Cancelar</span></div>

                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

.cancel {
  font-size: 12px;
  text-align: center;
  margin-top: 1em;
}
.cancel span {
  cursor: pointer;
}
.cancel span:hover {
  text-decoration: underline;
}

.submit-button {
  display: block;
  background-color: #3498DB;
  height: 40px;
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875em;
  border: none;
  font-weight: 700;
  padding: 0 34px;
  margin: 0 auto;
}
.submit-button img {
  position: relative;
  top: 3px;
  right: 6px;
}
.submit-button:hover {
  background-color: #217dbb;
  cursor: pointer;
}

</style>