<template>
  <button type="button" style="height:40px;padding-top:0px;" v-on:click="acao(item)" :disabled="this.carregando==true||this.disabled==true"> <slot>Enviar</slot> <img v-if="this.carregando" src="../assets/loader-wheel.svg" style="-webkit-transform:scale(0.4)"></button>
</template>

<script>

/* eslint-disable */
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name:"BotaoLoader",
  data: function () {
          return {
            carregando:false
          }
        },
  props: {

    item:null,
    pai:null,
    disabled: {
        type: Boolean,
        default:false
    }

  },

  template: '',
  methods: {

    cancel: function () {
      this.carregando = false;
    },
    ajaxPost: function (url,parameters,successCalback,cancelCalback) {


      var ref = this;

      $.ajax({
              url: url , 
              dataType: "JSON",
                  type: "POST",
              data: parameters,
              headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
             }).success(function(result){
              
              if(result.status==true){
          
                successCalback(result);

              }else{

                if(cancelCalback){

                  cancelCalback(result);

                }else{

                  toast.error(result.message, {
                      timeout: 2000
                  });

                }

              }

          }).complete(function(result){
            
            ref.carregando = false;

          }).error(function(respErro){

            ref.carregando = false;

            if(respErro.status=="403"){ 

              toast.error("Acesso não permitido, entre em contato com o administrador para verificar se você possuí as permissões de acesso adequadas.", {
                  timeout: 2000
              });

            //}else if(respErro.status=="401"){ //Fez a requisição de conteudo logado via ajax mas já perdeu login...
            //  location.reload();
            }else{

              try{

                var result;
                if(respErro.responseText){
                  result = JSON.parse(respErro.responseText);
                }

                if(result&&result.message){

                  toast.error(result.message, {
                      timeout: 2000
                  });

                }else{

                  toast.error("Houve um problema, tente novamente mais tarde.", {
                      timeout: 2000
                  });

                }

              }catch(e){

                  toast.error("Houve um problema, tente novamente mais tarde.", {
                      timeout: 2000
                  });

              }


            }


          });

          //TODO: implementar outros erros...

    }, 

    acao: function (item) {

      var ref = this;
      ref.carregando = true;

      console.log(item);

      ref.$nextTick(function () {

        ref.$emit('acao',ref.ajaxPost,ref.cancel,ref.item,ref.pai);

      });

    }

  }



}
</script>

<style scoped>
</style>



