<script>
  /* eslint-disable */

var moment = require('moment');
import { apiHost } from '../config'

import { useToast } from "vue-toastification";
import { secureUpper,easyPostJson } from '../utils'
import Menu from '../components/Menu.vue'

const toast = useToast();

export default {

  components: {
    Menu
  },

  mounted: function () {

    var ref = this;
    ref.getLocation();

  },

  computed:{

      apiHost(){
        return apiHost
      },

      matriz() {
        return localStorage.getItem('matriz')=="true"
      },

      token(){
        return localStorage.getItem('token')
      }

  },

  data: function () {
                      return {
                              localizacao:null,
                              carregando:false,
                              exibirInstrucoesQrCode:false,
                              dadosAnimal:null
                             }
                    },

  methods: {
          
          secureUpper,

          chamarProprietario:function(telefone){

              var ref = this;
              var textoEnviado = "Oi tudo bem? Encontrei o "+ref.dadosAnimal.nome+
                                ". Vou lhe passar minha localização:"

              var telefoneComDddNros = telefone.replace( /[^\d]+/g, '');

              var nroFormatado = "55"+telefoneComDddNros;

              var url;
              if( navigator.userAgent.indexOf("OPR") > -1){
                url = "https://web.whatsapp.com/send?phone="+nroFormatado+"&text="+encodeURIComponent(textoEnviado);
              }else{
                url = "https://api.whatsapp.com/send?phone="+nroFormatado+"&text="+encodeURIComponent(textoEnviado); 
              }

              window.open(url,"_blank");


          },

          getLocation:function(){

            var ref = this
            ref.carregando = true

            ref.$nextTick(function () {

                if(navigator.geolocation){

                  navigator.geolocation.getCurrentPosition(function(position){

                    ref.localizacao = {latitude:position.coords.latitude,
                                        longitude:position.coords.longitude };

                    ref.registrarAcessoPet();

                  },function(data){

                    console.log("=====FALHA getLocation data:"+data)
  
                    ref.carregando = false

                  });

                } else {

                  ref.carregando = false

                  toast.error("Localização não suportada pelo navegador");
                }

             })
            
          },

          copiarCodigoMedal: function (){

            var ref = this;
            var codigo = ref.$route.params.identificador;
            var promise = navigator.clipboard.writeText(codigo);

            toast.info("O código foi copiado para area de transferencia");

          },

          registrarAcessoPet: function (rg){

            var ref = this
            var parametros = {localizacao: ref.localizacao,
                                identificador: ref.$route.params.identificador }
            easyPostJson("api/ajaxAcessoPet",parametros,"",function(result){

                    ref.dadosAnimal = result.dadosAnimal

                }, function(result){

                  if(result&&result.codigoErro=="PET_NAO_CONFIGURADO"){

                    if(ref.matriz){ 
                      toast.error("Para fazer o cadastro do proprietário e PET você precisa sair da conta de administrador.");
                      return
                    }

                    ref.exibirInstrucoesQrCode = true;
                  }

                  ref.carregando = false

                })

          }

    }

}
</script>

<template>

<div>
  <Menu/>

<div class="row">

  <div class="col-xs-12 col-sm-12 col-md-3"></div>

  <div class="col-xs-12 col-sm-12 col-md-6" style="position:relative;border: #4dc7b1;border-style: solid;padding: 30px!important;margin-top: 100px;min-height: 300px;">

    <h1 v-show="carregando">Aguarde um momento...</h1>
    <img v-show="carregando" src="@/assets/loader-wheel.svg" height="50" style="position:absolute;left: 50%;">

    <div @click="getLocation" style="cursor: pointer;" v-show="carregando==false&&localizacao==null" class="alert alert-info">Compartilhe sua localização para liberar o acesso aos dados adicionais. Verifique os avisos do navegador para liberar o compartilhamento da localização (icone marcador de mapa)</div>

    <div v-if="exibirInstrucoesQrCode">
      <h1>Medal não configurada</h1>
      <h2>{{$route.params.identificador}}</h2>
      <br><br>
      <a class="btn btn-success" :href="'/app/cadastroPet?medal='+$route.params.identificador">Cadastrar novo PET nesta medal</a>
      <br><br>
      <a class="btn btn-info" href="#" @click="copiarCodigoMedal">Copiar código da medal</a>
    </div>

    <div v-if="dadosAnimal" style="position: relative;">

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <img v-if="dadosAnimal.fotoPerfilId" :src="apiHost+'api/fotoPublicaAnimal/'+dadosAnimal.fotoPerfilId+'?nomeAnimal='+dadosAnimal.nome" style="width: 100%;max-width: 300px;">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <img v-show="dadosAnimal.situacao=='REGULAR'" src="@/assets/SELO-REGULAR.png" style="width: 100%;max-width: 300px;">
          <img v-show="dadosAnimal.situacao=='FURTADO'" src="@/assets/SELO-FURTADO.png" style="width: 100%;max-width: 300px;">
          <img v-show="dadosAnimal.situacao=='PERDIDO'" src="@/assets/SELO-PERDIDO.png" style="width: 100%;max-width: 300px;">
          <img v-show="dadosAnimal.situacao=='ROUBADO'" src="@/assets/SELO-ROUBADO.png" style="width: 100%;max-width: 300px;">
          <img v-show="dadosAnimal.situacao=='OBITO'" src="@/assets/SELO-OBITO.png" style="width: 100%;max-width: 300px;">
        </div>
      </div>
   
      <div style="text-align: left;margin-top: 30px;">
         <b>Nome do pet: </b><span>{{dadosAnimal.nome}}</span>
          <br>
          <b>Data de nascimento: </b><span>{{dadosAnimal.nascimento}}</span>
          <br>
          <b>Espécie: </b><span>{{dadosAnimal.especie}}</span>
          <br>
          <b>Raça: </b><span>{{dadosAnimal.raca}}</span>
          <br>
          <b>Sexo: </b><span>{{dadosAnimal.sexo}}</span>
          <br>
          <b>Cor: </b><span>{{dadosAnimal.cor}}</span>
          <br>
          <b>Código do microchip: </b><span>{{dadosAnimal.microchip}}</span>
          <br>
          <b>Sequência numérica QR Code: </b><span>{{dadosAnimal.codigoQrCode}}</span>
          <br>
          <b>Observações: </b><p>{{dadosAnimal.observacoes}}</p>

      </div>
   
      <div class="row" style="margin-top: 30px;">

          <div class="col-xs-12 col-sm-5 col-md-5" style="text-align: left;">
            <img src="@/assets/logo-bychos-simples.png" style="width: 100%;max-width: 300px;">
            <br>
            <b><small>*informações cadastradas pelo proprietário sob sua inteira responsabilidade</small></b>
          </div>

        <div class="col-xs-12 col-sm-7 col-md-7" style="text-align: left;padding-left:30px;padding-top: 30px;font-size: 18px;">
            <b>Proprietário: </b><span>{{dadosAnimal.proprietarioNome}}</span>
            <br>
            <b>Email: </b><span>{{dadosAnimal.proprietarioEmail}}</span>
            <br>

            <button type="button" class="btn btn-success" @click="chamarProprietario(dadosAnimal.proprietarioCelular)"
                  style="background-image: linear-gradient(to right, white 0%, white 20%, #5cb85c 100%)">

              <img src="@/assets/whatsapp.png" width="30">
              <span style="color: black;margin:10px;">{{dadosAnimal.proprietarioCelular}}</span>
              <span class="label label-success">chame agora</span>
            </button>

        </div>

    </div>

  </div>
  <div class="col-xs-12 col-sm-12 col-md-3"></div>
</div>

</div>
</div>
</template>