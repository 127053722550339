<template>
  <div class="hello">
    <Menu/>
    <div class="alert alert-warning" v-if="imgQrcodeWhatsapp">Faça a sincronização do whatsapp que será utilizado para o envio das mensagens</div>

    <div v-if="imgQrcodeWhatsapp" class="row">

      <div class="col-xs-2 col-sm-2">
      </div> 

      <div class="col-xs-4 col-sm-2">

        <ul class="list-group" style="width: 300px;">

          <li class="list-group-item">Caso já tenha conectado o whatsapp web no computador será necessário desconectar</li>
          <li class="list-group-item">No whatsapp acesse Configurações->Aparelhos conectados</li>
          <li class="list-group-item">Desative a opção "Multiplos aparelhos (Beta)", caso ela esteja ativa</li>
          <li class="list-group-item">Selecione a opção "Conectar um aparelho"</li>
          <li class="list-group-item">Faça a leitura do QR Code, pode ser necessário ler mais de uma vez.</li>
        </ul>

      </div>
      <div class="col-xs-4 col-sm-4">
        <img :src="imgQrcodeWhatsapp" height="200">
      </div> 

      <div class="col-xs-2 col-sm-2">
      </div> 

    </div>

    <div class="alert alert-info" v-if="!imgQrcodeWhatsapp">Whatsapp já sincronizado</div>

    <BotaoLoader class="btn btn-success " v-on:acao="forcarReinicioWhatsapp" :pai="this" role="button">Reconfigurar Whatsapp</BotaoLoader>

    <BotaoLoader class="btn btn-success " v-on:acao="forcarProcessamentoFila" :pai="this" role="button">Forçar processamento fila</BotaoLoader>

    <div style="margin:10px;">
  
      <TabelaAjax ref="tbDados" :root="this" :paginado="true" :colunas="configTbDados.columns" :url="configTbDados.url" :filtros="configTbDados.filtros" ></TabelaAjax>
      
    </div>

  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import TabelaAjax from '../components/TabelaAjax.vue'
import BotaoLoader from '../components/BotaoLoader.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,easyGet } from '../utils'

const toast = useToast();

export default {

  props: {
    msg: String
  },

  components: {
    Menu,
    BotaoLoader,
    TabelaAjax
  },

    computed: {

      apiHost(){
        return apiHost
      }

    },

  mounted(){

    var ref = this
    ref.atualizarDados()

  },

  data: function () {
                      return {
                              imgQrcodeWhatsapp:null,

                              configTbDados:{
                                url: apiHost+"api/ajaxListarFilaMensagens",
                                filtros:[
                                  {
                                    name:"status",
                                    title:"Status",
                                    selected:"PENDENTE",
                                    options: [
                                                {code:"PENDENTE",title:"PENDENTE"},
                                                {code:"SUCESSO",title:"SUCESSO"},
                                                {code:"ERRO",title:"ERRO"}
                                            ]
                                  }
                                ],

                                columns : [ 
                                            {name:"destino",title:"Celular"},
                                            {name:"texto",title:"Texto"},
                                            {name:"createdAt",title:"Data"},
                                            {name:"status",title:"Status"},
                                            {name:"detalhesErro",title:""},
                                            {name:"formaEnvio",title:"Forma de envio"}
                                          ]
                              },

                             }
                    },

    methods: {

      secureUpper,

      forcarReinicioWhatsapp:function(ajaxPost,cancel){

        var ref = this;
        easyGet("api/ajaxReiniciarWhatsapp","","Faça a leitura do QR code acima",
                      function(result){

                        ref.atualizarDados()

                      },function(result){

                        cancel()

                      })

      },

      forcarProcessamentoFila:function(ajaxPost,cancel){

        var ref = this;
        easyGet("api/ajaxForcarProcessamentoFilaWhatsapp","","Processado",
                      function(result){

                        ref.atualizarDados()

                      },function(result){

                        cancel()

                      })

      },

      atualizarDados:function(){

        var ref = this;
        easyGet("api/ajaxCarregarQrCodeWhatsapp","","",
                      function(result){

                        ref.imgQrcodeWhatsapp = result.imgQrcodeWhatsapp

                        setTimeout(function(){

                          ref.atualizarDados()

                        }, 2000);

                      })


      }

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
