<script>
/* eslint-disable */

import ComboboxBase from '../components/ComboboxBase'
import { apiHost } from '../config'
import ItemArvoreEditavel from '../components/ItemArvoreEditavel'
import { useToast } from "vue-toastification";
import { easyPost } from "../utils";

const toast = useToast();

export default {

  components: {
    ComboboxBase,
    ItemArvoreEditavel
  },
  name:"Arvore",
  data: function () {
            return {
                      animal:null,
                      irmaosMachos:[],
                      irmaosFemeas:[],

                      novoIrmaoNinhada:null,

                      AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",
                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"

                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }


                                },
                                AnimalMae:{
                                      nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }
                                }

                              },
                              AnimalMae:{
                                    nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                    AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }

                                    },
                                    AnimalMae:{
                                          nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                          AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
    
                                          },
                                          AnimalMae:{
                                                nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                          }

                                    }

                              }

                          },

                           AnimalMae:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                              AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"

                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }


                                },
                                AnimalMae:{
                                      nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }
                                }

                              },
                              AnimalMae:{
                                    nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                    AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }



                                    },
                                    AnimalMae:{
                                          nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                          AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
    
                                          },
                                          AnimalMae:{
                                                nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                          }

                                    }

                              }

                            },




            }
          },

  props: {
    rg: null,
    edicao:  {
      type: Boolean,
      default: true
    },
    cabecalho:  {
      type: Boolean,
      default: true
    },
    compacto:  {
      type: Boolean,
      default: false
    },

    styleItem1: null,
    styleItem2: null,
    styleItem3: null,
    styleItem4: null,

    styleMae:null,
    stylePai:null

  },

  mounted: function () {

    if(this.rg){
      this.carregarDados();
    }

  },

  watch: {
    rg: function (val) {

      if(this.rg){
        this.carregarDados()
      }

    },

  },

  computed: {

    apiHost(){
      return apiHost
    }

  },

  methods: {

        removerIrmaoNinhada:function(id){
          
          var ref = this          
            
          if(!confirm("Tem certeza que deseja remover da ninhada?")){
            return
          }

          easyPost('api/ajaxRemoverIrmaoNinhada',
                            'id='+id,
               'Irmão removido da ninhada',
               function(){

                ref.carregarDados()

           })

        },

        incluirIrmaoNinhada:function(){
          
          var ref = this          
            
          if(!ref.novoIrmaoNinhada){
            toast.error("Selecione o irmão a ser adicionado")
            return
          }

          easyPost('api/ajaxIncluirIrmao',
                            'idAnimalPrincipal='+ref.animal.id+'&idAnimalIrmao='+ref.novoIrmaoNinhada,
               'Irmão adicionado',
               function(){

                ref.novoIrmaoNinhada = null
                ref.carregarDados()

           })

        },

        limparArvore:function(){

          var ref = this;
          ref.animal=null;
          ref.novoIrmaoNinhada = null
          ref.irmaosMachos=[]
          ref.irmaosFemeas=[]

          ref.AnimalPai = {nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",
                            AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"

                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }


                                },
                                AnimalMae:{
                                      nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }
                                }

                              },
                              AnimalMae:{
                                    nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                    AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }

                                    },
                                    AnimalMae:{
                                          nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                          AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
    
                                          },
                                          AnimalMae:{
                                                nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                          }

                                    }

                              }

                          }

            ref.AnimalMae = {nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                              AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"

                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }


                                },
                                AnimalMae:{
                                      nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }
                                }

                              },
                              AnimalMae:{
                                    nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                    AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                        AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
  
                                        },
                                        AnimalMae:{
                                              nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                        }



                                    },
                                    AnimalMae:{
                                          nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY",

                                          AnimalPai:{nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
    
                                          },
                                          AnimalMae:{
                                                nome:"XXXXXXXXXXXXXXXXXXXXXX YYYYYYYYYYYYYYYYYYYYYYYYYYYYY"
                                          }

                                    }

                              }

                            }


        },

        carregarArvore:function(referencia,animalConsultado,nivel){

            var ref = this;

            if(nivel==4){
              return ;
            }

            nivel++;

            /*
            if(nivel==3){
              console.log("====carregarArvore animalConsultado:"+)
            }
            */

            if(!animalConsultado){
              toast.error("Problema no carregamento")
              return
            }

            referencia.AnimalPai.idFilho   = animalConsultado.id;
            referencia.AnimalMae.idFilho   = animalConsultado.id;

            if(animalConsultado.AnimalPai!=null){
              referencia.AnimalPai.id   = animalConsultado.AnimalPai.id;
              referencia.AnimalPai.nome = animalConsultado.AnimalPai.nome;
              referencia.AnimalPai.rg   = animalConsultado.AnimalPai.rg;
              ref.carregarAnimalArvore(referencia.AnimalPai,nivel)
            }

            if(animalConsultado.AnimalMae!=null){
              referencia.AnimalMae.id   = animalConsultado.AnimalMae.id;
              referencia.AnimalMae.nome = animalConsultado.AnimalMae.nome;
              referencia.AnimalMae.rg   = animalConsultado.AnimalMae.rg;
              ref.carregarAnimalArvore(referencia.AnimalMae,nivel)
            }

          },

          carregarAnimalArvore:function(animal,nivel){

            if(!animal.rg){
              toast.error("animal sem rg...");
              return;
            }

            var ref = this;
            var parameters = "rg="+animal.rg
            $.ajax({
                url: apiHost+"api/ajaxCarregarAnimal",
                data: parameters,
                dataType: "JSON",
                type: "POST",
                headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
               }).success(function(result){
                
                if(result.status==true){

                  ref.carregarArvore( animal ,result.animal ,nivel);

                }else{
                    toast.error(result.message);
                }

            }).complete(function(result){

            });

          },

          carregarDados: function () {

            var ref  = this;

            ref.limparArvore();

            if(!ref.rg){
              alert("rg não informado")
              return
            }

            if(!ref.rg){
              alert("rg não informado")
              return
            }

            if(!ref.rg){
              toast.error("rg não informada")
              return
            }

            if(ref.rg==-1&&ref.compacto){

              console.log("EXEMPLO...")

              ref.animal = {id:0,nome:"Exemplo"}
              ref.irmaosMachos = [{id:0,nome:"Irmão 1"} , {id:1,nome:"Irmão 2"} ]
              ref.irmaosFemeas = [{id:2,nome:"Irmã 1" } , {id:3,nome:"Irmã 2"}  ]

              return
            }

            var parameters = "rg="+ref.rg+"&carregarIrmaos=true"
            $.ajax({
                url: apiHost+"api/ajaxCarregarAnimal",
                data: parameters,
                dataType: "JSON",
                type: "POST",
                headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
               }).success(function(result){
                
                if(result.status==true){

                  console.log("===result.animal:"+JSON.stringify(result.animal) )

                  if(result.animal==null){
                    toast.error("Animal não encontrado")
                    //window.location.href = "/404";
                    return;
                  }

                  ref.animal = result.animal
                  ref.irmaosMachos = result.irmaosMachos
                  ref.irmaosFemeas = result.irmaosFemeas

                  ref.carregarArvore(ref,ref.animal,0);

                }else{
                    toast.error(result.message);
                }

            }).complete(function(result){
              

            });


          }

  },

}
</script>

<template>

  <div v-if="animal&&rg" style="margin-top: 20px;" v-bind:class="{ compacto: compacto }">

    <h1 v-if="cabecalho">{{animal.nome}}</h1>

    <div v-if="cabecalho" class="well">

      <table class="table-striped table table-condensed" style="width: 80%;">
        <tbody>
          <tr>
            <th>Irmãos</th>
          </tr>
          <tr v-if="irmaosMachos.length==0&&irmaosFemeas.length==0">
            <td >
              Nenhum irmão cadastrado 
            </td>
          </tr>
          <tr v-for="irmao in irmaosMachos">
            <td >{{irmao.nome}}
                <button @click="removerIrmaoNinhada(irmao.id)" class="btn btn-danger" role="button"><i class="glyphicon glyphicon-remove" ></i> </button>
            </td>
          </tr>
          <tr v-for="irma in irmaosFemeas">
            <td >{{irma.nome}}
                <button @click="removerIrmaoNinhada(irma.id)" class="btn btn-danger" role="button"><i class="glyphicon glyphicon-remove" ></i> </button>
            </td>

          </tr>            
          <tr>
            <td>
              <br><br>
              <p>Adicionar irmão de ninhada</p>
              <ComboboxBase 
                  :url="apiHost+'api/ajaxComboboxAnimais'"
                  :filtros="{racaId:animal.Raca?animal.Raca.id:null}"
                  v-model="novoIrmaoNinhada"/>
              <button @click="incluirIrmaoNinhada" class="btn btn-info" role="button"><i class="glyphicon glyphicon-plus" ></i> </button>
            </td>
          </tr>
        </tbody>
     </table>

    </div>

    <table v-bind:class="{ 'table-striped': !compacto, 'table':!compacto, 'table-condensed':!compacto }" style="width: 80%;">
      <tbody>

      <tr>
        <th :style="stylePai">Pai</th>
        <th :style="stylePai">Avós paternos</th>
        <th :style="stylePai">Bisavós paternos</th>
        <th :style="stylePai">Trisavós paternos</th>
      </tr>

      <tr>
        <td> 
            <div :style="styleItem1" class="ancestral-item ancestral">
              <span v-if="compacto==false">🐕</span>
              <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>
              
            </div>
        </td>
        <td>  
          <div :style="styleItem2" class="ancestral-item ancestral-2">

              <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalPai"
                          coluna="pai"
                          v-on:modificado="carregarDados"
                          :texto="AnimalPai.AnimalPai.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

             
            </div>

        </td>
        <td>

            <div :style="styleItem3" class="ancestral-item ancestral-2-1">

              <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalPai.AnimalPai.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalPai.AnimalPai.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

             
            </div>

        </td>
        
        <td>
          <div :style="styleItem4" class="ancestral-item ancestral-2-2">

            <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalPai.AnimalPai.AnimalPai.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalPai.AnimalPai.AnimalPai.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

             
          </div>

        </td>
      </tr>
      <tr>
        <td></td>
        <td>
            <div :style="styleItem2" class="ancestral-item ancestral-3">
  
               <span v-if="compacto==false">🐩</span>
                <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                              :edicao="edicao"
                              :compacto="compacto"
                              :animal="AnimalPai.AnimalMae"
                              v-on:modificado="carregarDados"
                              coluna="mae"
                              :texto="AnimalPai.AnimalMae.nome"
                              :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

             
            </div>

        </td>
        <td>

          <div :style="styleItem3" class="ancestral-item ancestral-2-1-b">

             <span v-if="compacto==false">🐩</span>
              <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalPai.AnimalPai.AnimalMae"
                            v-on:modificado="carregarDados"
                            coluna="mae"
                            :texto="AnimalPai.AnimalPai.AnimalMae.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

             
          </div>

        </td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-2-2">

           <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalPai.AnimalPai.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalPai.AnimalPai.AnimalPai.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
 
          </div>

        </td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem3" class="ancestral-item ancestral-3-1">

            <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalPai.AnimalMae.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalPai.AnimalMae.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
          </div>

      </td>
      <td>

          <div :style="styleItem4" class="ancestral-item ancestral-2-1-c">

            <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"

                            :animal="AnimalPai.AnimalPai.AnimalMae.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalPai.AnimalPai.AnimalMae.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
          </div>

      </td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem3" class="ancestral-item ancestral-3-2">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalMae.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalPai.AnimalMae.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
          </div>
        
        </td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-2-1-c">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalPai.AnimalMae.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalPai.AnimalPai.AnimalMae.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
          </div>

        </td>
      </tr>

      <tr >
        <td></td>
        <td></td>
        <td></td>
        <td>
  
          <div :style="styleItem4" class="ancestral-item ancestral-3-1-b">

            <span v-if="compacto==false">🐕</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalMae.AnimalPai.AnimalPai"
                          coluna="pai"
                          v-on:modificado="carregarDados"
                          :texto="AnimalPai.AnimalMae.AnimalPai.AnimalPai.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
          </div>

        </td>
      </tr>

      <tr >
        <td></td>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-3-1-b">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalMae.AnimalPai.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalPai.AnimalMae.AnimalPai.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            
          </div>

        </td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-3-2-b">

            <span v-if="compacto==false">🐕</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalMae.AnimalMae.AnimalPai"
                          coluna="pai"
                          v-on:modificado="carregarDados"
                          :texto="AnimalPai.AnimalMae.AnimalMae.AnimalPai.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            

            </div>

        </td>
      </tr>

      <tr >
        <td></td>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-3-2-b">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalPai.AnimalMae.AnimalMae.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalPai.AnimalMae.AnimalMae.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

            

          </div>

        </td>
      </tr>

      <tr>
        <th :style="styleMae"> 
          Mãe
        </th>
        <th :style="styleMae"> 
          Avós maternos        
        </th>
        <th :style="styleMae"> 
          Bisavós maternos
        </th>
        <th :style="styleMae"> 
          Trisavós maternos
        </th>
      </tr>

      <tr>
        <td> 
            <div :style="styleItem1" class="ancestral-item ancestral">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>


            

            </div>
        </td>
        <td>  
          <div :style="styleItem2" class="ancestral-item ancestral-2">

              <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalPai"
                          coluna="pai"
                          v-on:modificado="carregarDados"
                          :texto="AnimalMae.AnimalPai.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              


            </div>

        </td>
        <td>

            <div :style="styleItem3" class="ancestral-item ancestral-2-1">

              <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalMae.AnimalPai.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalMae.AnimalPai.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

            </div>

        </td>
        
        <td>
          <div :style="styleItem4" class="ancestral-item ancestral-2-2">

            <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalMae.AnimalPai.AnimalPai.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalMae.AnimalPai.AnimalPai.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>


              

          </div>

        </td>
      </tr>
      <tr>
        <td></td>
        <td>
            <div :style="styleItem2" class="ancestral-item ancestral-3">
  
               <span v-if="compacto==false">🐩</span>
                <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                              :edicao="edicao"
                              :compacto="compacto"
                              :animal="AnimalMae.AnimalMae"
                              v-on:modificado="carregarDados"
                              coluna="mae"
                              :texto="AnimalMae.AnimalMae.nome"
                              :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

            </div>

        </td>
        <td>

          <div :style="styleItem3" class="ancestral-item ancestral-2-1-b">

             <span v-if="compacto==false">🐩</span>
              <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalMae.AnimalPai.AnimalMae"
                            v-on:modificado="carregarDados"
                            coluna="mae"
                            :texto="AnimalMae.AnimalPai.AnimalMae.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

          </div>

        </td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-2-2">

           <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalPai.AnimalPai.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalMae.AnimalPai.AnimalPai.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>


              

          </div>

        </td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem3" class="ancestral-item ancestral-3-1">

            <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalMae.AnimalMae.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalMae.AnimalMae.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

          </div>

      </td>
      <td>

          <div :style="styleItem4" class="ancestral-item ancestral-2-1-c">

            <span v-if="compacto==false">🐕</span><ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                            :edicao="edicao"
                            :compacto="compacto"
                            :animal="AnimalMae.AnimalPai.AnimalMae.AnimalPai"
                            coluna="pai"
                            v-on:modificado="carregarDados"
                            :texto="AnimalMae.AnimalPai.AnimalMae.AnimalPai.nome"
                            :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

          </div>

      </td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem3" class="ancestral-item ancestral-3-2">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalMae.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalMae.AnimalMae.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              


          </div>
        
        </td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-2-1-c">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalPai.AnimalMae.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalMae.AnimalPai.AnimalMae.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

          </div>

        </td>
      </tr>

      <tr >
        <td></td>
        <td></td>
        <td></td>
        <td>
  
          <div :style="styleItem4" class="ancestral-item ancestral-3-1-b">

            <span v-if="compacto==false">🐕</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalMae.AnimalPai.AnimalPai"
                          coluna="pai"
                          v-on:modificado="carregarDados"
                          :texto="AnimalMae.AnimalMae.AnimalPai.AnimalPai.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

          </div>

        </td>
      </tr>

      <tr >
        <td></td>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-3-1-b">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalMae.AnimalPai.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalMae.AnimalMae.AnimalPai.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              


          </div>

        </td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-3-2-b">

            <span v-if="compacto==false">🐕</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalMae.AnimalMae.AnimalPai"
                          coluna="pai"
                          v-on:modificado="carregarDados"
                          :texto="AnimalMae.AnimalMae.AnimalMae.AnimalPai.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              


            </div>

        </td>
      </tr>

      <tr >
        <td></td>
        <td></td>
        <td></td>
        <td>

          <div :style="styleItem4" class="ancestral-item ancestral-3-2-b">

            <span v-if="compacto==false">🐩</span>
            <ItemArvoreEditavel type="EDITAVEL_TEXTO" 
                          :edicao="edicao"
                          :compacto="compacto"
                          :animal="AnimalMae.AnimalMae.AnimalMae.AnimalMae"
                          v-on:modificado="carregarDados"
                          coluna="mae"
                          :texto="AnimalMae.AnimalMae.AnimalMae.AnimalMae.nome"
                          :urlEdicao='apiHost+"api/ajaxEditarInformacao"'/>

              

          </div>

        </td>
      </tr>

      </tbody>
    </table>

  </div>

</template>

<style scoped>
.btn-primary{
  background-color: black;
}

.compacto{
    font-size: 11px;
    margin-top: 5px!important;
}

.compacto table-condensed>tbody>tr>td, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>td, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>thead>tr>th {
    padding: 0px;
}

.ancestral-item{
    padding:5px;border-radius:5px;
}

.compacto .ancestral-item{
    padding:4px;border-radius:0px;
    width: 233px;
}

.ancestral{
    background-color: yellowgreen;
}

.ancestral-2{
    background: linear-gradient(90deg, rgba(154,205,50,1) 0%, rgba(8,0,255,1) 100%);
}

.ancestral-3{
    background: linear-gradient(90deg, rgba(154,205,50,1) 0%, rgba(255,0,215,1) 100%);;
}

.ancestral-3-1{
    background: linear-gradient(90deg, rgba(255,0,215,1) 0%,#94effb  100%);;
}

.ancestral-3-2{
    background: linear-gradient(90deg, rgba(255,0,215,1) 0%,green  100%);;
}

.ancestral-3-2-b{
    background: linear-gradient(90deg, green 0%,black  100%);;
}

.ancestral-3-1-b{
    background: linear-gradient(90deg, #94effb 0%,black  100%);;
}


.ancestral-2-1{
    background: linear-gradient(90deg, rgba(8,0,255,1) 0%, #ffe200 100%);;
}

.ancestral-2-1-b{
    background: linear-gradient(90deg, rgba(8,0,255,1) 0%, red 100%);;
}

.ancestral-2-1-c{
    background: linear-gradient(90deg, red 0%, black 100%);;
}

.ancestral-2-2{
    background: linear-gradient(90deg, #ffe200 0%, black 100%);;
}

</style>



 