<script>
  /* eslint-disable */

  window.$ = window.jQuery = require('jquery');

  require("../vendor/select2/select2.min.js");
  require("../vendor/select2/select2_locale_pt-BR.js");
  require("../vendor/select2/select2.css");

  import { useToast } from "vue-toastification";
  const toast = useToast();

  import FormExpansivel from '../components/FormExpansivel.vue'
  import { secureUpper } from '../utils'

  export default {

    name:"ComboboxBase",
    emits:["update:modelValue"],

    data: function () {
                      return {
                              novoItemNome:"",
                              optionsFormNovoItem:{
                                confirm:{
                                  show:true,
                                  action:function(ajaxPost,cancel,item,refPai){

                                      var parameters = "nome="+encodeURIComponent(refPai.novoItemNome);

                                      if(refPai.parametrosCadastro){

                                        $.each(refPai.parametrosCadastro, function(key, value) {
                                            parameters+="&"+key+"="+encodeURIComponent(value)
                                        })

                                      }

                                      ajaxPost(refPai.urlCadastro,parameters,
                                          function (result) {

                                              if(!result.status){
                                                toast.error(result.message)
                                                return
                                              }

                                              if(!result.id){
                                                toast.error("ID incluido não foi retornado")
                                                return
                                              }

                                              //refPai.$refs.tbDados.forceRefreshCurrentData();
                                              refPai.$refs.mdPopup.limparRecolherDados();

                                              setTimeout(function() {

                                                var refCombobox = refPai.$refs.cbComboboxBase;

                                                var valorNovo = ""+result.id+"|"+refPai.novoItemNome;

                                                console.log("====combobox.valorNovo:"+valorNovo)

                                                $(refCombobox).val(valorNovo).trigger("change");

                                              }, 1000);

                                          }
 
                                      );

                                  }
                                }

                              }

                            }
                    },

    props: {
      'cmb-name':null,
      url:null,
      modelValue:null,
      filtros:null,
      urlCadastro:null,
      parametrosCadastro:null,
      placeholder: {
        type: String,
        default: function () {
          return "..."
        }
      }
    },

    components: {
      FormExpansivel
    },

    mounted: function () {
      var ref = this;
      ref.configurarCombobox();
    },

    watch: {
      modelValue :{
      
        handler: function (val, oldVal) {

          var ref         = this;
          var refCombobox = ref.$refs.cbComboboxBase;

          if($(refCombobox).val()==val){
            return
          }

          $(refCombobox).val(val).trigger("change", { ignore: true });
        }
        //,deep: true
      }
    
    },

    methods: {
      secureUpper,
      configurarCombobox:function(){

          var ref = this;
          var refCombobox = ref.$refs.cbComboboxBase;


          $(refCombobox).select2({
            language: 'pt-BR',

            ajax: {
              url: ref.url,
              dataType: 'json',
              placeholder: "",
              quietMillis: 250,
              transport: function(params){
                  params.beforeSend = function(request){
                      request.setRequestHeader("Authorization", 'Bearer '+localStorage.getItem('token'));
                  };
                  return $.ajax(params);
              },

              data: function (term,page){

                var filtros;
                if(!ref.filtros){
                  filtros={q:term};
                }else{
                  filtros = ref.filtros;
                  filtros.q=term;
                }

                return filtros;
              },

              results: function (data, page) { // parse the results into the format expected by Select2.
                  return { results: data.items };
              },

              cache: true
            },

            initSelection: function (element, callback) {

              var ref = this;

              var item = $(element).val();

              var id = "";
              var descricao = "";

              if(item&&item.includes("|")){

                var itemSplit = item.split("|")

                id        = itemSplit[0]
                descricao = itemSplit[1]

              }else{
                id        = item
                descricao = item
              }

              var data = { 'id': id, 'nome': descricao };
              callback(data);

            },

            escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
              minimumInputLength: 0,
              formatResult: ref.formatItem, // omitted for brevity, see the source of this page
              formatSelection: ref.formatItemSelection,  // omitted for brevity, see the source of this page
              allowClear:true
          });

          $(refCombobox).on('change', function(ev, args){

            if (!(args && "ignore" in args)){

              ref.$emit('update:modelValue', $(refCombobox).val() );

            }

          });

          if(ref.modelValue){
            $(refCombobox).val(ref.modelValue).trigger("change", { ignore: true });
          }

        },

        formatItem: function(cidade){

          if(!cidade){
            return null;
          }

          var markup = cidade.nome;
          return markup;
        },

        formatItemSelection: function(cidade){

          if(!cidade){
            return null;
          }
          var markup = cidade.nome;

          return markup;
        }

      }

  }
</script>

<template>
    <div>
        <input type="text" :data-placeholder="placeholder" class="select2" ref="cbComboboxBase" :name="cmbName" style="width: 80%;"/>

        <div class="combobox-cadastro" v-if="urlCadastro" v-show="!modelValue" style="width:80%;background-color:#9e9e9e;">

          <FormExpansivel :pai="this" ref="mdPopup" :large="false" id="modalPopup" alinhamento="left" title="Cadastro" v-bind:options="optionsFormNovoItem">

            <form class="form-horizontal" role="form" style="padding: 10px;">
                <fieldset>

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Nome</label>
                      <div class="col-sm-7">
                          <input name="nome" type="text" id="nome" class="form-control" placeholder="" v-model="novoItemNome" @keyup="novoItemNome=secureUpper(novoItemNome)">
                      </div>
                  </div>

                </fieldset>
              </form>

          </FormExpansivel>

        </div>

    </div>
</template>

<style scoped>

.combobox-cadastro .container {
    background-image: linear-gradient(180deg, rgb(233 233 233) 3%, #ffffff 88%, rgb(0 0 0 / 93%) 100%);
}

</style>