<script>
/* eslint-disable */


import PopupSimples from './PopupSimples.vue'
import { apiHost } from '../config'

export default {
  name:"PopupConfirmacao",
  components: {
    PopupSimples
  },

  props: {
      pai:null,
      valoresAdicionais:null,
      
      habilitarOutros: {
        type: Boolean,
        default: false
      },

      alinhamento: {
        type: String,
        default: function () {
          return 'center'
        }
      }
  },

  data: function () {
            return {
              aguarde: false,
              solicitarDetalhesAdicionais:false,
              textoMotivoOutros:"",
              motivos: null,
              identificador:null,
              urlAjax:null,
              parametrosAdicionais:null,
              tituloPopup:null,
              explicacaoPopup:null,
              textoConfirmacao:null,
              optionsPopup:{
                    showCloseButton:false,
                    canClose:true,
                    canCloseOnEsc:false,
                    confirm:{
                      show:false
                    }
                }
            }
          },

    watch: {
        aguarde: {
            handler: function (val, oldVal) {

                var ref = this
                ref.optionsPopup.canClose = !ref.aguarde
                
            }
        }
    },

  computed: {

    identificadorPopup: function () {
  
      var ref = this;      
      var retorno = ref.urlAjax;
      if(ref.identificador){
        retorno+="||"+ref.identificador;
      }
      return retorno;

    }

  },

  methods: {

      teste:function(){
        alert("teste")
      },

      abrirPopupConfirmacaoAjax: function(tituloPopup,explicacaoPopup,textoConfirmacao,motivos,urlAjax,parametrosAdicionais){
 
        var ref = this;

        ref.tituloPopup          = tituloPopup;
        ref.explicacaoPopup      = explicacaoPopup;
        ref.textoConfirmacao     = textoConfirmacao;
        ref.urlAjax              = urlAjax;
        ref.parametrosAdicionais = parametrosAdicionais;
        ref.textoMotivoOutros = "";
        ref.aguarde           = false;

        if(urlAjax.indexOf(apiHost)>-1){
          alert("(BUG) Parametro urlAjax não deve conter apiHost");
          return;
        }

        ref.motivos = motivos;
        ref.aguarde = false;

        ref.$refs.mdPopupMotivo.abrirPopup();
        
      },

      confirmarAcao: function (codigoMotivo,descMotivo) {

          var ref         = this;
          var textoMotivo = "";

          if(codigoMotivo=="OUTROS"){

              textoMotivo = ref.textoMotivoOutros;
              if(textoMotivo.length<=5){
                  ref.$refs.mdPopupMotivo.mostrarAlerta("Para confirmar a ação você precisa detalhar o ocorrido. (Minímo de 5 caracteres)");
                  return;
              }

              if((textoMotivo[0]==textoMotivo[1])&&(textoMotivo[1]==textoMotivo[2]) && textoMotivo.length<20){
                ref.$refs.mdPopupMotivo.mostrarAlerta("Atenção, utilize informações relevantes para o motivo da ocorrência. (Minímo de 5 caracteres)");
                return;
              }

          }else{
              textoMotivo = descMotivo;
          }

          var parameters = "textoMotivo="+textoMotivo+"&codigoMotivo="+codigoMotivo;
          if(ref.parametrosAdicionais){
            parameters+= "&"+ref.parametrosAdicionais;
          }

          if(ref.valoresAdicionais){

            var valoresAdicionais = ref.valoresAdicionais;

            for (var prop in valoresAdicionais) {

              if (valoresAdicionais.hasOwnProperty(prop)){
                parameters += "&"+prop+"="+valoresAdicionais[prop];
              }

            }

          }

          var urlServico = apiHost+ref.urlAjax;

          ref.aguarde = true;

          $.ajax({
              url: urlServico , 
              data: parameters,
              type: "POST",
              dataType: "JSON",
              headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
             }).success(function(result){

              if(result.status==true){

                 ref.$refs.mdPopupMotivo.ocultarPopup();

                  ref.$emit('sucesso',result);

              }else{
                alert(result.message);
              }

            }).complete(function(result){

              ref.aguarde = false;

            }).error(function(result){

              if(result.message){
                alert(result.message);
              }else{
                alert("Houve um problema, tente novamente mais tarde");
              }


            });

      }


  }

}
</script>


<template>

  <popup-simples :pai="pai" :alinhamento="alinhamento" ref="mdPopupMotivo" :title="tituloPopup" :description="explicacaoPopup" v-bind:options="optionsPopup">

      <div style="display:inline-block;">
        <p>{{textoConfirmacao}}</p>
        <div style="margin-bottom:15px;"><slot></slot></div>
        <div v-show="solicitarDetalhesAdicionais==false">
              <div class="btn-group-vertical" role="group" aria-label="...">
                  <button :disabled="aguarde" style="word-wrap: break-word;white-space: normal;align-items:center;" :style="[motivo.descricao.length>60 ? {'display': 'flex'} : '']" 
                    v-for="motivo in motivos" v-bind:key="motivo.codigo" v-on:click="confirmarAcao(motivo.codigo,motivo.descricao)" type="button" class="btn btn-default ">{{motivo.descricao}}<span class="label label-info" v-if="motivo.acaoPadrao">{{ motivo.acaoPadrao }}</span></button>
                  <button v-show="habilitarOutros" :disabled="aguarde" v-on:click="solicitarDetalhesAdicionais=true" type="button" class="btn btn-default">Outros</button>
             </div>
             <div v-show="aguarde">
                 <img src="../assets/loading_rapido.gif" border="0">
             </div>
        </div>
        <div v-show="solicitarDetalhesAdicionais" style="width:400px;">
            <div class="comment-body">
                  <textarea v-model="textoMotivoOutros" class="form-control input-sm" placeholder="Para confirmar a ação você precisa detalhar o ocorrido. (Minímo de 5 caracteres)" rows="4" cols="50" style="width:90%;height:50px;"></textarea>
                  <button :disabled="aguarde" v-on:click="confirmarAcao('OUTROS','Outros')" type="button" class="btn btn-success btn-block" style="margin-top:10px;">Confirmar</button>
            </div>
        </div>
      </div>
  </popup-simples>

</template>



<style scoped>
</style>