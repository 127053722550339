
<script>
/* eslint-disable */
	
import filtro from './Filtro.vue'
import botaoLoader from './BotaoLoader.vue'
import CampoEditavel from './CampoEditavel.vue'

var moment = require('moment');

import { useToast } from "vue-toastification";
const toast = useToast();
import { apiHost } from '../config'

export default {
	name:"TabelaAjax",

    components: {
  	  filtro,
  	  botaoLoader,
  	  CampoEditavel
  	},

	data: function () {
	        return {
	          textoCabecalho:null,
	          contadorAtualizacao:0,
	          dados:null,
	          total:0,
	          offset:0,
	          qtdItensPagina:10,
	          nroPaginas:0,
	          carregando:false,
	          paginaAtual:1, 
	          loadedHash:"",
	          loadedHashDate:null,
	          filtrosData:null,
	          pai:null,
	          pxStr:"px",
	          primeiroCarregamento:true,
	          descricaoFiltros:"ajustar filtros",
	          exibirFiltros:true


	        }
	      },
	props: {

	  filtros:Array,
	  colunas:Array,
	  
	  dominio:null,

	  url:String,

	  paginado: {
	    type: Boolean,
	    required: false,
	    default:true
	  },

	  root:null,

	  abrirNaUltimaPagina: {
	    type: Boolean,
	    required: false,
	    default:false
	  },

	  exibirBorda: {
	    type: Boolean,
	    required: false,
	    default:true
	  },

	  //só efetua requisição ajax se pelo menos 1 filtro
	  //for informado, não permite carregar dados sem filtro...
	  filtrosObrigatorios: {
	    type: Boolean,
	    required: false,
	    default:false
	  }

	},

	created: function () {

	  if(this.filtros){
	    this.filtrosData = JSON.parse(JSON.stringify(this.filtros));
	  }

	  this.pai = this;

		$.ajaxSetup({
		      crossDomain: true

		      /*,
		      xhrFields: {
		          withCredentials: true
		      }
		      */
		  });
		  //this.http.options.credentials = true;

	},

	mounted: function () {

	  this.ajaxLoadFilters();
	  this.ajaxLoadData();

	},

	computed: {

      token() {
        return localStorage.getItem('token')
      },

	  apiHost(){
	      return apiHost
	  },

      filtrosVisiveis: function (){

		if(!this.filtrosData){
			return this.filtrosData;
		}

		return this.filtrosData.filter(item => {
	        return item.type!="HIDDEN"
	     })

      },

	  paginasExibidas: function () {

	    var exibidas = [];

	    var contadorInicio = this.paginaAtual - 3;
	    if(contadorInicio<1){
	      contadorInicio = 1;
	    }

	    var contadorFim    = contadorInicio + 5;

	    if(contadorFim>this.nroPaginas){
	      contadorFim = this.nroPaginas;
	    }

	    for (var i = contadorInicio; i <= contadorFim; i++) {
	      exibidas.push(i);
	    }

	    return exibidas;
	  }
	},

	methods: {

	  formatarItem:function(item,coluna){

	  	if(coluna.name.includes(".")){

	  		var col = coluna.name.split(".");


	  		if(item[col[0]]==null){
	  			return "";
	  		}

	  		return item[col[0]][col[1]];
	  		
	  	}else{

	  		if(item[coluna.name]&&item[coluna.name][item[coluna.name].length-1]=="Z"){
	  			
	  			var dataFormatada = moment(item[coluna.name])
	  			if(dataFormatada.isValid()){

	  				if(dataFormatada.format('HH:mm')=="00:00"){
	  					return dataFormatada.format('DD/MM/YYYY')
	  				}
		  			return dataFormatada.format('DD/MM/YYYY HH:mm')
		  			
	  			}

	  		}

	  	}

		return item[coluna.name];
	  },

	  mostrarFiltros: function (){
	    this.exibirFiltros = true;
	  },
	  ocultarFiltros: function (){
	    this.exibirFiltros = false;
	  },

	  visualizar: function (coluna,itemDaColuna,dadosColuna) {

	    //TODO: faz sentido migrar isso pra evento, utilizar .emit e ouvir evento nos componentes...
	    coluna.action(itemDaColuna,this.root,this.pai,dadosColuna);

	  },

	  setFilterValue: function (par,val) {

	    for (var i = 0; i < this.filtrosData.length; i++) {

	      if(this.filtrosData[i].name==par){
	        this.filtrosData[i].selected = val;
	      }

	    }
	    this.filtroModificado();
	  },

	  filtroModificado: function () {

	    var ref = this;
	    ref.refreshData();
	    ref.ajustarDescricaoFiltros();
	  },

	  ajustarDescricaoFiltros: function () {

	    var ref = this;

	    var descricao = "";
	    var qtdFiltrosAtivos = 0;
	    for (var i = 0; i < ref.filtrosData.length; i++) {

	      var filtro = ref.filtrosData[i];

	      if(filtro.type=='SEARCH'){

	        if(filtro.selected){
	          qtdFiltrosAtivos++;
	        }

	      }else if(filtro.type==null){
	        //select

	        if(filtro.selected){

	          //conta selecionado apenas se não for o padrão?
	          if(filtro.options[0].code!=filtro.selected){
	            qtdFiltrosAtivos++;
	          }

	        }

	      }

	    }
	      
	    if(qtdFiltrosAtivos==0){
	      descricao = "ajustar filtros";
	    }else if(qtdFiltrosAtivos==1){
	      descricao = "1 filtro informado";
	    }else{
	      descricao = ""+qtdFiltrosAtivos+" filtros informados";
	    }

	    ref.descricaoFiltros = descricao;

	  },

	  clearData: function () {

	    this.dados      = null;
	    this.total      = 0;
	    this.offset     = 0;
	    this.nroPaginas = 0;

	  },

	  refreshData: function () {

	    //filtros modificados, muda pra primeira pagina e faz a requisição dos
	    //dados novamente...
	    this.paginaAtual = 1;
	    this.offset      = 0;
	    this.ajaxLoadData();

	  },

	  onCampoEditavelAtualizado: function(){

	  	this.forceRefreshCurrentData();

	  },

	  forceRefreshCurrentData: function () {

		var ref = this;
		ref.loadedHash 	   = "";
	    ref.loadedHashDate = null;
	    ref.ajaxLoadData();
	    
	  },

	  irParaUltimaPagina: function () {

	    if(this.paginaAtual!=this.nroPaginas){
	      this.paginar(this.nroPaginas);
	    }

	  },

	  paginar: function (paginaAtual) {

	    if(this.paginaAtual == paginaAtual){
	      return;
	    }

	    if(this.carregando){
	      return;
	    }

	    this.paginaAtual = paginaAtual;

	    this.offset = (paginaAtual-1)*this.qtdItensPagina;

	    this.ajaxLoadData();

	  },




	  ajaxLoadFilters: function () {

	    var referencia = this;

	    if(referencia.filtrosData){

	      for (var i = 0; i < referencia.filtrosData.length; i++) {

	        if(referencia.filtrosData[i].url){

	          var filtro = referencia.filtrosData[i];

	            $.ajax({
	                url: filtro.url,
	                data: "",
	                dataType: "JSON",
	                    type: "GET",
				    async: false,
					headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
	               }).success(function(result){

	                if(result.status==true){

	                  if(result.options){
	                     filtro.options  = result.options;
	                  }else if(result.items){

	                  	var opcoes = [];

	                  	for(var i = 0; i < result.items.length; i++){
	                  		opcoes.push({code:result.items[i].id,title:result.items[i].nome});

	                  	}

	                  	filtro.options = opcoes;

	                  }

	                  if(result.options&&result.options.length>0){
		                  filtro.selected = result.options[0].code;
	                  }

	                  referencia.filtroModificado();

	                }else{

		                  toast.error(result.message, {
		                      timeout: 2000
		                  });

	                }

	            }).complete(function(result){
	              

	            });
	        }

	      }

	    }

	  },

	  ajaxLoadData: function () {

	    var referencia = this;

	    referencia.carregando = true;

	    var parametros = "offset="+referencia.offset;

	    var filtrosInformados = false;

	    if(referencia.filtrosData){

	      for (var i = 0; i < referencia.filtrosData.length; i++) {

	        var valor = "";
	        if(referencia.filtrosData[i].selected){
	            valor = referencia.filtrosData[i].selected;
	            filtrosInformados = true;
	        }

	        parametros += "&"+referencia.filtrosData[i].name+"="+valor;

	      }

	    }

	    if(referencia.filtrosObrigatorios==true&&filtrosInformados==false){
	      referencia.clearData();
	      referencia.carregando = false;
	      return;
	    }

	    var currentHash = referencia.url+"?"+parametros;
	    if(currentHash==referencia.loadedHash){

	      var dataAtual = new Date();

	      var timeDiffSeconds = dataAtual - referencia.loadedHashDate;
	      timeDiffSeconds = Math.round(timeDiffSeconds/1000);

	      //TODO: tem situações onde seria necessário atualizar mesmo possuindo o mesmo hash, 
	      //      como em atualizações recorrentes...

	      if(timeDiffSeconds<=5){
	        referencia.carregando = false;
	        return;
	      }

	    }

	    referencia.loadedHash = currentHash;
	    referencia.loadedHashDate = new Date();

	    referencia.contadorAtualizacao++;

	    var atualizacaoAtual = referencia.contadorAtualizacao;

	    $.ajax({
	            url: referencia.url , 
	            data: parametros,
	            dataType: "JSON",
                type: "POST",
				headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
	           }).success(function(result){
	            
	            if(result.status==true){

	              if(atualizacaoAtual!=referencia.contadorAtualizacao){
		           	
	                  toast.info("Atualização anterior descartada pois houve mudança nos filtros", {
	                      timeout: 2000
	                  });

	              	return;
	              }

	              referencia.textoCabecalho = result.textoCabecalho;

	              referencia.total = result.total;
                  referencia.$emit('total-itens-atualizado',referencia.total);

	              var maxItens = 10;
	              if(result.max){
	              	maxItens = result.max;
	              }

	              referencia.qtdItensPagina = maxItens;

	              referencia.nroPaginas = Math.ceil(result.total/maxItens);

	              if(referencia.primeiroCarregamento){

	                referencia.primeiroCarregamento = false;

	                if(referencia.abrirNaUltimaPagina&&(referencia.paginaAtual!=referencia.nroPaginas)){
	                  referencia.carregando = false;
	                  referencia.irParaUltimaPagina();
	                  return;
	                }

	              }

	              referencia.dados = result.dados;

	            }else{

                  toast.error(result.message, {
                      timeout: 2000
                  });

	            }

	        }).complete(function(result){
	          
	          referencia.carregando = false;
	      
            }).error(function(respErro){

                  if(respErro.status=="403"){ 
                    
                    toast.error("Acesso não permitido, entre em contato com o administrador para verificar se você possuí as permissões de acesso adequadas.");
                  }else{

                    try{

	                    var result;
	                    if(respErro.responseText){
	                      result = JSON.parse(respErro.responseText);
	                    }

	                    if(result&&result.message){

	                    	toast.error(result.message);

	                    }else{
	                    	toast.error("Houve um problema, tente novamente mais tarde.");

	                    }

                    }catch(e){

                    	toast.error("Houve um problema, tente novamente mais tarde.");

                    }


                  }

	        });

	  }

	}

}

</script>

<template>

	<div class="mainDiv">

		<h3 v-if="textoCabecalho">{{textoCabecalho}}</h3>

        <div v-if="filtrosVisiveis&&filtrosVisiveis.length>0"><form class="form-horizontal" v-on:submit.prevent>

            <div v-if="exibirFiltros==true" style="background-color:#eeeeee;padding-left:10px;padding-top:10px;padding-right:10px;border:1px solid #ddd;border-bottom-width: 0px;height:70px;">
              <filtro style="margin-bottom:10px;" v-for="filtro in filtrosData" v-on:modificado="filtroModificado" :filtro="filtro" ></filtro>
              <img v-show="carregando" src="../assets/loader-wheel.svg" style="-webkit-transform:scale(0.5);">
            </div>

        </form></div>
        <div style="min-height: 450px;">
        	
        <table v-bind:class="{ 'table-bordered': exibirBorda }" class="table mb-0 no-footer table-hover" style="table-layout:fixed;word-wrap:break-word;color:black !important;">
            <thead>
              <tr>
                <th v-for="coluna in colunas" v-bind:width="coluna.width">
                  <span v-show="coluna.type!='VIEW'&&coluna.type!='ACTION_GREEN'&&coluna.type!='DELETE'&&coluna.type!='AJAX_CALL'&&coluna.type!='MENU'">{{coluna.title}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="item in dados" :key="item.id">

                  <td v-for="coluna in colunas">

					  <p class="descricaoMobile">{{coluna.title}}</p>

                      <span v-if="coluna.type=='YES_NO'"><span v-show="item[coluna.name]==true" class="fa fa-check-square text-success"></span> <span v-show="item[coluna.name]==false" class="glyphicon glyphicon-unchecked"></span></span>
                      <a v-if="coluna.type=='LINK'" target="_blank" :href="item[coluna.name]">{{formatarItem(item,coluna)}}</a>

                      <img v-if="item[coluna.name]&&coluna.type=='API_IMAGEM'" 
                      	@click="coluna.action(root,pai,item,apiHost+'api/carregarFoto/'+item[coluna.name]+'?token='+token)" :src="apiHost+'api/carregarFoto/'+item[coluna.name]+'?token='+token" style="height: 60px;cursor: pointer;"/>
					 
					  <a v-if="(!item[coluna.name])&&coluna.type=='API_IMAGEM'" @click="coluna.action(root,pai,item,null)">incluir foto</a>

                      <button class="btn btn-primary" v-on:click="visualizar(coluna,item[coluna.name],item)" role="button" v-if="coluna.type=='VIEW'"><i v-if="coluna.class" v-bind:class="coluna.class"></i> {{coluna.title}}</button>

                      <button class="btn btn-success" v-on:click="visualizar(coluna,item[coluna.name],item)" role="button" v-if="coluna.type=='ACTION_GREEN'"><i v-if="coluna.class" v-bind:class="coluna.class"></i> {{coluna.title}}</button>

                      <button class="btn btn-danger" v-on:click="visualizar(coluna,item[coluna.name],item)" role="button" v-if="coluna.type=='DELETE'"><i v-if="coluna.class" v-bind:class="coluna.class"></i><i v-else class="glyphicon glyphicon-remove"></i> {{coluna.title}}</button>

                      <botaoLoader :class="{btn:true,'btn-success':(coluna.class==null||coluna.class=='btn-sucess'),'btn-danger':coluna.class=='btn-danger'}"  v-on:acao="coluna.action" :pai="pai" :item="item[coluna.name]" role="button" v-if="coluna.type=='AJAX_CALL'">{{coluna.title}}</botaoLoader>

                      <div v-if="coluna.type=='MENU'" class="btn-group" style="margin-left:10px;"><a class="btn btn-gray dropdown-toggle" data-toggle="dropdown" href="#">Opções<span class="caret"></span></a>
                        <ul class="dropdown-menu pull-right">
                           <li v-for="opt in coluna.options"><a href="#" v-on:click="visualizar(opt,item[opt.name],item)"><i v-bind:class="opt.class"></i> {{opt.title}}</a></li>  
                        </ul>
                      </div>

                      <CampoEditavel :id="item['id']" :coluna="coluna.name"
                      	:dominio="dominio"
                      	:type="coluna.type"
                      	v-on:modificado="onCampoEditavelAtualizado" v-if="coluna.type=='EDITAVEL_DARAHORA'||coluna.type=='EDITAVEL_TEXTO'||coluna.type=='EDITAVEL_SENHA'||coluna.type=='EDITAVEL_COMBOBOX'||coluna.type=='EDITAVEL_BOOLEAN'||coluna.type=='EDITAVEL_TEXTAREA'" :urlEdicao="coluna.urlEdicao"
                      	:urlCombobox="coluna.urlCombobox"
			            :filtrosCombobox="coluna.filtrosCombobox?coluna.filtrosCombobox(item):null"
	                    :texto="formatarItem(item,coluna)"/>

                      <span v-if="(!coluna.type)&&(coluna.color)" v-bind:style="{ color: coluna.color(item) }">{{formatarItem(item,coluna)}}</span>

                      <span v-if="(!coluna.type)&&(!coluna.color)">{{formatarItem(item,coluna)}}</span>
                      <span v-if="(coluna.type=='HTML')&&(!coluna.color)"><span v-html="item[coluna.name]"></span></span>
                      <span v-if="coluna.type=='HTML_CLIENT'"><span v-html="coluna.htmlClient"></span></span>
                  </td>
               </tr>
            </tbody>
          </table>



        </div>

        <nav style="height:50px;position: relative;">

          <div style="padding: 20px 0;float:left;">
            <small v-if="carregando==false&&dados!=null">Mostrando <b>{{offset}} - {{offset+dados.length}}</b> de {{total}} itens.</small>
            <img v-show="carregando" src="../assets/loader-wheel.svg" style="-webkit-transform:scale(0.5);">


          </div>
          <ul class="pagination pull-right" v-show="paginado==true">
            
            <li v-show="paginaAtual>1">
              <a style="cursor:pointer;" v-on:click="paginar(paginaAtual-1)" aria-label="Anterior">
                <span aria-hidden="true">Anterior</span>
              </a>
            </li>
            <li v-if="nroPaginas" v-for="n in paginasExibidas" v-bind:class="{ active: paginaAtual==n  }"><a style="cursor:pointer;" v-on:click="paginar(n)">{{n}}</a></li>
            <li v-show="paginaAtual<nroPaginas">
              <a style="cursor:pointer;" v-on:click="paginar(paginaAtual+1)" aria-label="Próximo">
                <span aria-hidden="true">Próximo</span>
              </a>
            </li>

            <li v-show="paginaAtual<nroPaginas">
              <a style="cursor:pointer;" v-on:click="irParaUltimaPagina" aria-label="Fim">
                <span aria-hidden="true"><span class="glyphicon glyphicon-forward"></span></span>
              </a>
            </li>

          </ul>
        </nav>

      </div>

</template>


<style scoped>

.mainDiv{
	min-width: 1000px;
}

.descricaoMobile{
	visibility: hidden;
	line-height: 0px;
	margin:0px;
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	.descricaoMobile{
		visibility: visible;
		line-height: 20px;
		margin:10px;
		font-weight: 800;
	}

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 20%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 25%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	.mainDiv{
		min-width: 100%;
	}

}

.btn-primary{
  background-color: black;
}

.pagination>li>a{
  background-color: black;
  color:white;
}

.pagination>.active>a{
  background-color: #484848;
}

.pagination>.active>a:hover{
  background-color: #9e9e9e;
}


</style>