<template>
<div>

<FormExpansivel :pai="this" ref="mdFormNinhada" :large="true" id="modalPopupNinhada" alinhamento="left" title="Cadastrar Ninhada" description="" v-bind:options="optionsPopupNinhada">

<div class="" style="margin:10px;width: 800px;">

  <div>

        <form id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">

            <fieldset>

                <div class="well" v-show="cadastroPaeMaeResumido==false">

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Ninhada</label>
                      <div class="col-sm-7">
                        <input class="form-control" type="text" :value="ninhada" disabled="">
                      </div>
                  </div>

                  <div class="form-group" v-if="!especieFranquia">
                      <label for="nome" class="col-sm-4 control-label">Espécie</label>
                      <div class="col-sm-7">
                        <ComboboxBase 
                            v-model="especieCombobox" :url='apiHost+"api/ajaxComboboxEspecies"'
                            />
                      </div>
                  </div>

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Raça</label>
                      <div class="col-sm-7">
                        <ComboboxBase 
                            :filtros="{especieId:especie}"
                            v-model="racaId" :url='apiHost+"api/ajaxComboboxRacas"'/>
                      </div>
                  </div>

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Registro</label>
                      <div class="col-sm-7">
                        <select class="form-control" v-model="registro">
                            <option value="RG">RG</option>
                            <option value="RI">RI</option>
                        </select>
                      </div>
                  </div>

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Criador</label>
                      <div class="col-sm-7">
                        <ComboboxBase 
                            v-model="criador" 
                            :urlCadastro="apiHost+'api/ajaxAdicionarUsuario'"
                            :parametrosCadastro="{perfil:'CRIADORES'}"
                            :filtros="{perfil:'CRIADORES'}"
                            :url='apiHost+"api/ajaxComboboxUsuarios"'/>

                      </div>
                  </div>

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Pai</label>
                      <div class="col-sm-7">
                        <ComboboxBase 
                              :filtros="{racaId:racaId,especieId:especie,sexo:'M'}"
                              v-model="exemplarPai" :url='apiHost+"api/ajaxComboboxAnimais"'
                              />
                      </div>
                  </div>

                  <div class="form-group">
                      <label for="nome" class="col-sm-4 control-label">Mãe</label>
                      <div class="col-sm-7">
                        <ComboboxBase 
                              :filtros="{racaId:racaId,especieId:especie,sexo:'F'}"
                              v-model="exemplarMae" :url='apiHost+"api/ajaxComboboxAnimais"'/>
                      </div>
                  </div>

                  <div class="form-group">

                      <label for="nome" class="col-sm-4 control-label">Nascimento</label>
                      <div class="col-sm-2">
                        <InputDataHora v-model="dataNascimento"/>
                      </div>

                  </div>

                </div>

                <div class="well" v-show="cadastroPaeMaeResumido==true">
                  <span style="margin: 10px;">Pai: <b>{{exemplarPai}}</b></span>
                  <span>Mãe: <b>{{exemplarMae}}</b></span>

                  <button class="btn" style="margin-left: 10px;" type="button" v-on:click="cadastroPaeMaeResumido=false">editar</button>
                </div>

                <div v-show="cadastroPaeMaeResumido==true" style="position:relative;">

                  <FormFilhote style="width: 770px;" v-for="filhote in filhotes" 
                          ref="filhotes"
                          :prefixo="prefixo"
                          :sufixo="sufixo"
                          :racaId="racaId"
                          v-bind:key="filhote.pos"
                          v-model:microchip="filhote.microchip"
                          v-model:nome="filhote.nome"
                          v-model:sexo="filhote.sexo"
                          v-model:cor="filhote.cor"
                          :editavel="!filhote.salvo"
                          v-on:fechar="remover(filhote)"
                          />

                  <button class="btn btn-info" type="button" v-on:click="incluirFilhote('M')">+adicionar</button>
                </div>

            </fieldset>
        </form>

  </div>
</div>

  <template v-slot:botoes class="panel-footer">

    <button v-show="cadastroPaeMaeResumido==false" class="submit-button" type="button" v-on:click="avancar">avançar</button>

    <botao-loader v-show="cadastroPaeMaeResumido==true" ref="btnConfirm" class="submit-button" v-on:acao="salvarNinhada" role="button">Confirmar</botao-loader>

  </template>

</FormExpansivel>

  </div>
</template>

<script>
/* eslint-disable */

import FormFilhote from './FormFilhote.vue'
import BotaoLoader from './BotaoLoader.vue'
import ComboboxBase from './ComboboxBase.vue'
import InputDataHora from './InputDataHora.vue'
import FormExpansivel from './FormExpansivel.vue'
import { useToast } from "vue-toastification";
import { secureUpper,easyGet,easyPost } from '../utils'

const toast = useToast();


import { apiHost } from '../config'

export default {

  name: 'CadastroNinhada',
  props: {
    msg: String
  },

  components: {
    FormFilhote,
    FormExpansivel,
    BotaoLoader,
    ComboboxBase,
    InputDataHora
  },

  data: function () {
                      return {
                          cadastroPaeMaeResumido:false,
                          dataNascimento:null,
                          criador:null,
                          ninhada:null,
                          registro:"RG",
                          prefixo:null,
                          sufixo:null,
                          especieCombobox:null,
                          racaId:null,
                          optionsPopupNinhada:{
                            confirm:{
                              show:false
                            }
                          },
                          exemplarPai:null,
                          exemplarMae:null,
                          filhotes:[  
                                    {pos:0,nome:"",sexo:"M",cor:null,salvo:false},
                                    {pos:1,nome:"",sexo:"F",cor:null,salvo:false}
                                  ]
                      }
                    },

    watch: {
        
      criador:function(val){

        var ref = this

        if(!ref.criador){
          ref.prefixo = ""
          ref.sufixo = ""
        }else{

          var ref = this
          var parametros = "id="+ref.criador+"&offset=0"
          easyPost("api/ajaxListarUsuarios",parametros,null,function(result){

              var usuario = result.dados[0]

              ref.prefixo = usuario.prefixo
              ref.sufixo  = usuario.sufixo

          })

        }

      }

    },

    computed: {

      especieFranquia(){

          if(localStorage.getItem('especieId')=="null"){
            return null
          }
          return localStorage.getItem('especieId')

      },

      especie(){

        if(this.especieFranquia){
          return this.especieFranquia
        }

        return this.especieCombobox
      },

      apiHost(){
        return apiHost
      }

    },

    mounted: function () {

      var ref = this;
      ref.gerarNovoCodigoNinhada();

    },

    methods: {
          secureUpper,
          gerarNovoCodigoNinhada:function () {

            var ref = this
            ref.ninhada = this.uuidv4()

          },

          uuidv4:function () {

            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );

          },

          remover:function(filhote){

            var ref = this;

            if(ref.filhotes.length==1){
              toast.error("Não é possível remover o ultimo item");
              return
            }

            ref.filhotes.splice(filhote.pos,1);

            for(var i = 0; i < ref.filhotes.length; i++){
              ref.filhotes[i].pos = i;
            }

          },

          avancar:function(){

            var ref = this;

            if(ref.exemplarMae==null&&ref.exemplarPai==null&&ref.especie==null&&ref.racaId==null){
              toast.error("Caso não tenha os dados de pai e mãe necessário informar raça ou espécie caso não tenha raça definida")
              return
            }

            if(!ref.dataNascimento){
              toast.error("Informe a data de nascimento")
              return
            }

            ref.cadastroPaeMaeResumido=true
          },

          nullParamCheck(param){
            if(param==null){
              return "";
            }
            return param
          },

          salvarNinhada: function (ajaxPost,cancel) {

            var ref = this;

            for(var i = 0; i < ref.filhotes.length; i++){

              if(!ref.filhotes[i].nome){
                toast.error("nome não informado!!")
                cancel()
                return
              }

            }

            ref.salvarFilhote(ajaxPost,cancel,0);

          },

          extrairId:function(valor){
            
            if(!this.nullParamCheck(valor) ){
              return "";
            }

            if(!valor)
              return "";

            if(valor&&valor.includes("|")){

              var valorSplit = valor.split("|")
              return valorSplit[0]

            }

            return valor
          },

          limparDados:function(){

            var ref = this;
            ref.gerarNovoCodigoNinhada();
            ref.$refs.mdFormNinhada.limparRecolherDados();
            ref.cadastroPaeMaeResumido = false;
            ref.filhotes = []
            ref.incluirFilhote("M")
            ref.incluirFilhote("F")

          },

          salvarFilhote: function (ajaxPost,cancel,pos){

            var ref = this;

            if(pos>=ref.filhotes.length){

              ref.$emit('animaisIncluidos', ref.filhotes, ref.ninhada );

              ref.limparDados();
              return
            }

            if(ref.filhotes[pos].salvo){
              pos++;
              ref.salvarFilhote(ajaxPost,cancel,pos);
              return
            }

            ref.$refs.filhotes[pos].salvarImagemCasoNecessario(function(result){

                  var fotoPerfilId
                  if(result){

                    if(!result.status){
                      toast.error(result.message)
                      return
                    }
                    if(result.idArquivo){
                      fotoPerfilId = result.idArquivo
                    }

                  }

                  var nome="";
                  if(ref.prefixo){
                    nome=ref.prefixo+" "
                  }

                  nome+=ref.filhotes[pos].nome

                  if(ref.sufixo){
                    nome+=" "+ref.sufixo
                  }

                  var parameters = "nome="+nome+
                                      "&especieId="+ref.nullParamCheck(ref.especie)+
                                      "&racaId="+ref.nullParamCheck(ref.racaId)+
                                      "&animalPaiId="+ref.nullParamCheck(ref.exemplarPai)+
                                      "&animalMaeId="+ref.nullParamCheck(ref.exemplarMae)+
                                      "&criador="+ref.nullParamCheck(ref.criador)+
                                      "&sexo="+ref.nullParamCheck(ref.filhotes[pos].sexo)+
                                      "&registro="+ref.nullParamCheck(ref.registro)+
                                      "&fotoPerfilId="+ref.nullParamCheck(fotoPerfilId)+
                                      "&ninhada="+ref.ninhada+
                                      "&cor="+ref.extrairId(ref.filhotes[pos].cor)+
                                      "&microchip="+ref.extrairId(ref.filhotes[pos].microchip)+
                                      "&dataNascimento="+ref.nullParamCheck(ref.dataNascimento);

                  ajaxPost(apiHost+"api/ajaxAdicionarAnimal",parameters,
                      function (result) {

                          if(!result.status){
                            toast.error(result.message)
                            return
                          }

                          ref.filhotes[pos].salvo = true;

                          pos++;
                          ref.salvarFilhote(ajaxPost,cancel,pos);

                      });

                });

          },

          incluirFilhote: function (sexo) {

            var ref = this;
            ref.filhotes.push({pos: ref.filhotes.length,
                              nome:"",sexo:sexo,cor:null,
                              microchip:"",
                              salvo:false });

          },

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.submit-button {
  display: block;
  background-color: #3498DB;
  height: 40px;
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875em;
  border: none;
  font-weight: 700;
  padding: 0 34px;
  margin: 0 auto;
}
.submit-button img {
  position: relative;
  top: 3px;
  right: 6px;
}
.submit-button:hover {
  background-color: #217dbb;
  cursor: pointer;
}

</style>
