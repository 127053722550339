<script>
  /* eslint-disable */

var moment = require('moment');
import { apiHost } from '../config'
import { useToast } from "vue-toastification"
import { secureUpper,easyPost,easyPostJson,resizeImage,nullCheck,extrairId } from '../utils'
import ComboboxBase from '../components/ComboboxBase.vue'
import InputDataHora from '../components/InputDataHora.vue'
import UploadImages from "vue-upload-drop-images"
import BotaoLoader from '../components/BotaoLoader.vue'
import Menu from '../components/Menu.vue'

const toast = useToast();

export default {

  components: {
    UploadImages,
    BotaoLoader,
    InputDataHora,
    Menu,
    ComboboxBase
  },

  mounted: function () {

    var ref = this;

    if(!ref.logado){
      ref.$router.push({ path: `/app/cadastroProprietario/`})
    }

    var medal = ref.$route.query.medal
    if(medal){
      ref.permitirEdicaoMedal      = false
      ref.dadosAnimal.codigoQrCode = medal
    }

  },

  computed:{

      logado() {
        return localStorage.getItem('token')!=null
      },

      apiHost() {
        return apiHost
      }

  },

  data: function () {
                      return {
                            localizacao:null,
                            fileFoto:null,
                            permitirEdicaoMedal:true,
                            dadosAnimal:{
                                id:null,
                                situacao:"REGULAR",
                                urlFoto:"",
                                nome:"",
                                nascimento: "20/02/2000",
                                especie: null,
                                raca: null,
                                sexo: "M",
                                cor: null,
                                microchip: "",
                                codigoQrCode: "",
                                observacoes: ""
                            }

                        }
                    },

  methods: {
          
          secureUpper,

          cadastrarPet: function (ajaxPost,cancel) {

            var ref = this
            var parameters = "nome="+ref.dadosAnimal.nome+
                            "&especieId="+nullCheck(ref.dadosAnimal.especie)+
                            "&racaId="+nullCheck(ref.dadosAnimal.raca)+
                            "&sexo="+nullCheck(ref.dadosAnimal.sexo)+
                            "&cor="+extrairId(ref.dadosAnimal.cor)+
                            "&microchip="+extrairId(ref.dadosAnimal.microchip)+
                            "&observacoes="+extrairId(ref.dadosAnimal.observacoes)+
                            "&codigoQrCode="+extrairId(ref.dadosAnimal.codigoQrCode)+
                            "&dataNascimento="+nullCheck(ref.dadosAnimal.nascimento)

                  ajaxPost(apiHost+"api/ajaxCadastrarPet",parameters,
                      function (result) {

                          if(!result.status){
                            toast.error(result.message)
                            return
                          }

                          ref.dadosAnimal.id = result.id

                          if(ref.fileFoto){
                            ref.salvarImagem(ref.fileFoto, ref.onPetCadastrado )
                          }else{
                            ref.onPetCadastrado()
                          }

                      })


          },

          onPetCadastrado: function(){

            toast.success("Salvo com sucesso!")
            window.location.href = "/app/animais"

          },

          salvarImagem: async function (resizedImage,onImagemSalva){

            var ref = this
            var form = new FormData()
            form.append('file_1', resizedImage )

            $.ajax({
                url: apiHost+'api/ajaxUploadFoto',
                data: form,
                processData: false,
                contentType: false,
                type: 'POST',
                headers: {"Authorization": 'Bearer '+localStorage.getItem('token')},
                success: function (result) {
                  
                  if(result.status==true){

                    easyPost('api/ajaxEditarInformacao',
                             'dominio=Animais&type=EDITAVEL_TEXTO&coluna=fotoPerfilId&id='+ref.dadosAnimal.id+'&valor='+result.idArquivo,
                             'Imagem salva com sucesso',
                             function(){

                                onImagemSalva()

                             })

                  }else{
                    toast.error(result.message)    
                  }

                }
            })

          },

          onImagemChanged: async function (file){

            var ref = this
            if( (!file)||(!file[0])){
              console.log("=====file null, ignorado...")
              return;
            }

            ref.fileFoto = await resizeImage({
                                                file: file[0],
                                                maxSize: 500
                                            })

        }

    }

}
</script>

<template>
<div>

  <Menu/>

<div class="row">

  <div class="col-xs-12 col-sm-12 col-md-4"></div>

  <div class="col-xs-12 col-sm-12 col-md-4">
    
    <div style="position: relative; border: #4dc7b1;border-style: solid;padding: 20px;">
      <b>Nome do pet: </b><input type="text" class="form-control" v-model="dadosAnimal.nome" />
      <br>
      <b>Data de nascimento: </b>
      <br>
      <InputDataHora v-model="dadosAnimal.nascimento" style="float: left;width: 130px;"/>
      <br><br>
      <b>Sexo: </b>
      <select v-model="dadosAnimal.sexo" class="form-control">
        <option value="M">Macho</option>
        <option value="F">Femea</option>
      </select>
      <br>
      <b>Espécie: </b>
      <ComboboxBase 
        :url="apiHost+'api/ajaxComboboxEspecies'"
        v-model="dadosAnimal.especie"/>
      <br>
      <b>Raça: </b>
      <ComboboxBase 
          :url="apiHost+'api/ajaxComboboxRacas'"
          v-model="dadosAnimal.raca"
          :filtros="{especieId:dadosAnimal.especie}"
          />


      <br>
      <b>Cor: </b>
      <ComboboxBase 
        :url="apiHost+'api/ajaxComboboxCores'"
        v-model="dadosAnimal.cor"
        :filtros="{racaId:dadosAnimal.raca}"
        />

      <br>
      <b>Código do microchip: </b><input type="text" class="form-control" v-model="dadosAnimal.microchip" />
      <br>
      <b>Sequência numérica QR Code: </b><input type="text" class="form-control" v-model="dadosAnimal.codigoQrCode" :disabled="!permitirEdicaoMedal"/>
      <br>
      <b>Observações: </b>
      <textarea class="form-control" rows="5" v-model="dadosAnimal.observacoes"></textarea>

      <br>
      <b>Situação: </b>
      <select v-model="dadosAnimal.situacao" class="form-control" disabled="">
        <option value="REGULAR">Situação Regular</option>
        <option value="PERDIDO">Situação Perdido</option>
        <option value="ROUBADO">Situação Roubado</option>
        <option value="FURTADO">Situação Furtado</option>
      </select>

      <img v-if="dadosAnimal.urlFoto" :src="dadosAnimal.urlFoto" style="height: 200px;">

      <div style="margin: 10px;width: 300px;">
        
        <UploadImages @changed="onImagemChanged" :max="1" maxError="1 foto 
        por animal" fileError="Apenas imagens aceitas" clearAll="remover"/>
      
      </div>

      <BotaoLoader style="margin-top: 30px;" class="btn btn-success btn-block" v-on:acao="cadastrarPet" :pai="this" role="button"><span class="glyphicon glyphicon-ok" aria-hidden="true"> 
      </span> Salvar</BotaoLoader>

    </div>

  </div>
  <div class="col-xs-12 col-sm-12 col-md-4"></div>
</div>

</div>
</template>