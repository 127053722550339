<script>
/* eslint-disable */

import Arvore from '../components/Arvore.vue'
var moment = require('moment');
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import { secureUpper,buscarPais,easyGet } from '../utils'

const toast = useToast();

export default {

  name: 'Pedigree',
  props: {
    msg: String
  },

  components: {
    Arvore
  },

  mounted: function () {

    this.carregarDados()
    this.carregarConfiguracaoPedigree()

  },

    computed:{

      descricaoIrmaos(){

        var ref = this
        var nomes = ""

        for (var i = 0; i < ref.irmaosMachos.length; i++) {
            
            if(nomes){
              nomes+=", "
            }

            nomes += ref.irmaosMachos[i].nome
        }

        for (var i = 0; i < ref.irmaosFemeas.length; i++) {
            
            if(nomes){
              nomes+=", "
            }

            nomes += ref.irmaosFemeas[i].nome
        }

        return nomes
      },

      descricaoPais(){

        var idRaca

        if(this.animal.Raca&&this.animal.Raca.pais){
          idRaca = this.animal.Raca.pais
        }

        console.log("====descricaoPais idRaca:"+idRaca)

        return buscarPais(idRaca)

      },

      iconeBandeira() {

        var idRaca
        if(this.animal.Raca&&this.animal.Raca.pais){
          idRaca = this.animal.Raca.pais
        }

        if(!idRaca){
          return ""
        }

        return "flag-icon flag-icon-"+idRaca.toLowerCase()
      },

      token() {
        return localStorage.getItem('token')
      },

      apiHost(){
          return apiHost
      },

      rg(){ 

        var ref = this;
        return ref.$route.params.rg.replaceAll("-","/")

      },

      qrCode(){

        const urlConsulta = window.location.href.replaceAll("pedigree","consulta");
        return "https://api.katon.tech/api/qr-code?type=ONLY_BASE64_IMAGE&chs=64x64&data="+encodeURI(urlConsulta)

      },

      dataAtual() {
        return moment().format('DD/MM/YYYY')
      },

      dataNascimento() {

        if(!this.animal.dataNascimento){
          return "indefinida"
        }
       
        return moment(this.animal.dataNascimento).format('DD/MM/YYYY')
      }

    },

  data: function () {
                      return {

                            animal:null,
                            irmaosMachos: [],
                            irmaosFemeas: [],

                            configStyle: null,

                                          /*{
   "principal":{
      "position":"fixed",
      "left":"45px",
      "top":"15px",
      "width":"1024px",
      "height":"724px",
      "z-index":"999"
   },
   "nome":{
      "position":"absolute",
      "left":"20px",
      "top":"50px"
   },
   "proprietario":{
      "position":"absolute",
      "left":"360px",
      "top":"60px"
   },
   "foto":{
      "position":"absolute",
      "left":"810px",
      "bottom":"500px",
      "width":"140px"
   },
   "qrCode":{
      "position":"absolute",
      "left":"675px",
      "top":"125px"
   },
   "cor":{
      "position":"absolute",
      "left":"20px",
      "top":"140px"
   },
   "sexo":{
      "position":"absolute",
      "left":"110px",
      "top":"175px"
   },
   "microchip":{
      "position":"absolute",
      "left":"360px",
      "top":"195px"
   },
   "nascimento":{
      "position":"absolute",
      "left":"20px",
      "top":"175px"
   },
   "rg":{
      "position":"absolute",
      "left":"360px",
      "top":"150px"
   },
   "raca":{
      "position":"absolute",
      "left":"20px",
      "top":"90px"
   },
   "emissao":{
      "position":"absolute",
      "left":"220px",
      "top":"175px"
   },
   "empresa":{
      "position":"absolute",
      "left":"20px",
      "top":"210px"
   },
   "pais":{
      "position":"absolute",
      "left":"340px",
      "top":"635px"
   },
   "irmao":{
      "position":"absolute",
      "left":"20px",
      "top":"640px"
   },
   "arvore":{
      "position":"absolute",
      "left":"20px",
      "top":"245px"
   }
},
                                        */

                            AnimalPai:{nome:"",

                              AnimalPai:{nome:"",

                                AnimalPai:{nome:"",

                                        AnimalPai:{nome:""

                                        },
                                        AnimalMae:{
                                              nome:""
                                        }


                                },
                                AnimalMae:{
                                      nome:"",

                                        AnimalPai:{nome:""
  
                                        },
                                        AnimalMae:{
                                              nome:""
                                        }
                                }

                              },
                              AnimalMae:{
                                    nome:"",

                                    AnimalPai:{nome:"",

                                        AnimalPai:{nome:""
  
                                        },
                                        AnimalMae:{
                                              nome:""
                                        }



                                    },
                                    AnimalMae:{
                                          nome:"",

                                          AnimalPai:{nome:""
    
                                          },
                                          AnimalMae:{
                                                nome:""
                                          }

                                    }

                              }

                            },

                            AnimalMae:{nome:"",

                              AnimalPai:{nome:"",

                                AnimalPai:{nome:"",

                                        AnimalPai:{nome:""

                                        },
                                        AnimalMae:{
                                              nome:""
                                        }


                                },
                                AnimalMae:{
                                      nome:"",

                                        AnimalPai:{nome:""
  
                                        },
                                        AnimalMae:{
                                              nome:""
                                        }
                                }

                              },
                              AnimalMae:{
                                    nome:"",

                                    AnimalPai:{nome:"",

                                        AnimalPai:{nome:""
  
                                        },
                                        AnimalMae:{
                                              nome:""
                                        }



                                    },
                                    AnimalMae:{
                                          nome:"",

                                          AnimalPai:{nome:""
    
                                          },
                                          AnimalMae:{
                                                nome:""
                                          }

                                    }

                              }

                            },
                            
                      }
                    },

    methods: {

          secureUpper,
          carregarArvore:function(referencia,animalConsultado,nivel){

            var ref = this;

            if(nivel==4){
              console.log("====3 niveis, ignora...")
              return ;
            }

            console.log("===carregarArvore nivel:"+nivel);
            console.log("===carregarArvore referencia.AnimalPai:"+JSON.stringify(referencia.AnimalPai) );
            console.log("===carregarArvore referencia.AnimalMae:"+JSON.stringify(referencia.AnimalMae) );
            console.log("===carregarArvore animalConsultado:"+JSON.stringify(animalConsultado) );

            nivel++;

            if(animalConsultado.AnimalPai!=null){
              referencia.AnimalPai.nome = animalConsultado.AnimalPai.nome;
              referencia.AnimalPai.rg   = animalConsultado.AnimalPai.rg;
              ref.carregarAnimalArvore(referencia.AnimalPai,nivel)
            }

            if(animalConsultado.AnimalMae!=null){
              referencia.AnimalMae.nome = animalConsultado.AnimalMae.nome;
              referencia.AnimalMae.rg   = animalConsultado.AnimalMae.rg;
              ref.carregarAnimalArvore(referencia.AnimalMae,nivel)
            }

          },

          carregarAnimalArvore:function(animal,nivel){

            if(!animal.rg){
              toast.error("animal sem rg...");
              return;
            }

            var ref = this;
            var parameters = "rg="+animal.rg
            $.ajax({
                url: apiHost+"api/ajaxCarregarAnimal",
                data: parameters,
                dataType: "JSON",
                type: "POST",
                headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
               }).success(function(result){
                
                if(result.status==true){

                  ref.carregarArvore( animal ,result.animal ,nivel);

                }else{
                    toast.error(result.message);
                }

            }).complete(function(result){

            });

          },

          carregarDados: function () {

            var ref  = this;
            const rg = ref.$route.params.rg.replaceAll("-","/")

            console.log("=====rg:"+rg);

            if(!rg){
              toast.error("rg não informada")
              return
            }

            var parameters = "rg="+rg+"&carregarIrmaos=true"
            $.ajax({
                url: apiHost+"api/ajaxCarregarAnimal",
                data: parameters,
                dataType: "JSON",
                type: "POST",
                headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
               }).success(function(result){
                
                if(result.status==true){

                  console.log("===result.animal:"+result.animal)
                  console.log("===result.irmaosMachos:"+result.irmaosMachos)
                  console.log("===result.irmaosFemeas:"+result.irmaosFemeas)

                  if(result.animal==null){
                    window.location.href = "/404";
                    return;
                  }

                  ref.animal = result.animal
                  ref.irmaosMachos = result.irmaosMachos
                  ref.irmaosFemeas = result.irmaosFemeas

                }else{
                    toast.error(result.message);
                }

            }).complete(function(result){
              

            });


          },

          carregarConfiguracaoPedigree:function(){

            var ref = this
            var parametros
            easyGet("api/ajaxCarregarConfiguracaoPedigree",parametros,null,function(result){

                  ref.configStyle = result.pedigreeStyle;

              })


          }


    }

}
</script>

<template>
<div>

    <div v-if="animal&&configStyle" :style="configStyle.principal">

<!--       <img src="@/assets/pedigree_base_3.png" width="1024"
          style="position:fixed;left:0px;top:0px;">
 -->
      <div :style="configStyle.nome">
        <b :style="configStyle.label">Nome</b><br>
        <p>{{animal.nome}}</p>
      </div>

      <div :style="configStyle.proprietario">
        <b :style="configStyle.label">Proprietário</b><br>
        <span>{{animal.Proprietario?animal.Proprietario.nome:"indefinido"}}</span>
      </div>

      <div :style="configStyle.qrCode">
        <b :style="configStyle.label">QR code de identificação</b>
        <img :src="qrCode" style="height: 64px; width: 64px;">
      </div>
      
      <img :style="configStyle.foto" v-if="animal.fotoPerfilId" :src="apiHost+'api/carregarFoto/'+animal.fotoPerfilId+'?token='+token" />

      <div :style="configStyle.raca">
        <b :style="configStyle.label">Raça</b><br>
        <span v-if="animal.Raca">{{animal.Raca.nome}}</span>
        <span v-if="!animal.Raca">indefinida</span>
      </div>

      <div :style="configStyle.rg">
        <b :style="configStyle.label">Nº de registro</b><br>
        <span>{{animal.rg}}</span>
      </div>

      <div :style="configStyle.cor">
        <b :style="configStyle.label">Cor</b><br>
        <span v-if="animal.Cor">{{animal.Cor.nome}}</span>
        <span v-if="!animal.Cor">indefinida</span>
      </div>

      <div :style="configStyle.sexo">
        <b :style="configStyle.label">Sexo</b><br>
        <span>{{animal.sexo=="F"?"Femea":"Macho"}}</span>
      </div>

      <div :style="configStyle.microchip">
        <b :style="configStyle.label">Microchip</b><br>
        <span>{{animal.nroMicrochip}}&nbsp;</span>
      </div>

      <div :style="configStyle.nascimento">
        <b :style="configStyle.label">Nascimento</b><br>
        <span>{{dataNascimento}}</span>
      </div>

      <div :style="configStyle.empresa">
        <b :style="configStyle.label">Criador</b><br>
        <span>{{animal.Proprietario?animal.Proprietario.nomeEmpresa:"indefinido"}}</span>
      </div>

      <div :style="configStyle.emissao">
        <b :style="configStyle.label">Emissão</b><br>
        <span>{{dataAtual}}</span>
      </div>

      <div :style="configStyle.pais">
        <b :style="configStyle.label">Origem</b>
        <div style="margin-top:5px;">
          <i style="margin-right:5px;font-size: 24px!important;float:left;" :class="iconeBandeira"></i>
          <span>{{descricaoPais}}</span>
        </div>
      </div>

      <div :style="configStyle.irmao">
        <b :style="configStyle.label">Irmãos de ninhada</b><br>
        <small>{{descricaoIrmaos}}</small>
      </div>

      <div :style="configStyle.arvore">
        <Arvore :compacto="true" :cabecalho="false" :edicao="false" :rg="rg" 
                :styleItem1="configStyle.itemArvore1"
                :styleItem2="configStyle.itemArvore2"
                :styleItem3="configStyle.itemArvore3"
                :styleItem4="configStyle.itemArvore4"
                :styleMae="configStyle.itemMae"
                :stylePai="configStyle.itemPai"/>
      </div>

    </div>

  </div>
</template>

<style scoped type="text/css" media="print">

  span{
    text-transform:uppercase;
  }

  @media print {  
    body {
        -webkit-print-color-adjust: exact;
        margin:0px;
      }
  }

  @page { size: landscape !important; 
         margin: 0;
        }

</style>