require('bootstrap/dist/css/bootstrap.min.css');
require('./css/flags/css/flag-icons-printable.css');
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Maska from "maska";

import { createApp } from 'vue'
import router from './router' 

import App from './App.vue'

console.log("====process.env.NODE_ENV:"+process.env.NODE_ENV);

var app = createApp(App);
app.use(router)
app.use(Maska)

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

app.mount('#app')