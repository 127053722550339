<script>
/* eslint-disable */

window.$ = window.jQuery = require('jquery');
require("../js/jquery.numeric.js");

export default {
    name:"InputNumerico",
    props: {
      id: null,
      name: null,
      negativo: null,
      decimal: null,
      modelValue: null,
      placeholder: null,
      disabled: {
        type: Boolean,
        default: function () {
          return false
        }
      },
      width: {
        type: Number,
        required: false,
        default:80
      }
    },


  watch: {
    modelValue :{
      handler: function (val, oldVal) {

        var ref = this;
        var refField = ref.$refs.txtNumerico;
        $(refField).val(this.modelValue);

      }
    }
  },

  methods: {

    focus: function () {

      var ref = this;
      var refField = ref.$refs.txtNumerico;
      refField.focus();

    }

  },

  mounted: function () {

    var ref = this;
    var refField = ref.$refs.txtNumerico;

  	var permiteValorNegativo = false;
  	if(this.negativo===true){
  		permiteValorNegativo = true;
  	}

  	var decimalConf;
  	if(this.decimal===false){
  		decimalConf = false;
  	}else{	
		decimalConf = ",";
  	}

    $(refField).numeric({ decimal : decimalConf, negative : permiteValorNegativo });

    if(this.modelValue!=null){
  		$(refField).val(this.modelValue);
    }

    $(refField).keyup(function(e){

      var code = e.which;
      if(code==13){
        ref.$emit('confirmado');
      }

      ref.$emit('input', $(refField).val() );

    });

    $(refField).focus(function(){
      ref.$emit('focus');
    });

    $(refField).blur(function(){
      ref.$emit('blur');
    });

  }

}
</script>

<template>
    <input ref="txtNumerico" autocomplete="off" :disabled="disabled" :placeholder="placeholder" 
    v-bind:style="{ width: width +'px'}" :id="id" :name="name" type="text" :class="{'form-control': true }">
</template>

<style scoped>
</style>