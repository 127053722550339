<script>
/* eslint-disable */

import ComboboxBase from '../components/ComboboxBase.vue'
import { useToast } from "vue-toastification";
const toast = useToast();
import { apiHost } from '../config'
import UploadImages from "vue-upload-drop-images"
import { resizeImage } from '../utils'
import { secureUpper } from '../utils'

export default {

  components: {
    ComboboxBase,
    UploadImages
  },
  name:"FormFilhote",
  data: function () {
            return {
              resumir:false,
              exibirFoto:false,
              arquivoParaUpload:null,
              arquivoSalvoId:null
            }
          },

  props: {
    racaId: String,
    nome: String,
    sexo: String,
    microchip: String,
    cor: String,
    prefixo:String,
    sufixo:String,
    editavel:Boolean
  },

  computed: {

    apiHost(){
      return apiHost
    },

    matriz(){
      return localStorage.getItem('matriz')=="true"
    }

  },

    methods: {

      secureUpper,

      onImagemChanged:async function (file) {

        var ref = this
        if( (!file)||(!file[0])){
          ref.arquivoParaUpload = null
          return;
        }

        ref.arquivoParaUpload = await resizeImage({
                                                      file: file[0],
                                                      maxSize: 500
                                                  })

      },

      salvarImagemCasoNecessario:function (callback) {

        var ref = this
        if( (!ref.arquivoParaUpload ) ){
          console.log("=====file null, ignorado...")
          callback()
          return;
        }

        var form = new FormData();
        form.append('file_1', ref.arquivoParaUpload); 

        $.ajax({
            url: apiHost+'api/ajaxUploadFoto',
            data: form,
            processData: false,
            contentType: false,
            type: 'POST',
            headers: {"Authorization": 'Bearer '+localStorage.getItem('token')},
            success: function (result) {
              
              callback(result)

              if(result.status==true){
                ref.arquivoSalvoId = result.idArquivo
              }

            }
        });

      }

    },

    watch: {
      cor :{
      
        handler: function (val, oldVal){

          var ref         = this;
          ref.$emit('update:cor', ref.cor);

        }
      }
    
    },

  emits: ['update:nome','update:sexo','update:cor','update:microchip']

}
</script>

<template>

  <div class="well">

    <button v-if="editavel" class="btn btn-warning pull-right" type="button" v-on:click="$emit('fechar')">x</button>

    <div v-show="resumir==true||editavel==false">
      <span>Nome: <b>{{nome}}</b></span>
      <button v-if="editavel" class="btn btn-info pull-right" type="button" v-on:click="resumir=false">expandir</button>

      <span class="alert alert-info" 
            style="top: 5px;right: 35px;position: absolute;" 
            v-if="editavel==false">já salvo

            <span v-if="arquivoSalvoId"> (imagem salva)</span>
      </span>

    </div>

    <div v-show="resumir==false&&editavel==true">
      
      <div class="form-group">

          <label for="nome" class="col-sm-4 control-label">Nome do animal</label>
          <div class="col-sm-7">

              <input v-show="prefixo" type="text" :value="prefixo" disabled style="width: 100%;">

              <input 
                    :value="nome"
                    @input="$emit('update:nome', secureUpper($event.target.value) )"
                   name="nome" type="text" id="nome" class="form-control" placeholder="">

              <input v-show="sufixo" type="text" :value="sufixo" disabled style="width: 100%;">
          </div>
      </div>

      <div class="form-group">
          <label for="nome" class="col-sm-4 control-label">Sexo</label>
          <div class="col-sm-7">

            <select 
                   :value="sexo"
                    @input="$emit('update:sexo', secureUpper($event.target.value) )"
                   class="form-control">
              <option value="M">Macho</option>
              <option value="F">Femea</option>
            </select>

          </div>
      </div>

      <div class="form-group">
          <label for="nome" class="col-sm-4 control-label">Cor</label>
          <div class="col-sm-8">

            <ComboboxBase v-if="matriz"
                  :urlCadastro="apiHost+'api/ajaxAdicionarCor'"
                  v-model="cor"
                  :url='apiHost+"api/ajaxComboboxCores"'
                  :parametrosCadastro="{racaId:racaId}"
                  :filtros="{racaId:racaId}"
                />

            <ComboboxBase v-if="!matriz"
                  v-model="cor"
                  :url='apiHost+"api/ajaxComboboxCores"'
                  :filtros="{racaId:racaId}"/>

          </div>
      </div>

      <div class="form-group">
          <label for="nome" class="col-sm-4 control-label">Nro do microchip</label>
          <div class="col-sm-7">

              <input 
                    :value="microchip"
                    @input="$emit('update:microchip', secureUpper($event.target.value) )"
                   name="microchip" type="text" id="microchip" class="form-control" placeholder="">

          </div>
      </div>

      <div class="form-group">
          <label for="nome" class="col-sm-4 control-label">Foto</label>
          <div class="col-sm-8">

            <a v-if="!exibirFoto" v-on:click="exibirFoto=true">+incluir</a>

            <UploadImages v-if="exibirFoto" @changed="onImagemChanged" :max="1" maxError="1 foto por animal" fileError="Apenas imagens aceitas" clearAll="remover"/>

          </div>
      </div>

      <button class="btn btn-info pull-right" type="button" v-on:click="resumir=true">recolher</button>
      <br>
    </div>

  </div>

</template>

<style scoped>
</style>