<template>
  <div class="hello">
    <Menu/>

    <CadastroNinhada v-if="habilitarOpcoes" v-on:animaisIncluidos="onAnimaisIncluidos"/>

    <button v-if="habilitarOpcoes" style="margin: 10px;" type="button" class="btn btn-info" @click="incluirArvore">Nova arvore</button>

    <div style="margin:10px;padding: 5px;">
      <TabelaAjax dominio="Animais" ref="tbDados" :root="this" :paginado="true" :colunas="configTbDados.columns" :url="configTbDados.url" :filtros="configTbDados.filtros" ></TabelaAjax>
    </div>

    <popup-simples :pai="this" ref="mdPopTransferencias" :large="false" id="modalPopTransferencias" alinhamento="left" title="Histórico de transferências"  v-bind:options="optionsPopTransferencias">

      <TabelaAjax ref="tbTransferencias" :root="this" :paginado="true" :colunas="configTbTransferencias.columns" :url="configTbTransferencias.url" :filtros="configTbTransferencias.filtros" ></TabelaAjax>

    </popup-simples>

    <popup-simples :pai="this" ref="mdPopLinhagem" :large="true" id="modalPopLinhagem" alinhamento="left" 
                    title="Árvore"  v-bind:options="optionsPopLinhagem">

      <div v-show="!rgAnimalArvore">

          <b class="titulo">Raça</b>
          <ComboboxBase 
              :url="apiHost+'api/ajaxComboboxRacas'"
              v-model="racaIdNovoAnimal"/>

          <br><br>
          <b v-show="racaIdNovoAnimal" class="titulo">Animal</b>
          <ComboboxBase v-show="racaIdNovoAnimal"
              :url="apiHost+'api/ajaxComboboxAnimais'"
              :urlCadastro="apiHost+'api/ajaxAdicionarAnimal'"
              :filtros="{racaId:racaIdNovoAnimal}"
              :parametrosCadastro="{racaId:racaIdNovoAnimal}"
              v-model="idAnimalCadastroArvore"/>
        
      </div>

      <Arvore :rg="rgAnimalArvore"/>

    </popup-simples>

    <PopupConfirmacao ref='mdPopConfirmacaoExclusao' v-on:sucesso="onExcluido"/>

    <popup-simples :pai="this" ref="mdPopupFotoAnimal" :large="true" id="modalPopupFoto" alinhamento="left" :title="optionsPopupFoto.titulo"  v-bind:options="optionsPopupFoto">
              
      <img v-if="optionsPopupFoto.urlFoto" :src="optionsPopupFoto.urlFoto" style="height: 200px;">

      <div style="margin: 10px;">
        <button class="btn btn-info" v-if="!optionsPopupFoto.editarFoto" @click="optionsPopupFoto.editarFoto=true">editar</button>
        
        <UploadImages v-if="optionsPopupFoto.editarFoto" @changed="onImagemChanged" :max="1" maxError="1 foto por animal" fileError="Apenas imagens aceitas" clearAll="remover"/>
      </div>

    </popup-simples>

    <popup-simples :pai="this" ref="mdPopup" :large="true" id="modalPopup" alinhamento="left" title="Transferir para novo proprietário"  v-bind:options="optionsPopup">

        <form v-if="animalPopup" id="frmAdicionarUsuarioAdmin" class="form-horizontal" role="form">
            <fieldset>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Nome</label>
                    <div class="col-sm-7">
                      <input class="form-control" type="text" :value="animalPopup.nome" disabled="">
                    </div>
                </div>

               <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Registro</label>
                    <div class="col-sm-7">
                      <input class="form-control" type="text" :value="animalPopup.rg" disabled="">
                    </div>
                </div>

               <div class="form-group" v-if="animalPopup.Proprietario">
                    <label for="nome" class="col-sm-4 control-label">Proprietário atual</label>
                    <div class="col-sm-7">
                      <input class="form-control" type="text" :value="animalPopup.Proprietario.nome" disabled="">
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Novo proprietário</label>
                    <div class="col-sm-7">
                        <ComboboxBase 
                            :url="apiHost+'api/ajaxComboboxUsuarios'"
                            :urlCadastro="apiHost+'api/ajaxAdicionarUsuario'"
                            :parametrosCadastro="{perfil:'CRIADORES',especieId: (animalPopup.Especie?animalPopup.Especie.id:null) }"
                            :filtros="{perfil:'CRIADORES'}"
                            v-model="novoProprietario" />
                    </div>
                </div>

                <div class="form-group">
                    <label for="nome" class="col-sm-4 control-label">Novo Nome</label>
                    <div class="col-sm-7">
                      <input class="form-control" type="text" v-model="novoNome" @keyup="novoNome=secureUpper(novoNome)">
                    </div>
                </div>

            </fieldset>
        </form>

    </popup-simples>

  </div>
</template>

<script>
/* eslint-disable */

import TabelaAjax from '../components/TabelaAjax.vue'
import Menu from '../components/Menu.vue'
import PopupSimples from '../components/PopupSimples.vue'
import ComboboxBase from '../components/ComboboxBase.vue'
import CadastroNinhada from '../components/CadastroNinhada.vue'
import PopupConfirmacao from '../components/PopupConfirmacao.vue'
import Arvore from '../components/Arvore.vue'
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
import UploadImages from "vue-upload-drop-images"
import { easyPost,easyGet,resizeImage,secureUpper } from '../utils'

const toast = useToast();

export default {

  name: 'Animais',
  props: {
    msg: String
  },

  components: {
    TabelaAjax,
    Menu,
    Arvore,
    PopupSimples,
    PopupConfirmacao,
    CadastroNinhada,
    UploadImages,
    ComboboxBase
  },

  computed: {

    habilitarOpcoes(){

      if(this.perfil=="PROPRIETARIO"){
        return false
      }

      console.log("======habilitarOpcoes!!!! true")

      return true
    
    },

    perfil() {
      return localStorage.getItem('perfil')
    },

    apiHost(){
      return apiHost
    },

  },

  watch:{

    idAnimalCadastroArvore(val){

      var ref = this
      if(ref.idAnimalCadastroArvore){

        easyGet("api/ajaxBuscarRgAnimal","id="+ref.extrairId(ref.idAnimalCadastroArvore),null,function(result){

          ref.rgAnimalArvore = result.animal.rg

        })

      }else{
        ref.rgAnimalArvore = null
      }

    }

  },

  data: function () {

                      console.log("====data #1")

                      var ref = this;

                      var colunas = [ 
                                      {name:"nome",title:"Nome",type:"EDITAVEL_TEXTO",
                                                urlEdicao:apiHost+"api/ajaxEditarInformacao"}
                                    ]

                      var filtros = [
                                      {
                                        name:"filtroNome",
                                        title:"Nome",
                                        selected:"",
                                        type:"SEARCH",
                                      }
                                    ]

                      if( localStorage.getItem('matriz')=="true" ){

                        colunas.push({name:"Especie.nome",title:"Especie"})

                        filtros.push({
                                        title:"Franquias",
                                        name:"filtroFranquias",
                                        selected:"",
                                        url:apiHost+"api/ajaxComboboxFranquias",
                                      })

                        filtros.push({
                                        title:"Espécie",
                                        name:"filtroEspecie",
                                        selected:"",
                                        url:apiHost+"api/ajaxComboboxEspecies",
                                      })

                        filtros.push({
                                        title:"Raças",
                                        name:"filtroRaca",
                                        selected:"",
                                        url:apiHost+"api/ajaxComboboxRacas",
                                      })

                      }

                      console.log("====data #2")

                      if(localStorage.getItem('perfil')!="PROPRIETARIO"){

                        filtros.push({
                                          name:"ninhada",
                                          title:"Ninhada",
                                          selected:"",
                                          type:"SEARCH",
                                        })

                      }

                      console.log("====data #3")

                      colunas = colunas.concat([ 
                                                  {name:"sexo",title:"Sexo",type:"EDITAVEL_TEXTO",
                                                        urlEdicao:apiHost+"api/ajaxEditarInformacao"},


                                                  {name:"Cor.nome",title:"Cor",type:"EDITAVEL_COMBOBOX",
                                                        urlCombobox:apiHost+"api/ajaxComboboxCores",
                                                        filtrosCombobox:function(dadosColuna){

                                                          console.log("===filtrosCombobox:"+JSON.stringify(dadosColuna) );

                                                          return {racaId:dadosColuna.Raca?dadosColuna.Raca.id:null};

                                                        },
                                                        urlEdicao:apiHost+"api/ajaxEditarInformacao"},

                                                  {name:"dataNascimento",title:"Nascimento",type:"EDITAVEL_DARAHORA",
                                                        urlEdicao:apiHost+"api/ajaxEditarInformacao"},

                                                  {name:"Raca.nome",title:"Raça"},
                                                ])

                      if(localStorage.getItem('perfil')=="PROPRIETARIO"){

                        colunas = colunas.concat([ 
                                                    {name:"observacoes",title:"Observações",type:"EDITAVEL_TEXTAREA",
                                                          urlEdicao:apiHost+"api/ajaxEditarInformacao"},

                                                    {name:"medal",title:"Medal",type:"EDITAVEL_TEXTO",
                                                        urlEdicao:apiHost+"api/ajaxEditarInformacao"},
                                                  ])


                      }else{

                        colunas = colunas.concat([ 

                                                    {name:"Proprietario.nome",title:"Proprietário"},
                                                    {name:"ninhada",title:"Ninhada"}

                                                  ])

                      }

                      colunas = colunas.concat([ 
                                                  {name:"nroMicrochip",title:"Microchip",type:"EDITAVEL_TEXTO",
                                                        urlEdicao:apiHost+"api/ajaxEditarInformacao"},

                                                  {name:"situacao",title:"Situação",type:"EDITAVEL_COMBOBOX",
                                                        urlCombobox:apiHost+"api/ajaxComboboxSituacao",
                                                        urlEdicao:apiHost+"api/ajaxEditarInformacao"},

                                                  {name:"fotoPerfilId",title:"Foto",type:"API_IMAGEM", action: function (refRoot,refPai,dadosColuna,urlImagem){

                                                      refRoot.optionsPopupFoto.editarFoto = false
                                                      refRoot.optionsPopupFoto.titulo = "Foto: "+dadosColuna.nome
                                                      refRoot.optionsPopupFoto.animal = dadosColuna
                                                      refRoot.optionsPopupFoto.urlFoto = urlImagem
                                                      refRoot.$refs.mdPopupFotoAnimal.abrirPopup();

                                                      }
                                                  }
                                              ])

                      console.log("====data #4")

                      if( localStorage.getItem('matriz')=="true" ){

                        colunas = colunas.concat([
                                          {name:"id",title:"Medal",type:"VIEW", action: function (item,refRoot,refPai,dadosColuna){

                                                  const url = "/app/exibirQrCode/"+dadosColuna.rg.replaceAll("/","-")

                                                  console.log("=======Medal:"+url);

                                                  window.open(url, '_blank').focus();

                                              }
                                          
                                          }])


                      }

                      if(localStorage.getItem('perfil')!="PROPRIETARIO"){

                        console.log("====data #5")

                        console.log("=====OPCOES HABILITADAS!!!!")

                        colunas = colunas.concat([ 
                                          
                                          {name:"id",title:"Transferir",type:"VIEW", action: function (item,refRoot,refPai,dadosColuna){

                                              console.log("=====item:"+JSON.stringify(item) )

                                                refRoot.novoProprietario = null;
                                                refRoot.animalPopup = dadosColuna;
                                                refRoot.novoNome = dadosColuna.nome;
                                                refRoot.$refs.mdPopup.abrirPopup();

                                              }
                                          },

                                          {name:"id",title:"Histórico",type:"VIEW", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                refRoot.$refs.tbTransferencias.setFilterValue("idAnimal",item);
                                                refRoot.$refs.mdPopTransferencias.abrirPopup();
                                              }
                                          },

                                          {name:"id",title:"Árvore",type:"VIEW", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                refRoot.rgAnimalArvore = dadosColuna.rg
                                                refRoot.$refs.mdPopLinhagem.abrirPopup();

                                              }
                                          },


                                          {name:"id",title:"Pedigree",type:"VIEW", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                const url = "/app/pedigree/"+dadosColuna.rg.replaceAll("/","-")

                                                console.log("=======urlPedigre:"+url);

                                                window.open(url, '_blank').focus();
                                              }
                                          },

                                          {name:"id",title:"",type:"DELETE", action: function (item,refRoot,refPai,dadosColuna){

                                                console.log("=====item:"+JSON.stringify(item) )

                                                var opcoes = [ 
                                                                {codigo:"EXCLUIR",descricao:"Confirmar exclusão"}
                                                              ];

                                                var parametrosAdicionais = "id="+item+"&informacao=Animais";

                                                refRoot.$refs.mdPopConfirmacaoExclusao.abrirPopupConfirmacaoAjax(
                                                      "Confirmar exclusão?",
                                                      "",
                                                      "Ao confirmar #"+dadosColuna.nome+" será excluido permanentemente",
                                                      opcoes,
                                                      "api/ajaxExcluirInformacao",
                                                      parametrosAdicionais);

                                                }


                                          }

                                        ])

                                  }





                      return {
                            idAnimalCadastroArvore:null,
                            racaIdNovoAnimal:null,
                             animalPopup:null,
                             novoProprietario:null,
                             novoNome:"",

                             rgAnimalArvore:null,

                             optionsPopTransferencias:{
                              confirm:{
                                show:false
                              }
                             },

                             optionsPopupFoto:{
                              urlFoto:null,
                              editarFoto:false,
                              animal:null,
                              confirm:{
                                show:false
                              }
                             },

                             optionsPopLinhagem:{
                              confirm:{
                                show:false
                              }
                             },

                             optionsPopup:{
                              confirm:{
                                show:true,
                                action:function(ajaxPost,cancel,item,refPai){

                                    var parameters = "animal="+ refPai.animalPopup.id+"&novoProprietario="+refPai.extrairId(refPai.novoProprietario)+"&novoNome="+refPai.novoNome;

                                    ajaxPost(apiHost+"api/ajaxTransferirAnimal",parameters,
                                        function (result) {

                                            if(!result.status){
                                              toast.error(result.message)
                                              return
                                            }

                                            refPai.$refs.tbDados.forceRefreshCurrentData();
                                            refPai.$refs.mdPopup.ocultarPopup();

                                        }
                                    );
                                }
                              }

                            },

                            configTbTransferencias:{
                              url: apiHost+"api/ajaxListarTransferencias",
                              filtros:[
                                {
                                  name:"idAnimal",
                                  title:"Animal",
                                  selected:"",
                                  type:"HIDDEN"
                                }
                              ],

                              columns : [ 
                                          {name:"createdAt",title:"Data"},
                                          {name:"nomeAntigo",title:"Nome anterior"},
                                          {name:"nomeNovo",title:"Nome novo"},
                                          {name:"ProprietarioAnterior.nome",title:"Proprietario anterior"},
                                          {name:"ProprietarioNovo.nome",title:"Proprietario novo"}
                                        ]
                            },

                            configTbDados:{
                              filtros:filtros,
                              columns : colunas,
                              url: apiHost+"api/ajaxListarAnimais",
                            },

                      }
                    },

    methods: {
          secureUpper,
          
          onImagemChanged: async function (file){

            var ref = this
            if( (!file)||(!file[0])){
              console.log("=====file null, ignorado...")
              return;
            }

            const resizedImage = await resizeImage({
                                                      file: file[0],
                                                      maxSize: 500
                                                  })

            var form = new FormData();
            form.append('file_1', resizedImage );

            $.ajax({
                url: apiHost+'api/ajaxUploadFoto',
                data: form,
                processData: false,
                contentType: false,
                type: 'POST',
                headers: {"Authorization": 'Bearer '+localStorage.getItem('token')},
                success: function (result) {
                  
                  if(result.status==true){

                    easyPost('api/ajaxEditarInformacao',
                             'dominio=Animais&type=EDITAVEL_TEXTO&coluna=fotoPerfilId&id='+ref.optionsPopupFoto.animal.id+'&valor='+result.idArquivo,
                             'Imagem salva com sucesso',
                             function(){

                              ref.atualizarDados();
                              ref.$refs.mdPopupFotoAnimal.ocultarPopup();

                             })

                  }else{
                    toast.error(result.message)    
                  }

                }
            });

          },

          incluirArvore(){

            var ref = this  
            ref.idAnimalCadastroArvore = null
            ref.rgAnimalArvore = null
            ref.$refs.mdPopLinhagem.abrirPopup();

          },

          extrairId(valor){

            if(!valor)
              return

            if(valor&&valor.includes("|")){

              console.log("====initSelection ---- encontrou barra...valor:"+valor)

              var valorSplit = valor.split("|")

              console.log("====initSelection ---- utilizado:"+valorSplit[0])

             return valorSplit[0]
            }

            return valor
          },

          onExcluido: function () {
            this.atualizarDados();
          },
          onAnimaisIncluidos: function (filhotes,codigoNinhada) {

            var ref = this
            ref.$refs.tbDados.setFilterValue("filtroNome","");
            ref.$refs.tbDados.setFilterValue("ninhada",codigoNinhada);

          },

          atualizarDados:function(){
            var ref = this;
            ref.$refs.tbDados.forceRefreshCurrentData();
          }

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-body{
  height: 400px !important;
  overflow-y: scroll !important;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
