<template>
  <div class="hello" style="min-width:1024px;">
<!--     <img v-if="criador" style="width:1024px;z-index:1;position: fixed;left:0px;top:0px;" src="../assets/certificado_base.jpeg">
 -->    <div v-if="criador" style="z-index: 999;position: fixed;left: 0px;top:0px;">    

      <div style="position: fixed;left: 230px;top:300px;">
        <h1 style="color:blue;font-size:70px;font-family: 'Roboto';font-weight: bolder;">{{criador.nomeEmpresa}}</h1>
      </div>

      <div style="position: fixed;left: 240px;top:430px;">    
        <p>Número de inscrição: {{criador.inscricao}}</p>
      </div>

      <div style="position: fixed;left: 240px;top:455px;">    
        <p v-if="criador">Proprietário: {{criador.nome}}</p>
      </div>

      <div style="position: fixed;left: 240px;top:485px;">    
        <p>Validade: {{ dataValidade }}</p>
      </div>

      <div style="position: fixed;left: 240px;top:250px;">    
          <p>Declaramos que na data de {{dataCriacao}}, foi inscrito em nosso sistema o {{termo}}:</p>
      </div>

    </div>
</div>
</template>

<script>
/* eslint-disable */

var moment = require('moment');
import { apiHost } from '../config'
import { useToast } from "vue-toastification";
const toast = useToast();

export default {

  props: {
    msg: String
  },

  components: {

  },

  data: function () {
                      return {
                            criador:null,
                            termo:"local"
                      }
                    },

    mounted: function () {

      this.carregarDados();

    },

    computed:{

      dataValidade() {

        if(!this.criador.vencimento)
          return ""

        return   moment(this.criador.vencimento).format('DD/MM/YYYY')
      },

      dataCriacao() {
        return   moment(this.criador.createdAt).format('DD/MM/YYYY')
      },

    },

    methods: {

      carregarDados: function () {

          var ref = this;
          const inscricao = ref.$route.params.inscricao

          if(!inscricao){
            toast.error("inscricao não informada")
            return
          }

          var parameters = "inscricao="+inscricao

          $.ajax({
              url: apiHost+"api/ajaxCarregarCriador",
              data: parameters,
              dataType: "JSON",
              type: "GET",
              headers: {"Authorization": 'Bearer '+localStorage.getItem('token')}
             }).success(function(result){
              
              if(result.status==true){

                console.log("===result.criador:"+result.criador)

                if(result.criador==null){
                  window.location.href = "/404";
                  return;
                }

                ref.criador = result.criador;
                ref.termo   = result.termo

              }else{
                  toast.error(result.message);
              }

          }).complete(function(result){
            

          });

      }

    }

}
</script>

<style scoped type="text/css">

h1{
  color: black!important;
}

p{
  color: black!important;
}

</style>

<style scoped type="text/css" media="print">
  @page { size: landscape; }
</style>